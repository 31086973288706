import { types, flow, Instance, SnapshotIn, cast } from "mobx-state-tree";
import { IObservableArray } from "mobx";
import { SortDirection, RmaReturnSort, RmaReturnListCategories, PAGINATION } from "../boot/constants";
import { ApiResponseType, rmaReturnApi, ExportUtils, DateUtils, TPaginated, userAPI } from "../services";
import { BaseFilterRma } from "../core";
import { AdminModelShort } from "./admin-list.store";
import { TShortAccount } from "../models/account";

export const ItemModel = types
  .model("ItemModel", {
    replacementSn: types.maybeNull(types.string),
    id: types.number,
    originalSn: types.maybeNull(types.string),
    returnReason: types.maybeNull(types.string),
    isReproducible: types.maybeNull(types.boolean),
    salesItemId: types.maybeNull(types.number),
    canBeReproducible: types.optional(types.boolean, false)
  });

export const NonSerializedItemModel = types
  .model("NonSerializedItemModel", {
    allItemsQuantity: types.maybeNull(types.number),
    id: types.number,
    name: types.maybeNull(types.string),
    returnReason: types.maybeNull(types.string)
  });

const RmaReturnModel = types
  .model("RmaReturnModel", {
    accountCityAndState: types.maybeNull(types.string),
    accountName: types.maybeNull(types.string),
    creator: types.maybeNull(AdminModelShort),
    lastEditor: types.maybeNull(AdminModelShort),
    id: types.number,
    items: types.optional(types.array(ItemModel), []),
    nonSerializedItems: types.optional(types.array(NonSerializedItemModel), []),
    nonSerializedItemsQuantity: types.maybeNull(types.number),
    replacementTracking: types.maybeNull(types.string),
    requestDate: types.maybeNull(types.string),
    returnTracking: types.maybeNull(types.string),
    externalId: types.maybeNull(types.string),
    shipDate: types.maybeNull(types.string),
    ticketId: types.maybeNull(types.string),
    status: types.maybeNull(types.number),
    salesRep: types.maybeNull(AdminModelShort)
  });

export interface IItemModel extends Instance<typeof ItemModel> {}
export interface IItemModelSnapShot extends SnapshotIn<typeof ItemModel> {}
export interface INonSerializedItemModel extends Instance<typeof NonSerializedItemModel> {}
export interface INonSerializedItemModelSnapShot extends SnapshotIn<typeof NonSerializedItemModel> {}
export interface IRmaReturnModel extends Instance<typeof RmaReturnModel> {}
export interface IRmaReturnModelSnapShot extends SnapshotIn<typeof RmaReturnModel> {}

export const RmaReturnListModel = types
  .model("RmaReturnListModel", {
    activeCategory: types.optional(types.string, RmaReturnListCategories[0].value),
    page: 0,
    total: 0,
    rmaCategoryTotal: types.optional(types.number, 0),
    returnCategoryTotal: types.optional(types.number, 0),
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, RmaReturnSort.id),
    sortDirection: types.optional(types.number, SortDirection.DESC),
    items: types.optional(types.array(RmaReturnModel), []),
    isLoading: types.optional(types.boolean, true),
    dropDownFilterStatus: types.optional(types.string, ""),
    searchStatusValue: types.optional(types.string, ""),
    salesReps: types.optional(types.array(types.model({ value: types.string, label: types.string })), []),
    dropDownFilterSalesRep: types.optional(types.string, ""),
    searchSalesRepValue: types.optional(types.string, "")
  })
  .actions(self => ({
    setList(dto: IRmaReturnModelSnapShot[]) {
      self.items = dto as IObservableArray;
    },
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {
    const setActiveCategory = (value: string, count: number) => {
      self.activeCategory = value;
      self.page = 0;
      self.total = count;
      getList();
    };

    const setDefaultParams = () => {
      self.activeCategory = RmaReturnListCategories[0].value;
      self.page = 0;
      self.searchValue = "";
      self.sortBy = RmaReturnSort.id;
      self.sortDirection = SortDirection.DESC;
      self.dropDownFilterStatus = "";
      self.searchStatusValue = "";
      self.dropDownFilterSalesRep = "";
      self.searchSalesRepValue = "";
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const setSearchStatusValue = (search: string) => {
      self.searchStatusValue = search;
    };

    const setDropDownFilterStatus = (value: string | number) => {
      self.dropDownFilterStatus = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const setSearchSalesRepValue = (search: string) => {
      self.searchSalesRepValue = search;
    };

    const setDropDownFilterSalesRep = (value: string | number) => {
      self.dropDownFilterSalesRep = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const fetchSalesReps = flow(function* fetchSalesReps() {
      const salesRepData: ApiResponseType<TPaginated<TShortAccount>>
        = yield userAPI.getSalesReps(0, 9999, "Sales Rep", "");
      if (!salesRepData.isOk) {
        self.salesReps = cast([]);
        return;
      }
      self.salesReps = cast(salesRepData.data.entries.map((item: TShortAccount) => {
        return { value: item.id.toString(), label: item.name };
      }));
    });

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new BaseFilterRma();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        if (self.dropDownFilterSalesRep) {
          filter.salesRepId = self.dropDownFilterSalesRep;
        }

        if (self.dropDownFilterStatus) {
          filter.status = self.dropDownFilterStatus;
        }

        const result: ApiResponseType<IRmaReturnModelSnapShot[]>
          = self.activeCategory === RmaReturnListCategories[0].value
            ? yield rmaReturnApi.getRmaList(filter)
            : yield rmaReturnApi.getReturnList(filter);
        if (result.isOk) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new BaseFilterRma();
        filter.search = self.searchValue || "";

        if (self.dropDownFilterSalesRep) {
          filter.salesRepId = self.dropDownFilterSalesRep;
        }

        if (self.dropDownFilterStatus) {
          filter.status = self.dropDownFilterStatus;
        }

        const result: ApiResponseType<{returns: number, rmas: number}> = yield rmaReturnApi.getCounters(filter);
        if (result.isOk) {
          self.rmaCategoryTotal = result.data.rmas || 0;
          self.returnCategoryTotal = result.data.returns || 0;

          switch (self.activeCategory) {
            case RmaReturnListCategories[0].value :
              self.total = self.rmaCategoryTotal;
              break;
            case RmaReturnListCategories[1].value :
              self.total = self.returnCategoryTotal;
              break;
            default:
              break;
          }
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const downloadExport = flow(function* downloadExport() {
      const filter = new BaseFilterRma();
      filter.search = self.searchValue || "";
      filter.sortBy = self.sortBy;
      filter.sortDirection = self.sortDirection;

      if (self.dropDownFilterSalesRep) {
        filter.salesRepId = self.dropDownFilterSalesRep;
      }

      if (self.dropDownFilterStatus) {
        filter.status = self.dropDownFilterStatus;
      }

      try {
        const exportResult = self.activeCategory === RmaReturnListCategories[0].value
          ? yield rmaReturnApi.getExportRma(filter)
          : yield rmaReturnApi.getExportReturn(filter);
        if (!exportResult.isOk) {
          return;
        }

        ExportUtils.downloadCSV(exportResult.data.data, `${self.activeCategory}.csv`);
      } catch (error) {
        console.error(error);
      }
    });

    const downloadExportBatchMove = flow(function* downloadExportBatchMove(dates) {
      const filter = new BaseFilterRma();

      if (dates && dates[0] && dates[1]) {
        filter.startShipDate = DateUtils.toServerFormat(new Date(dates[0]));
        filter.endShipDate = DateUtils.toServerFormat(new Date(dates[1]));
      } else {
        return;
      }
      filter.search = self.searchValue || "";
      filter.sortBy = self.sortBy;
      filter.sortDirection = self.sortDirection;

      if (self.dropDownFilterSalesRep) {
        filter.salesRepId = self.dropDownFilterSalesRep;
      }

      if (self.dropDownFilterStatus) {
        filter.status = self.dropDownFilterStatus;
      }

      try {
        const exportResult = self.activeCategory === RmaReturnListCategories[0].value
          ? yield rmaReturnApi.getExportBatchMoveRma(filter)
          : yield rmaReturnApi.getExportBatchMoveReturn(filter);
        if (!exportResult.isOk) {
          return;
        }

        ExportUtils.downloadCSV(exportResult.data.data,
          `${self.activeCategory}BatchMove_${filter.startShipDate}-${filter.endShipDate}.csv`);
      } catch (error) {
        console.error(error);
      }
    });

    const deleteRmaReturn = flow(function* deleteRmaReturn(id: number) {
      try {
        self.isLoading = true;
        const result = self.activeCategory === RmaReturnListCategories[0].value
          ? yield rmaReturnApi.deleteRma(id)
          : yield rmaReturnApi.deleteReturn(id);

        if (result.isOk) {
          getTotalCount();
          return result;
        }
      } catch (error) {
        console.error(error);
      } finally {
        self.isLoading = false;
      }
    });

    return {
      nextPage,
      previousPage,
      getList,
      setActiveCategory,
      getTotalCount,
      setSearch,
      setSortBy,
      downloadExport,
      downloadExportBatchMove,
      deleteRmaReturn,
      setSearchStatusValue,
      setDropDownFilterStatus,
      setFirstPage,
      setDefaultParams,
      fetchSalesReps,
      setSearchSalesRepValue,
      setDropDownFilterSalesRep
    };
  });
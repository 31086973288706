import React, { FC, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { DashboardDropdownWrapper } from "./sl-dashboard-dropdown.presets";
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown.svg";
import { ReactComponent as ExitIcon } from "../../assets/icons/exit.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg";
import ProfileDefaultImage from "../../assets/img/profile-default.svg";
import { useRootStore } from "../../stores/storeContext";

const enum EventKeys {
  PROFILE = "profile",
  LOGOUT = "logout"
}

export const SlDashboardDropdown: FC = observer(() => {
  const { profile, logout } = useRootStore().userStore;
  const navigate = useNavigate();

  const username = useMemo(() => {
    return profile.firstname && profile.lastname
      ? `${profile.firstname} ${profile.lastname}`
      : profile.email;
  }, [profile.firstname, profile.lastname, profile.email]);

  const handleSelect = (key: EventKeys) => {
    switch (key) {
      case EventKeys.LOGOUT: {
        logout();
        return navigate("/login");
      }
      case EventKeys.PROFILE:
        return navigate("/profile");
      default:
        break;
    }
  };

  return (
    <DashboardDropdownWrapper>
      <Dropdown
        onSelect={k => handleSelect(k as EventKeys)}
      >
        <Dropdown.Toggle>
          <img
            src={profile?.imageUrl || ProfileDefaultImage}
            alt="Profile image"
          />
          <div className="text">{`${username} (${profile.groupName})`} </div>
          <DropdownIcon className={"dropdown-chevron"}/>
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          <Dropdown.Item eventKey={EventKeys.PROFILE}>
            <ProfileIcon/>
            <span>Profile</span>
          </Dropdown.Item>
          <Dropdown.Item eventKey={EventKeys.LOGOUT} className={"logout"}>
            <ExitIcon/>
            <span>Exit</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </DashboardDropdownWrapper>
  );
});

import { observer } from "mobx-react-lite";
import { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { VQAuthLogin } from "vivaquant-components-library";
import { RECAPTCHA_KEY } from "../../../boot/constants";
import { SlHeaderLogoOnly } from "../../../components/sl-header";
import { useRootStore } from "../../../stores/storeContext";

export const LoginPage: FC = observer(() => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [message, setMessage] = useState<{message: string, isError: boolean}>(locationState as never);

  const { login, loginTwoFactor, resendLoginCode } = useRootStore().userStore;

  const {
    email,
    password,
    invalidEmail,
    invalidPassword,
    // tokenReCaptcha,
    isSubmit,
    isShowPassword,
    isErrorLogin,
    isLoading,
    setEmail,
    setPassword,
    setSubmit,
    setShowPassword,
    setErrorLogin,
    setLoading,
    setTokenReCaptcha,
    cleanData
  } = useRootStore().loginFormStore;

  const { setDefaultParams: setDefaultParamsAccounts } = useRootStore().accountsListStore;
  const { setDefaultParams: setDefaultParamsAdmin } = useRootStore().adminListStore;
  const { setDefaultParams: setDefaultParamsBilling } = useRootStore().billingListStore;
  const { setDefaultParams: setDefaultParamsOrders } = useRootStore().orderListStore;
  const { setDefaultParams: setDefaultParamsRmaReturn } = useRootStore().rmaReturnListStore;
  const { setDefaultParams: setDefaultParamsDevicesHistory } = useRootStore().devicesHistoryListStore;

  const [isTwoFactor, setIsTwoFactor] = useState(false);
  const [telNumber, setTelNumber] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [resentCode, setResentCode] = useState(false);
  const [errorCode, setErrorCode] = useState(false);

  const cleanPagination = () => {
    setDefaultParamsAccounts();
    setDefaultParamsAdmin();
    setDefaultParamsBilling();
    setDefaultParamsOrders();
    setDefaultParamsRmaReturn();
    setDefaultParamsDevicesHistory();
  };

  const onSubmit = async(event: FormEvent) => {
    event.preventDefault();
    setSubmit();
    // [Temp]: Disabled recapcha validation
    // if (invalidEmail || invalidPassword || !tokenReCaptcha || !email || !password) { return; }
    if (invalidEmail || invalidPassword || !email || !password) { return; }
    setLoading(true);
    const result = await login(email, password);
    if (result) {
      if (typeof result === "object" && result.tel) {
        setIsTwoFactor(true);
        setTelNumber(result.tel);
      } else {
        setIsTwoFactor(false);
        setTelNumber("");
        cleanData();
        cleanPagination();
        navigate("/dashboard/orders");
      }
    } else {
      setErrorLogin(true);
    }
    setLoading(false);
  };

  const onValidateCode = async(event: any) => {
    event.preventDefault();
    setLoading(true);
    const result = await loginTwoFactor(email, authCode);
    if (result) {
      cleanData();
      navigate("/dashboard/orders");
    } else {
      setErrorCode(true);
    }
    setLoading(false);
  };

  const forgotPass = () => {
    navigate("/forgot-password");
  };

  const showPassword = () => {
    setShowPassword();
  };

  const handleVerifyRecaptcha = (token: string) => {
    setTokenReCaptcha(token || "");
  };

  const getTwoFactorAuthenticationMessage = () => {
    const replaced = telNumber.replace(/.(?=....)/g, "*");
    const sentText = resentCode ? "resent" : "sent";
    return `A message with a verification code has been ${sentText} to your phone number ${replaced}.
    Enter the code to continue.`;
  };

  const handleOnChangeAuthCode = (res: string) => {
    setAuthCode(res);
    setErrorCode(false);
  };

  const retrySendCode = () => {
    setResentCode(true);
    resendLoginCode(email);
  };

  useEffect(() => {
    if (locationState) {
      setMessage(locationState as never);
      navigate("", { replace: true });
    }
  }, []);

  return (
    <VQAuthLogin
      header={(<SlHeaderLogoOnly></SlHeaderLogoOnly>)}
      isTwoFactor={isTwoFactor}
      telNumber={telNumber}
      authCode={authCode}
      errorCode={errorCode}

      setEmail={setEmail}
      setPassword={setPassword}

      isLoading={isLoading}
      isSubmit={isSubmit}
      invalidEmail={invalidEmail}
      invalidPassword={invalidPassword}
      isShowPassword={isShowPassword}
      message={message && message.message ? message.message : ""}
      isErrorLogin={isErrorLogin}
      onSubmit={onSubmit}
      email={email}
      password={password}
      recaptchaKey={RECAPTCHA_KEY}

      showPassword={showPassword}
      forgotPass={forgotPass}
      handleVerifyRecaptcha={handleVerifyRecaptcha}
      getTwoFactorAuthenticationMessage={getTwoFactorAuthenticationMessage}
      handleOnChangeAuthCode={handleOnChangeAuthCode}
      onValidateCode={onValidateCode}
      retrySendCode={retrySendCode}
    ></VQAuthLogin>
  );
});

import { ApiResponseType, TPaginated } from "../types";
import { API } from "../../boot";
import { ProcessAPIError } from "../decorators";
import { ApiResponseDto } from "../../models";
import { TShortAccount } from "../../models/account";
import { IUserProfile } from "../../models/v2/auth/IUserProfile";
import { IUpdateProfileRequest } from "../../models/v2/User/IUpdateProfileRequest";
import { IUpdateProfileResponse } from "../../models/v2/User/IUpdateProfileResponse";

class UserApi {
  @ProcessAPIError()
  public async getCurrentUser(): Promise<ApiResponseDto<IUserProfile>> {
    const { data } = await API.get<IUserProfile>("users/me");
    return new ApiResponseDto<IUserProfile>(data);
  }

  @ProcessAPIError()
  public async getSalesReps(
    skip?: number,
    take?: number,
    groupName: string = "Sales Rep",
    search: string = ""
  ): Promise<ApiResponseDto<TPaginated<TShortAccount>>> {
    const params = {
      skip,
      take,
      groupName,
      search: search === "" ? null : search,
      sortBy: "fullname"
    };

    const { data } = await API.get("/employees", {
      params
    });
    data.entries = data.entries.map((el: TShortAccount) => {
      if (!el.name) {
        el.name = `${el.firstName || ""} ${el.lastName || ""}`.trim();
      }
      return el;
    });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async updateProfile(request: IUpdateProfileRequest): Promise<ApiResponseType<IUpdateProfileResponse>> {
    const { data } = await API.patch<IUpdateProfileResponse>("users/me/profile", request);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async updateProfile2Fa(profile: IUpdateProfileRequest, code: string)
    : Promise<ApiResponseType<IUpdateProfileResponse>> {
    const { data } = await API.patch<IUpdateProfileResponse>("users/me/profile/2fa", { profile, code });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async resendUpdateCode(): Promise<ApiResponseType<null>> {
    await API.post("users/me/profile/2fa");
    return new ApiResponseDto(null);
  }
}

export const userAPI = new UserApi();

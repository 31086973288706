export class ExportUtils {
  public static downloadCSV = (data: any, name?: string) => {
    const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
    const blobURL = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = name || "Report.csv";
    anchor.href = blobURL;
    anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(
      ":"
    );
    anchor.click();
  };
}

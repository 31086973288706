import { types } from "mobx-state-tree";
import { ValidationUtils } from "../../services";

export const InvitationFormModel = types
  .model("InvitationFormModel", {
    username: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
    passwordConfirm: types.optional(types.string, ""),
    invalidUsername: types.optional(types.boolean, false),
    invalidPassword: types.optional(types.boolean, false),
    invalidPasswordConfirm: types.optional(types.boolean, false),
    isSubmit: types.optional(types.boolean, false),
    isShowPassword: types.optional(types.boolean, false),
    isShowPasswordConfirm: types.optional(types.boolean, false),
    isErrorInvitation: types.optional(types.string, ""),
    isLoading: types.optional(types.boolean, false)
  })
  .actions(self => {
    const validateUsername = () => {
      self.invalidUsername = !ValidationUtils.isUsernameValid(self.username);
    };

    const validatePassword = () => {
      self.invalidPassword = !ValidationUtils.isPasswordValid(self.password);
    };

    const validatePasswordConfirm = () => {
      self.invalidPasswordConfirm = !ValidationUtils.isPasswordValid(self.passwordConfirm);
    };

    const setUsername = (value: string) => {
      self.username = value;
      setErrorInvitation("");
      validateUsername();
    };

    const setPassword = (value: string) => {
      self.password = value;
      setErrorInvitation("");
      validatePassword();
    };

    const setPasswordConfirm = (value: string) => {
      self.passwordConfirm = value;
      setErrorInvitation("");
      validatePasswordConfirm();
    };

    const setShowPassword = () => {
      self.isShowPassword = !self.isShowPassword;
    };

    const setShowPasswordConfirm = () => {
      self.isShowPasswordConfirm = !self.isShowPasswordConfirm;
    };

    const setSubmit = () => {
      self.isSubmit = true;
      validateUsername();
      validatePassword();
      validatePasswordConfirm();
    };

    const cleanSubmit = () => {
      self.isSubmit = false;
    };

    const setErrorInvitation = (value: string) => {
      self.isErrorInvitation = value;
    };

    const setLoading = (isLoading: boolean) => {
      self.isLoading = isLoading;
    };

    const cleanData = () => {
      self.username = "";
      self.password = "";
      self.passwordConfirm = "";
      self.isSubmit = false;
    };

    return {
      validateUsername,
      validatePassword,
      validatePasswordConfirm,
      setUsername,
      setPassword,
      setPasswordConfirm,
      setSubmit,
      cleanSubmit,
      setShowPassword,
      setShowPasswordConfirm,
      setErrorInvitation,
      setLoading,
      cleanData
    };
  });

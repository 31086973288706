import { ShippingServiceTypeOptions, States } from "../boot/constants";
import { IEditOrderModelSnapShot } from "../stores/edit-order.store";

export const EditOrderInitialState: IEditOrderModelSnapShot = {
  serialNumber: {
    name: "Order Id",
    value: "",
    type: "input",
    fieldId: "serialNumber"
  },
  ticketId: {
    name: "Zendesk/ClaritySoft ticket number",
    value: "",
    type: "input",
    fieldId: "ticketId",
    isOptional: true
  },
  description: {
    name: "Attention To",
    value: "",
    type: "input",
    fieldId: "description",
    isOptional: true
  },
  shippingServiceType: {
    name: "Shipping Service",
    value: "",
    type: "select",
    fieldId: "shippingServiceType",
    options: ShippingServiceTypeOptions,
    defaultValue: { value: ShippingServiceTypeOptions[0].value, label: ShippingServiceTypeOptions[0].label },
  },
  streetShipping: {
    name: "Street Address",
    value: "",
    type: "input",
    fieldId: "streetShipping"
  },
  cityShipping: {
    name: "City",
    value: "",
    type: "input",
    fieldId: "cityShipping"
  },
  stateShipping: {
    name: "State/Territory",
    value: States[0],
    type: "select",
    options: States.map(el => ({ value: el, label: el })),
    defaultValue: { value: States[0], label: States[0] },
    fieldId: "stateShipping"
  },
  zipCodeShipping: {
    name: "Zip/Postal Code",
    value: "",
    type: "input",
    fieldId: "zipCodeShipping"
  },

  trackingNumber: {
    name: "Order tracking number",
    value: "",
    type: "input",
    isOptional: true,
    fieldId: "trackingNumber"
  },
  shippingPrice: {
    name: "Shipping Price",
    value: "",
    type: "input",
    fieldId: "shippingPrice"
  },
  orderState: {
    name: "Status",
    value: "",
    type: "select",
    options: [],
    fieldId: "orderState"
  },
  shippingDate: {
    name: "Device shipping date",
    value: "",
    type: "dateTime",
    fieldId: "shippingDate"
  },
  deviceStartDate: {
    name: "Order start date",
    value: "",
    type: "dateTime",
    fieldId: "deviceStartDate"
  },
  syncWithShipStation: {
    name: "Update Ship label",
    value: "true",
    type: "checkbox",
    isChecked: true,
    fieldId: "syncWithShipStation"
  }
};

import React, { FC, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { DashboardWrapper } from "./sl-dashboard-layout.presets";

export const SlDashboardLayout: FC = () => {

  const [headerHeight, setHeaderHeight] = useState(0);

  const updateSize = () => {
    let elHeight = document.querySelector(".margin-table table thead")?.getBoundingClientRect()?.bottom || 1;
    if (elHeight !== headerHeight) {
      setHeaderHeight(elHeight);
    }
  };

  useEffect(() => { 
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    updateSize();
  });

  return (
    <DashboardWrapper headerHeight={headerHeight}>
      <Outlet/>
    </DashboardWrapper>
  );
};

import { IObservableArray } from "mobx";
import { cast, flow, Instance, SnapshotIn, types } from "mobx-state-tree";
import { OrderListCategories, PAGINATION, SortDirection, OrderSort } from "../boot/constants";
import { BaseFilterOrder } from "../core";
import { ApiResponseDto } from "../models";
import { TShortAccount } from "../models/account";
import { OrderTotalDto, TOrderData } from "../models/order";
import {
  ApiResponseType,
  DateUtils,
  ExportUtils,
  orderAPI,
  OrderStateMapper,
  TPaginated,
  userAPI
} from "../services";
import { AdminModelShort } from "./admin-list.store";

const OrderModel = types
  .model("OrderModel", {
    id: types.maybe(types.number),
    orderId: types.maybeNull(types.string),
    accountName: types.maybeNull(types.string),
    requestDate: types.maybeNull(types.string),
    shipDate: types.maybeNull(types.string),
    usageStartDate: types.maybeNull(types.string),
    contactName: types.maybeNull(types.string),
    orderState: types.maybeNull(types.number),
    orderStateName: types.maybeNull(types.string),
    salesRep: types.maybeNull(AdminModelShort),
    creator: types.maybeNull(AdminModelShort),
    lastEditor: types.maybeNull(AdminModelShort),
    trackingNumber: types.maybeNull(types.string),
    quantityOrdered: types.maybeNull(types.number),
    serializedItemsQuantity: types.maybeNull(types.number),
    nonSerializedItemsQuantity: types.maybeNull(types.number),
    allItemsQuantity: types.maybeNull(types.number),
    serializedItems: types.maybeNull(types.array(types.string)),
    nonSerializedItems: types.maybeNull(types.array(types.string))
  });

export interface IOrderModel extends Instance<typeof OrderModel> {}
export interface IOrderModelSnapShot extends SnapshotIn<typeof OrderModel> {}

export const OrderListModel = types
  .model("OrderListModel", {
    activeCategory: types.optional(types.string, OrderListCategories[0].value),
    page: 0,
    total: 0,
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, OrderSort.orderNumber),
    sortDirection: types.optional(types.number, SortDirection.DESC),
    isLoading: types.optional(types.boolean, true),
    AllCategoryTotal: types.optional(types.number, 0),
    NotShippedCategoryTotal: types.optional(types.number, 0),
    SuspendedCategoryTotal: types.optional(types.number, 0),
    ActiveCategoryTotal: types.optional(types.number, 0),
    CancelledCategoryTotal: types.optional(types.number, 0),
    items: types.optional(types.array(OrderModel), []),
    salesReps: types.optional(types.array(types.model({ value: types.string, label: types.string })), []),
    dropDownFilterSalesRep: types.optional(types.string, ""),
    searchSalesRepValue: types.optional(types.string, ""),
    isSerializedSort: types.maybeNull(types.boolean)
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setList(dto: IOrderModelSnapShot[]) {
      self.items = dto as IObservableArray;
    }
  }))
  .actions(self => {
    const setActiveCategory = (value: string, count: number) => {
      self.activeCategory = value;
      self.page = 0;
      self.total = count;
      getList();
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const setDefaultParams = () => {
      self.activeCategory = OrderListCategories[0].value;
      self.page = 0;
      self.searchValue = "";
      self.sortBy = OrderSort.orderNumber;
      self.sortDirection = SortDirection.DESC;
      self.dropDownFilterSalesRep = "";
      self.searchSalesRepValue = "";
      self.isSerializedSort = null;
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const setSearchSalesRepValue = (search: string) => {
      self.searchSalesRepValue = search;
    };

    const setDropDownFilterSalesRep = (value: string | number) => {
      self.dropDownFilterSalesRep = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const setSerializedSort = (value: string) => {
      if (!value) {
        self.isSerializedSort = null;
      } else {
        self.isSerializedSort = value === "true";
      }
      self.page = 0;
      getTotalCount();
    };

    const fetchSalesReps = flow(function* fetchSalesReps() {
      const salesRepData: ApiResponseType<TPaginated<TShortAccount>>
        = yield userAPI.getSalesReps(0, 9999, "Sales Rep", "");
      if (!salesRepData.isOk) {
        self.salesReps = cast([]);
        return;
      }
      self.salesReps = cast(salesRepData.data.entries.map((item: TShortAccount) => {
        return { value: item.id.toString(), label: item.name };
      }));
    });

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new BaseFilterOrder();
        filter.skip = 0;
        filter.take = 0;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        if (self.dropDownFilterSalesRep) {
          filter.salesRepId = self.dropDownFilterSalesRep;
        }

        if (self.isSerializedSort !== null) {
          filter.isSerialized = self.isSerializedSort;
        }

        const result: ApiResponseDto<OrderTotalDto>
          = yield orderAPI.getOrdersCounter(filter);
        if (result.isOk) {
          self.AllCategoryTotal = result.data.All || 0;
          self.NotShippedCategoryTotal = result.data.NotShipped || 0;
          self.SuspendedCategoryTotal = result.data.Suspended || 0;
          self.ActiveCategoryTotal = result.data.Active || 0;
          self.CancelledCategoryTotal = result.data.Cancelled || 0;

          switch (self.activeCategory) {
            case OrderListCategories[0].value :
              self.total = self.AllCategoryTotal;
              break;
            case OrderListCategories[1].value :
              self.total = self.NotShippedCategoryTotal;
              break;
            case OrderListCategories[2].value :
              self.total = self.SuspendedCategoryTotal;
              break;
            case OrderListCategories[3].value :
              self.total = self.ActiveCategoryTotal;
              break;
            case OrderListCategories[4].value :
              self.total = self.CancelledCategoryTotal;
              break;
            default:
              break;
          }
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new BaseFilterOrder();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        if (self.dropDownFilterSalesRep) {
          filter.salesRepId = self.dropDownFilterSalesRep;
        }

        if (self.isSerializedSort !== null) {
          filter.isSerialized = self.isSerializedSort;
        }

        const result: ApiResponseType<IOrderModelSnapShot[]>
          = yield orderAPI.getOrders(filter, self.activeCategory);
        if (result.isOk) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const downloadExport = flow(function* downloadExport() {
      const filter = new BaseFilterOrder();
      filter.search = self.searchValue || "";
      filter.sortBy = self.sortBy;
      filter.sortDirection = self.sortDirection;

      if (self.dropDownFilterSalesRep) {
        filter.salesRepId = self.dropDownFilterSalesRep;
      }

      if (self.isSerializedSort !== null) {
        filter.isSerialized = self.isSerializedSort;
      }

      try {
        const exportResult = yield orderAPI.getExport(filter, self.activeCategory);
        if (!exportResult.isOk) {
          return;
        }

        ExportUtils.downloadCSV(exportResult.data.data,
          `Orders_${self.activeCategory ? OrderStateMapper.getOrderState(+self.activeCategory) : "All"}.csv`);
      } catch (error) {
        console.error(error);
      }
    });

    const downloadExportBatchMove = flow(function* downloadExportBatchMove(dates) {
      const filter = new BaseFilterOrder();

      if (dates && dates[0] && dates[1]) {
        filter.startShipDate = DateUtils.toServerFormat(new Date(dates[0]));
        filter.endShipDate = DateUtils.toServerFormat(new Date(dates[1]));
      } else {
        return;
      }

      filter.search = self.searchValue || "";
      filter.sortBy = self.sortBy;
      filter.sortDirection = self.sortDirection;

      if (self.dropDownFilterSalesRep) {
        filter.salesRepId = self.dropDownFilterSalesRep;
      }

      if (self.isSerializedSort !== null) {
        filter.isSerialized = self.isSerializedSort;
      }

      try {
        const exportResult = yield orderAPI.getExportBatchMove(filter, self.activeCategory);
        if (!exportResult.isOk) {
          return;
        }

        ExportUtils.downloadCSV(exportResult.data.data,
          `OrdersBatchMove_${self.activeCategory ? OrderStateMapper.getOrderState(+self.activeCategory) : "All"}.csv`);
      } catch (error) {
        console.error(error);
      }
    });

    const getOrderForDelete = flow(function* getOrderForDelete(id: number) {
      try {
        self.setLoading(true);
        const result: ApiResponseType<TOrderData> = yield orderAPI.getOrderData(id);
        if (result.isOk) {
          return result.data;
        }
        return null;
      } catch {
        return null;
      } finally {
        self.setLoading(false);
      }
    });

    const deleteOrder = flow(function* deleteOrder(id: number) {
      try {
        self.setLoading(true);
        const result: ApiResponseType<TOrderData> = yield orderAPI.deleteOrder(id);
        return result.isOk;
      } catch {
        return false;
      } finally {
        self.setLoading(false);
      }
    });

    return {
      nextPage,
      previousPage,
      getList,
      getTotalCount,
      setSearch,
      setSortBy,
      setFirstPage,
      downloadExport,
      downloadExportBatchMove,
      setActiveCategory,
      getOrderForDelete,
      deleteOrder,
      fetchSalesReps,
      setSearchSalesRepValue,
      setDropDownFilterSalesRep,
      setSerializedSort,
      setDefaultParams
    };
  });

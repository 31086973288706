export class ApiResponseDto<T> {
  public readonly isOk: true = true;
  public readonly status: number;
  public readonly data: T;

  constructor(data: T, status = 200) {
    this.status = status;
    this.data = data;
  }
}

import { OrderState } from "../../boot/constants";

export class OrderStateMapper {
  public static getOrderState(state: number): OrderState {
    switch (state) {
      case 1:
        return OrderState.CompleteNotShipped;
      case 2:
        return OrderState.ShippedSuspended;
      case 3:
        return OrderState.ShippedActive;
      case 4:
        return OrderState.Cancelled;
      default:
        return OrderState.CompleteNotShipped;
    }
  }
}

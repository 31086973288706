import { FormEvent } from "react";
import { SuccessMessages } from "../../../boot/constants";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { VQAuthForgotPasssword } from "vivaquant-components-library";
import { useRootStore } from "../../../stores/storeContext";
import { SlHeaderLogoOnly } from "../../../components/sl-header";

export const ForgotPasswordPage = observer(() => {
  const { forgotPassword } = useRootStore().userStore;
  const {
    email,
    invalidEmail,
    isSubmit,
    isErrorForgot,
    isLoading,
    setEmail,
    setSubmit,
    setErrorForgot,
    setLoading,
    cleanData
  } = useRootStore().forgotFormStore;

  const navigate = useNavigate();

  const onSubmit = async(event: FormEvent) => {
    event.preventDefault();
    setSubmit();
    if (invalidEmail || !email) { return; }
    setLoading(true);
    const result = await forgotPassword(email);
    if (result) {
      cleanData();
      navigate("../login", { state: { message: SuccessMessages.successForgot } });
    } else {
      setErrorForgot(true);
    }
    setLoading(false);
  };

  return (
    <VQAuthForgotPasssword
      header={(<SlHeaderLogoOnly></SlHeaderLogoOnly>)}
      isLoading={isLoading}
      isErrorForgot={isErrorForgot}
      onSubmit={onSubmit}
      email={email}
      setEmail={setEmail}
      isSubmit={isSubmit}
      invalidEmail={invalidEmail}
    ></VQAuthForgotPasssword>
  );
});

import { IProfileStoreModelSnapShot } from "../stores/profile.store";

export const ProfileInitialState: IProfileStoreModelSnapShot = {
  firstName: {
    name: "First Name",
    value: "",
    type: "input",
    fieldId: "firstName"
  },
  lastName: {
    name: "Last Name",
    value: "",
    type: "input",
    fieldId: "lastName"
  },
  email: {
    name: "Email",
    value: "",
    type: "input",
    fieldId: "email"
  },
  username: {
    name: "Username",
    value: "",
    type: "input",
    fieldId: "username"
  },
  phoneNumber: {
    name: "Phone Number",
    value: "",
    type: "phone",
    fieldId: "phoneNumber"
  },
  is2FaEnabled: {
    name: "Enable 2-factor authentication",
    value: "false",
    type: "checkbox",
    isChecked: false,
    fieldId: "is2FaEnabled"
  }
};

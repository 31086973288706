import { types, applySnapshot, SnapshotIn, flow, cast } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { CreateAccountInitialState } from "../states/create-account.state";
import {
  ValidationUtils,
  accountsAPI,
  userAPI,
  ApiResponseType,
  TPaginated,
  DateUtils,
  Permissions
} from "../services";
import {
  AccountTypes,
  AccountModels,
  AccountPrices,
  AccountLinkedTypes,
  AccountSetTermsDurationInDays,
  AccountFeeForServicePrices
} from "../boot/constants";
import { TBriefAccount, TShortAccount } from "../models/account";
import { ApiResponseDto } from "../models";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import uniqBy from "lodash.uniqby";

export const CreateAccountModel = types
  .model("CreateAccountModel", {
    isEdit: types.optional(types.boolean, false),
    nameForEdit: types.maybeNull(types.string),
    isLoading: types.optional(types.boolean, false),
    openedStep: types.optional(types.number, 0),
    accountsNames: types.optional(types.array(types.string), []),
    id: types.maybeNull(types.number),
    tiersOptions: types.frozen(),
    dealersOptions: types.frozen(),

    type: types.optional(types.number, AccountTypes.Clinic),
    linkedType: types.optional(types.number, AccountLinkedTypes.None),
    status: types.maybe(InputFormModel),
    name: types.maybe(InputFormModel),
    salesRepId: types.maybe(InputFormModel),
    salesManagerId: types.maybe(InputFormModel),
    model: types.maybe(InputFormModel),
    standardPrice: types.maybe(InputFormModel),
    mctRecordingPrice: types.maybe(InputFormModel),
    eventRecordingPrice: types.maybe(InputFormModel),
    extendedHolterPrice: types.maybe(InputFormModel),
    shortTermHolterPrice: types.maybe(InputFormModel),
    homeHookupPrice: types.maybe(InputFormModel),
    isHomeHookupEnabled: types.optional(types.boolean, false),

    onBoardingDate: types.maybe(InputFormModel),
    effectiveDate: types.maybe(InputFormModel),
    mainClinicId: types.maybe(InputFormModel),
    connectionId: types.maybe(InputFormModel),
    monitoringCenterId: types.maybe(InputFormModel),
    tier1: types.maybe(InputFormModel),
    tier2: types.maybe(InputFormModel),
    netTermsDurationInDays: types.maybe(InputFormModel),

    firstName: types.maybe(InputFormModel),
    lastName: types.maybe(InputFormModel),
    email: types.maybe(InputFormModel),
    phone: types.maybe(InputFormModel),

    streetShipping: types.maybe(InputFormModel),
    cityShipping: types.maybe(InputFormModel),
    stateShipping: types.maybe(InputFormModel),
    zipCodeShipping: types.maybe(InputFormModel)
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setEdit(isEdit: boolean) {
      self.isEdit = isEdit;
    }
  }))
  .actions(self => {
    const setStep = (step: number) => {
      self.openedStep = step;
    };

    const setId = (id: number) => {
      self.id = id;
    };

    const setAccountType = (type: number) => {
      self.type = type;
      self.standardPrice.setValue("");
      setTimeout(() => {
        self.monitoringCenterId.setLoading(true);
      }, 1);
      self.monitoringCenterId.setValue("");
      self.monitoringCenterId.setDefaultValue({ value: "", label: "" });
      setTimeout(() => {
        self.monitoringCenterId.setLoading(false);
      }, 1);
      fetchSalesModel();
      fetchMonitoringCenters();
    };

    const setAccountLinkedType = (type: number) => {
      if (self.linkedType !== type) {
        self.linkedType = type;
        self.mainClinicId.setValue("");
        if (type === AccountLinkedTypes.LinkedToMain) {
          fetchMainClinics();
        }
      }
    };

    const resetForm = () => {
      self.isEdit = false;
      self.nameForEdit = null;
      self.openedStep = 0;
      self.accountsNames = cast([]);
      self.id = null;
      self.isHomeHookupEnabled = false;
      applySnapshot(self, CreateAccountInitialState);
    };

    const validateFormBasic = (hasPermission: Function): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      const isSplitBill = self.model.value === AccountModels.SplitBill.toString();

      if (!self.name?.value) {
        errors.name = "Please fill the field";
        isValid = false;
      } else {
        if (self.isEdit) {
          if (self.nameForEdit !== self.name?.value && self.accountsNames.includes(self.name?.value)) {
            errors.name = "This account name already exists";
            isValid = false;
          }
        } else {
          if (self.accountsNames.includes(self.name?.value)) {
            errors.name = "This account name already exists";
            isValid = false;
          }
        }
      }

      if (!self.cityShipping?.value) {
        errors.cityShipping = "Please fill the field";
        isValid = false;
      }

      if (!self.salesRepId?.value) {
        errors.salesRepId = "Please fill the field";
        isValid = false;
      }

      if (!self.salesManagerId?.value) {
        errors.salesManagerId = "Please fill the field";
        isValid = false;
      }

      if (!self.netTermsDurationInDays?.value) {
        errors.netTermsDurationInDays = "Please fill the field";
        isValid = false;
      }

      if (isSplitBill) { 
        /* empty */ 
      } else if (!self.standardPrice?.value) {
        errors.standardPrice = "Please fill the field";
        isValid = false;
      } else {
        if (self.model.value === AccountModels.StandardSubscription.toString()) {
          if (!hasPermission(Permissions.SetCustomPriceLower)
            && ((self.type === AccountTypes.Clinic && +self.standardPrice.value < AccountPrices.Clinic)
            || (self.type === AccountTypes.IDTF && +self.standardPrice.value < AccountPrices.IDTF)
            || (self.type === AccountTypes.Distributor && +self.standardPrice.value < AccountPrices.Distributor))
          ) {
            errors.standardPrice = "Subscription rate is lower than standard. Requires executive approval";
            isValid = false;
          }
        } else if (self.model.value === AccountModels.FeeForService.toString()) {
          if (!hasPermission(Permissions.SetCustomPriceLower) 
            && +self.standardPrice.value < AccountFeeForServicePrices.DeviceLease
          ) {
            errors.standardPrice = "Subscription rate is lower than standard. Requires executive approval";
            isValid = false;
          }
        }
      }

      if ((self.type === AccountTypes.Clinic || self.type === AccountTypes.Distributor) 
        && self.model.value === AccountModels.FeeForService.toString()
      ) {
        if (!self.mctRecordingPrice?.value) {
          errors.mctRecordingPrice = "Please fill the field";
          isValid = false;
        } else {
          if (!hasPermission(Permissions.SetCustomPriceLower) 
            && +self.mctRecordingPrice.value < AccountFeeForServicePrices.MctRecordingPrice
          ) {
            errors.mctRecordingPrice = "MCT Recording rate is lower than standard. Requires executive approval";
            isValid = false;
          }
        }

        if (!self.eventRecordingPrice?.value) {
          errors.eventRecordingPrice = "Please fill the field";
          isValid = false;
        } else {
          if (!hasPermission(Permissions.SetCustomPriceLower) 
            && +self.eventRecordingPrice.value < AccountFeeForServicePrices.EventRecordingPrice
          ) {
            errors.eventRecordingPrice = "Event Recording rate is lower than standard. Requires executive approval";
            isValid = false;
          }
        }

        if (!self.extendedHolterPrice?.value) {
          errors.extendedHolterPrice = "Please fill the field";
          isValid = false;
        } else {
          if (!hasPermission(Permissions.SetCustomPriceLower) 
            && +self.extendedHolterPrice.value < AccountFeeForServicePrices.ExtendedHolterPrice
          ) {
            errors.extendedHolterPrice = "Extended Holter rate is lower than standard. Requires executive approval";
            isValid = false;
          }
        }

        if (!self.shortTermHolterPrice?.value) {
          errors.shortTermHolterPrice = "Please fill the field";
          isValid = false;
        } else {
          if (!hasPermission(Permissions.SetCustomPriceLower) 
            && +self.shortTermHolterPrice.value < AccountFeeForServicePrices.ShortTermHolterPrice
          ) {
            errors.shortTermHolterPrice = "Short Term Holter rate is lower than standard. Requires executive approval";
            isValid = false;
          }
        }
      }
      
      if (isSplitBill) { 
        /* empty */ 
      } else if (
        (self.type === AccountTypes.Clinic || self.type === AccountTypes.Distributor) 
        && self.isHomeHookupEnabled
      ) {
        if (!self.homeHookupPrice?.value) {
          errors.homeHookupPrice = "Please fill the field";
          isValid = false;
        } else {
          if (!hasPermission(Permissions.SetCustomPriceLower) 
            && +self.homeHookupPrice.value < AccountFeeForServicePrices.HomeHookupPrice
          ) {
            errors.homeHookupPrice = "Home Hookup rate is lower than standard. Requires executive approval";
            isValid = false;
          }
        }
      }

      if (self.type === AccountTypes.Clinic
        && self.linkedType === AccountLinkedTypes.LinkedToMain
        && !self.mainClinicId?.value
      ) {
        errors.mainClinicId = "Please fill the field";
        isValid = false;
      }

      if (self.tier1?.value
        && self.tier2?.value
        && self.tier1?.value === self.tier2?.value) {
        errors.tier2 = "Shouldn't be able to have the same Tier1 and Tier2";
        isValid = false;
      }

      return {
        errors,
        isValid
      };
    };

    const validateFormContact = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.firstName?.value) {
        errors.firstName = "Please fill the field";
        isValid = false;
      }

      if (!self.lastName?.value) {
        errors.lastName = "Please fill the field";
        isValid = false;
      }

      if (!self.email?.value) {
        errors.email = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isEmailValid(self.email?.value)) {
          errors.email = "Wrong email";
          isValid = false;
        }
      }

      if (!self.phone?.value) {
        errors.phone = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isPhonedValid(self.phone?.value)) {
          errors.phone = "Please enter valid phone number";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const validateFormShipping = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.streetShipping?.value) {
        errors.streetShipping = "Please fill the field";
        isValid = false;
      }

      if (!self.cityShipping?.value) {
        errors.cityShipping = "Please fill the field";
        isValid = false;
      }

      if (!self.zipCodeShipping?.value) {
        errors.zipCodeShipping = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isZipCodeValid(self.zipCodeShipping?.value)) {
          errors.zipCodeShipping = "Please enter valid Zip/Postal Code";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const saveBasic = flow(function* saveBasic() {
      const isSplitBill = self.model.value === AccountModels.SplitBill.toString();

      const data: any = {
        type: self.type,
        name: self.name.value,
        city: self.cityShipping.value,
        state: self.stateShipping.value,
        salesRepId: +self.salesRepId.value,
        dealerId: null,
        salesManagerId: +self.salesManagerId.value,
        status: +self.status.value,
        model: +self.model.value,
        onBoardingDate: self.onBoardingDate.value
          ? DateUtils.toServerFormat(new Date(self.onBoardingDate.value))
          : null,
        effectiveDate: self.effectiveDate.value
          ? DateUtils.toServerFormat(new Date(self.effectiveDate.value))
          : null,
        isMainClinic: self.type === AccountTypes.Clinic && self.linkedType === AccountLinkedTypes.Main,
        tiers: [],
        netTermsDurationInDays: +self.netTermsDurationInDays.value || AccountSetTermsDurationInDays[0]
      };

      if (isSplitBill) {
        data.standardPrice = 0;
      } else {
        data.standardPrice = +self.standardPrice.value;
      }

      if ((self.type === AccountTypes.Clinic || self.type === AccountTypes.Distributor) 
        && self.model.value === AccountModels.FeeForService.toString()
      ) {
        data.mctRecordingPrice = +self.mctRecordingPrice.value;
        data.eventRecordingPrice = +self.eventRecordingPrice.value;
        data.extendedHolterPrice = +self.extendedHolterPrice.value;
        data.shortTermHolterPrice = +self.shortTermHolterPrice.value;
      }

      if (self.type === AccountTypes.Clinic || self.type === AccountTypes.Distributor) {
        data.isHomeHookupEnabled = self.isHomeHookupEnabled;
        if (!isSplitBill) {
          data.homeHookupPrice = +self.homeHookupPrice.value;
        }
      }

      if (self.dealersOptions) {
        const dealer = self.dealersOptions.find((el: any) => +el.value === +self.salesRepId.value);
        if (dealer) {
          data.dealerId = +dealer.value;
        }
      }

      if (self.type === AccountTypes.Clinic && self.tier1.value) {
        data.tiers.push(+self.tier1.value);
      }

      if (self.type === AccountTypes.Clinic && self.tier2.value) {
        data.tiers.push(+self.tier2.value);
      }

      if (self.type === AccountTypes.Clinic && self.linkedType === AccountLinkedTypes.LinkedToMain) {
        data.mainClinicId = +self.mainClinicId.value;
      }

      if (self.type === AccountTypes.Clinic && self.connectionId.value) {
        data.connectionId = +self.connectionId.value;
      }

      if (self.type !== AccountTypes.IDTF && self.monitoringCenterId.value) {
        data.monitoringCenterId = +self.monitoringCenterId.value;
      }

      if (self.isEdit) {
        data.id = self.id;
      }

      try {
        const result = self.isEdit
          ? yield accountsAPI.updateAccount(data)
          : yield accountsAPI.addAccount(data);
        fetchAccountInfo();
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const saveContact = flow(function* saveContact() {
      try {
        const result = yield accountsAPI.updateContact({
          firstName: self.firstName.value,
          lastName: self.lastName.value,
          email: self.email.value,
          phone: "+" + self.phone.value.replace(/[^\w\s]/gi, "").replace(/\s/g, ""),
          id: self.id
        });
        fetchAccountInfo();
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const saveShipping = flow(function* saveContact() {
      try {
        const result = yield accountsAPI.updateShipping({
          street: self.streetShipping.value,
          city: self.cityShipping.value,
          state: self.stateShipping.value,
          zipCode: self.zipCodeShipping.value,
          id: self.id
        });
        fetchAccountInfo();
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const fetchData = flow(function* fetchData(id: string) {
      if (id) {
        self.setEdit(true);
        setId(+id);
      }
      yield Promise.all([
        fetchSalesReps(),
        fetchSalesManagers(),
        fetchConnectionNotAssigned(),
        fetchMonitoringCenters()
      ]);  
      if (!self.isEdit) {
        fetchSalesModel();
        fetchPrices();
        setStep(1);
      } else {
        yield fetchAccountInfo();
      }
    });

    const fetchPrices = (data?: any) => {
      self.standardPrice.setValue(data?.standardPrice?.toString() || "");
          
      self.mctRecordingPrice.setValue(data?.mctRecordingPrice?.toString() 
        || AccountFeeForServicePrices.MctRecordingPrice.toString());
      self.eventRecordingPrice.setValue(data?.eventRecordingPrice?.toString()
        || AccountFeeForServicePrices.EventRecordingPrice.toString());
      self.extendedHolterPrice.setValue(data?.extendedHolterPrice?.toString() 
        || AccountFeeForServicePrices.ExtendedHolterPrice.toString());
      self.shortTermHolterPrice.setValue(data?.shortTermHolterPrice?.toString() 
        || AccountFeeForServicePrices.ShortTermHolterPrice.toString());
      self.homeHookupPrice.setValue(data?.homeHookupPrice?.toString() 
        || AccountFeeForServicePrices.HomeHookupPrice.toString());
    };

    const fetchSalesModel = () => {
      let options: any = [];
      switch (self.type) {
        case AccountTypes.Clinic:
          options = [
            { value: AccountModels.StandardSubscription.toString(), label: "Subscription" },
            { value: AccountModels.FeeForService.toString(), label: "Fee For Service" },
            { value: AccountModels.SplitBill.toString(), label: "Split Bill" }
          ];
          break;
        case AccountTypes.Distributor:
          options = [
            { value: AccountModels.StandardSubscription.toString(), label: "Subscription" },
            { value: AccountModels.FeeForService.toString(), label: "Fee For Service" }
          ];
          break;
        case AccountTypes.IDTF:
          options = [
            { value: AccountModels.StandardSubscription.toString(), label: "Subscription" }
          ];
          break;
      }
      
      self.model.setLoading(true);
      self.model.setOptions(options);
      self.model.setValue(options[0].value);
      self.model.setDefaultValue(options[0]);
      setTimeout(() => {
        self.model.setLoading(false);
      }, 1);
    };

    const fetchMainClinics = flow(function* fetchMainClinics(idSelected? : number, name? : string) {
      const { data }: ApiResponseDto<TPaginated<TBriefAccount>>
        = yield accountsAPI.getAccountsMainClinic();
      self.mainClinicId.setOptions(data.entries
        .filter((item: TBriefAccount) => {
          return item.id !== self.id;
        })
        .map((item: TBriefAccount) => {
          return { value: item.id.toString(), label: item.name };
        })
      );
      if (idSelected) {
        self.mainClinicId.setValue((idSelected || "").toString());
        const optionMainClinic = self.mainClinicId.options
          .find((item) => item.value === (idSelected || "").toString());
        if (optionMainClinic) {
          self.mainClinicId.setDefaultValue({ value: optionMainClinic.value, label: optionMainClinic.label });
        } else {
          self.mainClinicId.setOptions([{ 
            value: idSelected.toString(), 
            label: name 
          }]);
          self.mainClinicId.setDefaultValue({
            value: idSelected.toString(), 
            label: name
          });
        }
      }
    });

    const fetchConnectionNotAssigned = flow(function* fetchConnectionNotAssigned() {
      const { data }: ApiResponseDto<TPaginated<TShortAccount>> = yield accountsAPI.getConnectionNotAssigned();
      if (data) {
        self.connectionId.setOptions(data.entries.map((item: TShortAccount) => {
          return { value: item.id.toString(), label: item.name };
        }));
      }
    });

    const fetchMonitoringCenters = flow(function* fetchMonitoringCenters() {
      const { data }: ApiResponseDto<TPaginated<TBriefAccount>>
        = yield accountsAPI.getAccountsByType(4, "", 9999, self.type);
      if (data) {
        self.monitoringCenterId.setOptions(data.entries.map((item: TBriefAccount) => {
          return { value: item.id.toString(), label: item.name };
        }));
      }
    });

    const fetchSalesReps = flow(function* fetchSalesReps() {
      const { data }: ApiResponseDto<TPaginated<TShortAccount>> = yield userAPI.getSalesReps(0, 9999);
      const { data: dataDealers }: ApiResponseDto<TPaginated<TShortAccount>>
        = yield userAPI.getSalesReps(0, 9999, "Dealer");

      const options = data.entries
        .map((item: TShortAccount) => {
          return { value: item.id.toString(), label: item.name };
        });
      const optionsDealers = dataDealers.entries
        .map((item: TShortAccount) => {
          return { value: item.id.toString(), label: item.name };
        });

      self.salesRepId.setOptions(uniqBy(options.concat(optionsDealers), "value"));
      self.tier1.setOptions(uniqBy(options.concat(optionsDealers), "value"));
      self.tier2.setOptions(uniqBy(options.concat(optionsDealers), "value"));
      self.tiersOptions = uniqBy(options.concat(optionsDealers), "value");
      self.dealersOptions = optionsDealers;
    });

    const fetchSalesManagers = flow(function* fetchSalesManagers() {
      const { data }: ApiResponseDto<TPaginated<TShortAccount>>
        = yield userAPI.getSalesReps(0, 9999, "Sales Manager");
      self.salesManagerId.setOptions(data.entries.map((item: TShortAccount) => {
        return { value: item.id.toString(), label: item.name };
      }));
    });

    const fetchAccountInfo = flow(function* fetchAccountInfo() {
      if (!self.id) { return; }
      try {
        const response: ApiResponseType<any> = yield accountsAPI.getAccountInfo(self.id);
        if (response.isOk) {
          const { data } = response.data;
          self.type = data.type;
          fetchSalesModel();

          self.status.setValue(data.status.toString());
          const optionStatus = self.status.options.find((item) => item.value === data.status.toString());
          if (optionStatus) {
            self.status.setDefaultValue({ value: optionStatus.value, label: optionStatus.label });
          }

          self.name.setValue(data.name);
          self.nameForEdit = data.name;
          getAccounts();

          self.salesRepId.setValue((data.salesRep?.id || "").toString());
          const optionSalesRep = self.salesRepId.options
            .find((item) => item.value === (data.salesRep?.id || "").toString());
          if (optionSalesRep) {
            self.salesRepId.setDefaultValue({ value: optionSalesRep.value, label: optionSalesRep.label });
          } else {
            self.salesRepId.setOptions([{ 
              value: (data.salesRep?.id || "").toString(), 
              label: (data.salesRep?.name || "") 
            }]);
            self.salesRepId.setDefaultValue({
              value: (data.salesRep?.id || "").toString(), 
              label: (data.salesRep?.name || "")
            });
          }

          self.salesManagerId.setValue((data.salesManager?.id || "").toString());
          const optionSalesManager = self.salesManagerId.options
            .find((item) => item.value === (data.salesManager?.id || "").toString());
          if (optionSalesManager) {
            self.salesManagerId.setDefaultValue({ value: optionSalesManager.value, label: optionSalesManager.label });
          } else {
            self.salesManagerId.setOptions([{ 
              value: (data.salesManagerId?.id || "").toString(), 
              label: (data.salesManagerId?.name || "") 
            }]);
            self.salesManagerId.setDefaultValue({
              value: (data.salesManagerId?.id || "").toString(), 
              label: (data.salesManagerId?.name || "")
            });
          }

          if (data.tiers) {
            if (data.tiers[0]) {
              const optionTier = self.tier1.options
                .find((item) => item.value === (data.tiers[0]?.id || "").toString());
              if (optionTier) {
                self.tier1.setValue(optionTier.value);
                self.tier1.setDefaultValue({ value: optionTier.value, label: optionTier.label });
              }
            }

            if (data.tiers[1]) {
              const optionTier = self.tier2.options
                .find((item) => item.value === (data.tiers[1]?.id || "").toString());
              if (optionTier) {
                self.tier2.setValue(optionTier.value);
                self.tier2.setDefaultValue({ value: optionTier.value, label: optionTier.label });
              }
            }

            resetTearOptions();
          }

          self.model.setValue(data.salesModel.toString());
          const optionModel = self.model.options.find((item) => item.value === data.salesModel.toString());
          if (optionModel) {
            self.model.setDefaultValue({ value: optionModel.value, label: optionModel.label });
          }

          self.isHomeHookupEnabled = data.isHomeHookupEnabled;

          fetchPrices(data);

          self.onBoardingDate.setValue(data.onBoardingDate
            ? dayjs.utc(data.onBoardingDate).format("MM/DD/YYYY")
            : "");

          self.effectiveDate.setValue(data.effectiveDate
            ? dayjs.utc(data.effectiveDate).format("MM/DD/YYYY")
            : data.onBoardingDate
              ? dayjs.utc(data.onBoardingDate).format("MM/DD/YYYY")
              : "");

          if (data.isMainClinic) {
            self.linkedType = AccountLinkedTypes.Main;
          } else if (data.mainClinic) {
            self.linkedType = AccountLinkedTypes.LinkedToMain;
            yield fetchMainClinics(data.mainClinic.id, data.mainClinic.name);
          } else {
            self.linkedType = AccountLinkedTypes.None;
          }

          if (data.connectedTo) {
            const selectedOption = { value: data.connectedTo.id?.toString(), label: data.connectedTo.name };
            const newOptions = self.connectionId.options
              .map((el: any) => {
                return { label: el.label, value: el.value };
              });
            newOptions.unshift(selectedOption);
            self.connectionId.setOptions(newOptions);
            self.connectionId.setValue(selectedOption.value);
            self.connectionId.setDefaultValue(selectedOption);
          } else {
            self.connectionId.setValue("");
            self.connectionId.setDefaultValue({ value: "", label: "" });
          }

          if (data.monitoringCenter) {
            self.monitoringCenterId.setValue(data.monitoringCenter.id?.toString());
            const selectedOption
              = self.monitoringCenterId.options.find((item) => item.value === data.monitoringCenter.id?.toString());
            if (selectedOption) {
              self.monitoringCenterId.setDefaultValue({ value: selectedOption.value, label: selectedOption.label });
            } else {
              self.monitoringCenterId.setOptions([{ 
                value: (data.monitoringCenter?.id || "").toString(), 
                label: (data.monitoringCenter?.name || "") 
              }]);
              self.monitoringCenterId.setDefaultValue({
                value: (data.monitoringCenter?.id || "").toString(), 
                label: (data.monitoringCenter?.name || "")
              });
            }
          } else {
            self.monitoringCenterId.setValue("");
            self.monitoringCenterId.setDefaultValue({ value: "", label: "" });
          }

          if (data.netTermsDurationInDays) {
            self.netTermsDurationInDays.setValue(data.netTermsDurationInDays.toString());
            self.netTermsDurationInDays.setDefaultValue({
              value: data.netTermsDurationInDays.toString(),
              label: data.netTermsDurationInDays.toString()
            });
          }

          self.firstName.setValue(data.billingContact?.firstName || "");
          self.lastName.setValue(data.billingContact?.lastName || "");
          self.email.setValue(data.billingContact?.email || "");
          self.phone.setValue(data.billingContact?.phone || "");

          self.streetShipping.setValue(data.shippingAddress?.street || "");
          self.cityShipping.setValue(data.shippingAddress?.city || "");
          self.stateShipping.setValue(data.shippingAddress?.state || "");
          const optionState = self.stateShipping.options
            .find((item) => item.value === data.shippingAddress?.state);
          if (optionState) {
            self.stateShipping.setDefaultValue({ value: optionState.value, label: optionState.label });
          }
          self.zipCodeShipping.setValue(data.shippingAddress?.zipCode || "");
        }
      } catch (error) {
        console.error(error);
      }
    });

    const resetTearOptions = () => {
      if (!self.tiersOptions) { return; }
      self.tier1.setLoading(true);
      self.tier2.setLoading(true);

      self.tier1.setOptions(self.tiersOptions.filter((el:any) => el.value !== self.salesRepId.value));
      self.tier2.setOptions(self.tiersOptions.filter((el:any) => el.value !== self.salesRepId.value));

      if (self.tier1?.value === self.salesRepId?.value) {
        self.tier1.setValue("");
        self.tier1.setDefaultValue({ value: "", label: "" });
      } else {
        const optionTier = self.tier1.options
          .find((item) => item.value === self.tier1?.value);
        if (optionTier) {
          self.tier1.setValue(optionTier.value);
          self.tier1.setDefaultValue({ value: optionTier.value, label: optionTier.label });
        }
      }

      if (self.tier2?.value === self.salesRepId?.value) {
        self.tier2.setValue("");
        self.tier2.setDefaultValue({ value: "", label: "" });
      } else {
        const optionTier = self.tier2.options
          .find((item) => item.value === self.tier2?.value);
        if (optionTier) {
          self.tier2.setValue(optionTier.value);
          self.tier2.setDefaultValue({ value: optionTier.value, label: optionTier.label });
        }
      }

      if (!self.tier1?.value && self.tier2?.value) {
        const optionTier = self.tier1.options
          .find((item) => item.value === self.tier2?.value);
        if (optionTier) {
          self.tier1.setValue(optionTier.value);
          self.tier1.setDefaultValue({ value: optionTier.value, label: optionTier.label });
        }
        self.tier2.setValue("");
        self.tier2.setDefaultValue({ value: "", label: "" });
      }
      setTimeout(() => {
        self.tier1.setLoading(false);
        self.tier2.setLoading(false);
      }, 1);
    };

    const getAccounts = flow(function* getAccounts() {
      const response: ApiResponseType<TPaginated<TBriefAccount>>
        = yield accountsAPI.getAccountsByType(self.type, self.name.value, 5);
      if (!response.isOk || !response.data) {
        return;
      }
      self.accountsNames = cast(response.data.entries.map((item: TBriefAccount) => {
        return item.name;
      }));
    });

    const setIsHomeHookupEnabled = (value: boolean) => {
      self.isHomeHookupEnabled = value;
    };

    const setDeviceLeasePrise = () => {
      self.standardPrice.setValue(AccountFeeForServicePrices.DeviceLease.toString());
    };

    return {
      resetForm,
      validateFormBasic,
      validateFormContact,
      validateFormShipping,
      saveBasic,
      saveContact,
      saveShipping,
      setStep,
      setId,
      setAccountType,
      setAccountLinkedType,
      fetchData,
      getAccounts,
      resetTearOptions,
      setIsHomeHookupEnabled,
      setDeviceLeasePrise
    };
  });

export interface ICreateAccountModelSnapShot extends SnapshotIn<typeof CreateAccountModel> {}

import { FC, FormEvent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessages, PasswordRules, SuccessMessages } from "../../../boot/constants";
import { VQAuthCreatePassword } from "vivaquant-components-library";
import { useRootStore } from "../../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { SlHeaderLogoOnly } from "../../../components/sl-header";

export const RecoveryPasswordPage: FC = observer(() => {
  const { token } = useParams<{token: string}>();
  const { isRecoveryLinkValid, recoveryPassword } = useRootStore().userStore;
  const {
    password,
    passwordConfirm,
    invalidPassword,
    invalidPasswordConfirm,
    isSubmit,
    isShowPassword,
    isShowPasswordConfirm,
    isErrorRecovery,
    isLoading,
    setPassword,
    setPasswordConfirm,
    setSubmit,
    setShowPassword,
    setShowPasswordConfirm,
    setErrorRecovery,
    setLoading,
    cleanData
  } = useRootStore().recoveryFormStore;

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      return;
    }
    (isRecoveryLinkValid(token) as unknown as Promise<boolean>).then(r => {
      if (!r) {
        navigate("../login", {
          state: {
            message: ErrorMessages.recoveryLinkInvalid,
            isError: true
          }
        });
      }
    });
  }, []);

  const showPassword = () => {
    setShowPassword();
  };

  const showPasswordConfirm = () => {
    setShowPasswordConfirm();
  };

  const onSubmit = async(event: FormEvent) => {
    event.preventDefault();
    setSubmit();
    if (invalidPassword || invalidPasswordConfirm || !password || !passwordConfirm) { return; }
    if (password !== passwordConfirm) {
      return setErrorRecovery(ErrorMessages.passwordsDidNotMatch);
    }
    setLoading(true);
    const result = await recoveryPassword(token, password);
    if (result) {
      cleanData();
      navigate("../login", { state: { message: SuccessMessages.successRecovery } });
    } else {
      setErrorRecovery(ErrorMessages.recoveryError);
    }
    setLoading(false);
  };

  return (
    <VQAuthCreatePassword
      header={(<SlHeaderLogoOnly></SlHeaderLogoOnly>)}
      isLoading={isLoading}
      isErrorSave={isErrorRecovery}
      setPassword={setPassword}
      setPasswordConfirm={setPasswordConfirm}
      onSubmit={onSubmit}
      showPassword={showPassword}
      showPasswordConfirm={showPasswordConfirm}
      isSubmit={isSubmit}
      password={password}
      passwordConfirm={passwordConfirm}
      isShowPassword={isShowPassword}
      isShowPasswordConfirm={isShowPasswordConfirm}
      invalidPassword={invalidPassword}
      invalidPasswordConfirm={invalidPasswordConfirm}
      passwordRules={PasswordRules}
    ></VQAuthCreatePassword>
  );
});

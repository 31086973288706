export class ApiErrorDto {
  public readonly isOk: false = false;
  public readonly error: string;
  public readonly status: number;

  constructor(status: number, error: string) {
    this.status = status;
    this.error = error;
  }
}

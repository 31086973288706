import { types, flow, Instance } from "mobx-state-tree";
import { ApiResponseType, authAPI, userAPI, Permissions, PermissionService } from "../services";
import { ILoginResponse } from "../models/v2/auth/ILoginResponse";
import { API } from "../boot";
import { IIsRecoveryLinkValidResponse } from "../models/v2/auth/IIsRecoveryLinkValidResponse";
import { IIsInviteTokenValidRequest } from "../models/v2/auth/IIsInviteTokenValidRequest";
import { IUpdateProfileRequest } from "../models/v2/User/IUpdateProfileRequest";

const ProfileModel = types
  .model("ProfileModel", {
    firstname: types.maybeNull(types.string),
    lastname: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    login: types.maybeNull(types.string),
    usersGroup: types.maybeNull(types.number),
    groups: types.maybeNull(types.array(types.string)),
    is2FaEnabled: types.maybeNull(types.boolean),
    phone: types.maybeNull(types.string),
    canInvite: types.maybeNull(types.boolean),
    imageUrl: types.maybeNull(types.string),
    signatureUrl: types.maybeNull(types.string),
    permissions: types.maybeNull(types.number),
    id: types.maybeNull(types.number),
    groupName: types.maybeNull(types.string)
  });

export interface IProfileModel extends Instance<typeof ProfileModel> {}

export const UserStoreModel = types
  .model("UserStoreModel", {
    isLoading: types.optional(types.boolean, false),
    isAuth: types.maybe(types.boolean),
    profile: types.maybe(ProfileModel)
  })
  .actions(self => ({
    setIsAuth(value: boolean) {
      self.isAuth = value;
    }
  }))
  .actions(self => {
    const checkAuthorized = flow(function* checkAuthorized() {
      try {
        self.isLoading = true;
        const token = localStorage.getItem("token");
        if (!token) {
          self.setIsAuth(false);
          return;
        }
        (API.defaults.headers as unknown as Record<string, string>).Authorization = token;
        const result: ApiResponseType<IProfileModel> = yield userAPI.getCurrentUser();
        if (result.isOk) {
          self.setIsAuth(true);
          self.profile = result.data;
        } else {
          self.setIsAuth(false);
        }
        return result;
      } catch (e) {
        self.setIsAuth(false);
      } finally {
        self.isLoading = false;
      }
    });

    const hasPermission = (perm: Permissions) => {
      return PermissionService.hasPermission(self.profile, perm);
    };

    const login = flow(function* login(email: string, password: string) {
      try {
        const response: ApiResponseType<ILoginResponse> = yield authAPI.login(email, password);
        if (response.isOk && response.data.value) {
          if (response.data.isToken) {
            const token = "Bearer " + response.data.value;
            localStorage.setItem("token", token);
            (API.defaults.headers as unknown as Record<string, string>).Authorization = token;
            self.setIsAuth(true);
            self.profile = response.data.profile;
            return true;
          } else {
            return { tel: response.data.value };
          }
        } else {
          self.setIsAuth(false);
          return false;
        }
      } catch (err) {
        self.setIsAuth(false);
        return false;
      }
    });

    const loginTwoFactor = flow(function* loginTwoFactor(email: string, code: string) {
      try {
        const response: ApiResponseType<ILoginResponse> = yield authAPI.loginTwoFactor(email, code);
        if (!response.isOk) {
          self.setIsAuth(false);
          return false;
        }
        const token = "Bearer " + response.data.value;
        localStorage.setItem("token", token);
        (API.defaults.headers as unknown as Record<string, string>).Authorization = token;
        self.setIsAuth(true);
        self.profile = response.data.profile;
        return true;
      } catch (err) {
        self.setIsAuth(false);
        return false;
      }
    });

    const resendLoginCode = flow(function* resendLoginCode(email: string) {
      try {
        const response: ApiResponseType<null> = yield authAPI.resendLoginCode(email);
        return response.isOk;
      } catch (e) {
        return false;
      }
    });

    const resendUpdateCode = flow(function* resendUpdateCode() {
      try {
        const response: ApiResponseType<null> = yield userAPI.resendUpdateCode();
        return response.isOk;
      } catch (e) {
        return false;
      }
    });

    const logout = () => {
      delete (API.defaults.headers as unknown as Record<string, string>).Authorization;
      localStorage.removeItem("token");
      self.setIsAuth(false);
    };

    const forgotPassword = flow(function* forgotPassword(email: string) {
      try {
        const result: ApiResponseType<null> = yield authAPI.resetPassword(email);
        return !!result.isOk;
      } catch (error) {
        return false;
      }
    });

    const isRecoveryLinkValid = flow(function* isRecoveryLinkValid(token: string) {
      try {
        const result: ApiResponseType<IIsRecoveryLinkValidResponse> = yield authAPI.isRecoveryLinkValid(token);
        return result.isOk ? result.data.isValid : false;
      } catch (error) {
        return false;
      }
    });

    const isTokenValid = flow(function* isTokenValid(token: string) {
      try {
        const result: ApiResponseType<IIsInviteTokenValidRequest> = yield authAPI.isInviteTokenValid(token);
        return result.isOk && result.data.isValid;
      } catch (error) {
        return false;
      }
    });

    const recoveryPassword = flow(function* recoveryPassword(token: string, password: string) {
      try {
        const result: ApiResponseType<null> = yield authAPI.recoveryPassword(token, password);
        return !!result.isOk;
      } catch (error) {
        return false;
      }
    });

    const completeInvite = flow(function* completeInvite(token: string, username: string, password: string) {
      try {
        const result: ApiResponseType<null> = yield authAPI.completeInvite(token, username, password);
        return !!result.isOk;
      } catch (error) {
        return false;
      }
    });

    const updateProfileData = (profile: IUpdateProfileRequest) => {
      self.profile.imageUrl = profile.imageUrl;
      self.profile.firstname = profile.firstname;
      self.profile.lastname = profile.lastname;
      self.profile.email = profile.email;
      self.profile.login = profile.username;
      self.profile.phone = profile.phone;
      self.profile.is2FaEnabled = profile.is2FaEnabled;
      self.profile.signatureUrl = profile.signatureUrl;
    };

    return {
      checkAuthorized,
      hasPermission,
      login,
      logout,
      loginTwoFactor,
      resendLoginCode,
      resendUpdateCode,
      forgotPassword,
      isRecoveryLinkValid,
      isTokenValid,
      recoveryPassword,
      completeInvite,
      updateProfileData
    };
  });

import React, { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { LayoutProps } from "./sl-layout.props";
import { Outlet, useLocation } from "react-router-dom";
import { LayoutWrapper } from "./sl-layout.presets";
import { DASHBOARD_ROUTES } from "../sl-header/sl-header-links";
import { SlHeader } from "../sl-header";

export const SlLayout: FC<LayoutProps> = observer(() => {
  const { pathname } = useLocation();

  const isDashboardPage = useMemo(() => {
    return !!DASHBOARD_ROUTES.find(item => item.link === pathname.substring(1));
  }, [pathname]);

  return (
    <LayoutWrapper greyedBg={isDashboardPage}>
      <SlHeader/>
      <Outlet/>
    </LayoutWrapper>
  );
});

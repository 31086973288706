import { ProcessAPIError } from "../decorators";
import { ApiResponseDto } from "../../models";
import { ApiResponseType } from "../types";
import { API } from "../../boot";
import {
  TOrderData,
  TOrderDataAPI,
  OrderSplitApiDto,
  OrderSplitComplete, 
  TCreateOrder, 
  TOrder, 
  TEditOrder, 
  OrderTotalDto, 
  OrderTotalApiDto,
} from "../../models/order";
import { OrderStateMapper } from "../mappers";
import { OrderItemDto } from "../../models/order/order-items.dto";
import { BaseFilterOrder } from "../../core";
import { IOrderModelSnapShot } from "../../stores/order-list.store";
import { TAccountContactData } from "../../models/account";

class OrderApi {
  @ProcessAPIError()
  public async getOderItems(orderId: number): Promise<ApiResponseDto<OrderItemDto[]>> {
    const { data } = await API.get(`/Orders/${orderId}/items`);
    return new ApiResponseDto<OrderItemDto[]>(data);
  }

  @ProcessAPIError()
  public async splitOrder(orderSplit: OrderSplitComplete): Promise<ApiResponseType<OrderSplitApiDto>> {
    const { data: order } = await API.post(`/orders/${orderSplit.id}/split`, orderSplit.items);
    return new ApiResponseDto(order);
  }

  @ProcessAPIError()
  public async getOrderData(id: number): Promise<ApiResponseDto<TOrderData>> {
    const { data } = await API.get<TOrderDataAPI>(`/orders/${id}`);

    return new ApiResponseDto({
      serialNumber: data.serialNumber,
      accountName: data.accountName,
      accountRep: data.accountRep,
      trackingNumber: data.trackingNumber || "",
      orderState: OrderStateMapper.getOrderState(data.orderState as unknown as number),
      shippingDate: data.shippingDate ? new Date(data.shippingDate) : null,
      deviceStartDate: data.deviceStartDate ? new Date(data.deviceStartDate) : null,
      salesRep: data.salesRep,
      independentRep: data.independentRep,
      isSplited: !!data.isSplited,
      items: data.items.map(i => ({
        salesItemId: i.salesItemId,
        id: i.id,
        name: i.name,
        quantityOrdered: i.quantityOrdered
      }))
    });
  }

  @ProcessAPIError()
  public async createOrder(order: TCreateOrder): Promise<ApiResponseDto<TOrder>> {
    const { data } = await API.post<TOrder>("orders", order);
    return new ApiResponseDto<TOrder>(data);
  }

  @ProcessAPIError()
  public async updateOrder(order: TEditOrder): Promise<ApiResponseDto<TOrder>> {
    const { data } = await API.patch<TOrder>(`orders/${order.id}`, order);
    return new ApiResponseDto<TOrder>(data);
  }

  @ProcessAPIError()
  public async deleteOrder(id: number): Promise<ApiResponseType<void>> {
    await API.delete(`orders/${id}`);
    return new ApiResponseDto(null);
  }

  @ProcessAPIError()
  public async getOrdersCounter(
    filter: BaseFilterOrder
  ): Promise<ApiResponseType<OrderTotalDto>> {
    const { data } = await API.get<OrderTotalApiDto>("orders/count", { params: filter });
    return new ApiResponseDto({
      All: data.all,
      NotShipped: data.notShipped,
      Suspended: data.suspended,
      Active: data.active,
      Cancelled: data.cancelled
    });
  }

  @ProcessAPIError()
  public async getOrders(filter: BaseFilterOrder, orderState: string)
    : Promise<ApiResponseDto<IOrderModelSnapShot[]>> {
    const { data } = await API.get("/orders",
      { params: orderState ? { ...filter, orderState: +orderState } : filter });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getExport(filter: BaseFilterOrder, orderState: string): Promise<ApiResponseType<any>> {
    const result = await API.get("/orders/export",
      { params: orderState ? { ...filter, orderState: +orderState } : filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getExportBatchMove(filter: BaseFilterOrder, orderState: string): Promise<ApiResponseType<any>> {
    const result = await API.get("/orders/export/batch-move",
      { params: orderState ? { ...filter, orderState: +orderState } : filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getAccountContactData(id: number): Promise<ApiResponseType<TAccountContactData>> {
    const { data } = await API.get(`orders/${id}/contacts`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getOrderInfo(id: number) {
    const result = await API.get(`/orders/${id}`);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getOrderInfoShipStation(id: number) {
    const result = await API.get(`/orders/${id}/ship-station`);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async updateOrderShipping(data: {
    street: string,
    city: string,
    state: string,
    zipCode: string,
    id: number }
  ) {
    const result = await API.put("/orders/shipping-address", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async updateContactShipStation(id: number, data: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  }) {
    const result = await API.put(`/orders/${id}/ship-station/billing-contact`, data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async checkShipmentInfo(orderId: string | number, serialNumbers: [string]) {
    const { data } = await API.post("/orders/shipment-info", { orderId, serialNumbers });
    return new ApiResponseDto(data);
  }
}

export const orderAPI = new OrderApi();

import { IObservableArray } from "mobx";
import { cast, flow, Instance, SnapshotIn, types } from "mobx-state-tree";
import dayjs from "dayjs";
import {
  AccountModels,
  BillingListCategories,
  BillingSort,
  BillingState,
  PAGINATION,
  SortDirection
} from "../boot/constants";
import { BaseFilterBilling } from "../core";
import { ApiResponseDto } from "../models";
import { TShortAccount } from "../models/account";
import { BillingTotalApiDto } from "../models/billing";
import { ApiResponseType, billingsApi, DateUtils, ExportUtils, TPaginated, userAPI } from "../services";
import { AdminModelShort } from "./admin-list.store";

const PartsBillingModel = types
  .model("PartsBillingModel", {
    amount: types.maybeNull(types.number),
    dateRange: types.maybeNull(types.model({
      endDate: types.maybeNull(types.string),
      startDate: types.maybeNull(types.string),
      hasRange: types.maybeNull(types.boolean)
    })),
    durationInDays: types.maybeNull(types.number),
    partName: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    quantity: types.maybeNull(types.number),
    dataSourceType: types.maybeNull(types.number)
  });

const BillingInfoModel = types
  .model("BillingInfoModel", {
    customerName: types.maybeNull(types.string),
    salesRep: types.maybeNull(AdminModelShort),
    salesModel: types.maybeNull(types.number),
    isMain: types.maybeNull(types.boolean),
    totalAmount: types.maybeNull(types.number),
    combinedTotalAmount: types.maybeNull(types.number),
    parts: types.optional(types.array(PartsBillingModel), []),
    discrepancies: types.optional(types.array(types.model({
      date: types.maybeNull(types.string),
      quantityByOrders: types.maybeNull(types.number),
      quantityByUtilization: types.maybeNull(types.number)
    })), []),
    tiers: types.maybeNull(types.array(AdminModelShort))
  });

const BillingModel = BillingInfoModel
  .props({
    linkedCustomers: types.optional(types.array(BillingInfoModel), [])
  });

export interface IBillingInfoModel extends Instance<typeof BillingInfoModel> {}
export interface IPartsBillingModel extends Instance<typeof PartsBillingModel> {}
export interface IBillingModel extends Instance<typeof BillingModel> {}
export interface IBillingModelSnapShot extends SnapshotIn<typeof BillingModel> {}

export const BillingListModel = types
  .model("BillingListModel", {
    activeCategory: types.optional(types.string, BillingListCategories[0].id),
    activeCategoryValue: types.optional(types.maybeNull(types.string), BillingListCategories[0].value),
    activeCategoryState: types.optional(types.maybeNull(types.string), BillingListCategories[0].state),
    activeCategoryHasDealer: types.optional(types.maybeNull(types.boolean), BillingListCategories[0].hasDealer),
    activeCategoryBillingType: types.optional(types.number, BillingListCategories[0].billingType),
    activeCategoryIncludeLinked: types.optional(types.boolean, BillingListCategories[0].includeLinked),
    activeCategoryHasMainClinic: types.optional(types.maybeNull(types.boolean), BillingListCategories[0].hasMainClinic),
    page: 0,
    total: 0,
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, BillingSort.accountname),
    sortDirection: types.optional(types.number, SortDirection.ASC),
    isLoading: types.optional(types.boolean, true),
    clinicsCategoryTotal: types.optional(types.number, 0),
    distributorsCategoryTotal: types.optional(types.number, 0),
    idtfCategoryTotal: types.optional(types.number, 0),
    dealerClinicsCategoryTotal: types.optional(types.number, 0),
    accessoriesCategoryTotal: types.optional(types.number, 0),
    splitbillCategoryTotal: types.optional(types.number, 0),
    startDate: types.optional(types.Date, new Date(dayjs().subtract(1, "month")
      .startOf("month").format("MM/DD/YYYY"))),
    endDate: types.optional(types.Date, new Date(dayjs().subtract(1, "month").endOf("month").format("MM/DD/YYYY"))),
    items: types.optional(types.array(BillingModel), []),
    salesReps: types.optional(types.array(types.model({
      value: types.string,
      label: types.string
    })), []),
    dealers: types.optional(types.array(types.model({
      value: types.string,
      label: types.string
    })), []),
    dropDownFilterSalesRep: types.optional(types.string, ""),
    searchSalesRepValue: types.optional(types.string, ""),
    dropDownFilterSalesModel: types.optional(types.string, ""),
    searchSalesModelValue: types.optional(types.string, "")
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setList(dto: IBillingModelSnapShot[]) {
      self.items = dto as IObservableArray;
    }
  }))
  .actions(self => {
    const setActiveCategory = (id: string,
      value: string,
      hasDealer: boolean | null,
      count: number,
      billingType: number,
      includeLinked: boolean,
      hasMainClinic: boolean | null,
      state: string
    ) => {
      self.activeCategory = id;
      self.activeCategoryValue = value;
      self.activeCategoryState = state;
      self.activeCategoryHasDealer = hasDealer;
      self.activeCategoryBillingType = billingType;
      self.activeCategoryIncludeLinked = includeLinked;
      self.activeCategoryHasMainClinic = hasMainClinic;
      self.page = 0;
      self.total = count;
      getList();
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const setDefaultParams = () => {
      self.activeCategory = BillingListCategories[0].id;
      self.activeCategoryValue = BillingListCategories[0].value;
      self.activeCategoryState = BillingListCategories[0].state;
      self.activeCategoryHasDealer = BillingListCategories[0].hasDealer;
      self.activeCategoryBillingType = BillingListCategories[0].billingType;
      self.activeCategoryIncludeLinked = BillingListCategories[0].includeLinked;
      self.activeCategoryHasMainClinic = BillingListCategories[0].hasMainClinic;
      self.page = 0;
      self.searchValue = "";
      self.sortBy = BillingSort.accountname;
      self.sortDirection = SortDirection.ASC;
      self.startDate = new Date(dayjs().subtract(1, "month").startOf("month").format("MM/DD/YYYY"));
      self.endDate = new Date(dayjs().subtract(1, "month").endOf("month").format("MM/DD/YYYY"));
      self.dropDownFilterSalesRep = "";
      self.searchSalesRepValue = "";
      self.dropDownFilterSalesModel = "";
      self.searchSalesModelValue = "";
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const fetchSalesReps = flow(function* fetchSalesReps() {
      const { data }: ApiResponseDto<TPaginated<TShortAccount>> = yield userAPI.getSalesReps(0, 9999);
      const { data: dataDealers }: ApiResponseDto<TPaginated<TShortAccount>>
        = yield userAPI.getSalesReps(0, 9999, "Dealer");

      self.salesReps = cast(data.entries
        .filter((item: TShortAccount) => {
          return !dataDealers.entries.find((el: TShortAccount) => el.id === item.id);
        })
        .map((item: TShortAccount) => {
          return { value: item.id.toString(), label: item.name };
        }));
      self.dealers = cast(dataDealers.entries
        .map((item: TShortAccount) => {
          return { value: item.id.toString(), label: item.name };
        }));
    });

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new BaseFilterBilling();
        filter.skip = 0;
        filter.take = 1;
        filter.search = self.searchValue || "";
        filter.startAt = DateUtils.toServerFormat(self.startDate);
        filter.endAt = DateUtils.toServerFormat(self.endDate);

        if (self.dropDownFilterSalesRep) {
          if (self.activeCategoryHasDealer) {
            filter.dealerId = self.dropDownFilterSalesRep;
          } else {
            filter.salesRepId = self.dropDownFilterSalesRep;
          }
        }

        if (self.dropDownFilterSalesModel) {
          filter.salesModel = self.dropDownFilterSalesModel;
        }

        const result: ApiResponseDto<BillingTotalApiDto> = yield billingsApi.getBillingsCounter(filter);
        if (result.isOk) {
          self.clinicsCategoryTotal = result.data.clinics;
          self.distributorsCategoryTotal = result.data.distributors;
          self.idtfCategoryTotal = result.data.monitoringCenters;
          self.dealerClinicsCategoryTotal = result.data.dealerClinics;
          self.accessoriesCategoryTotal = result.data.accessories;
          self.splitbillCategoryTotal = result.data.splitBillClinics;

          switch (self.activeCategory) {
            case BillingListCategories[0].id :
              self.total = self.clinicsCategoryTotal;
              break;
            case BillingListCategories[1].id :
              self.total = self.splitbillCategoryTotal;
              break;
            case BillingListCategories[2].id :
              self.total = self.distributorsCategoryTotal;
              break;
            case BillingListCategories[3].id :
              self.total = self.idtfCategoryTotal;
              break;
            case BillingListCategories[4].id :
              self.total = self.dealerClinicsCategoryTotal;
              break;
            case BillingListCategories[5].id :
              self.total = self.accessoriesCategoryTotal;
              break;
            default:
              break;
          }
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new BaseFilterBilling();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;
        filter.startAt = DateUtils.toServerFormat(self.startDate);
        filter.endAt = DateUtils.toServerFormat(self.endDate);

        if (self.dropDownFilterSalesRep) {
          if (self.activeCategoryHasDealer) {
            filter.dealerId = self.dropDownFilterSalesRep;
          } else {
            filter.salesRepId = self.dropDownFilterSalesRep;
          }
        }

        if (self.activeCategoryValue !== null) {
          filter.type = self.activeCategoryValue;
        }

        if (self.activeCategoryState === BillingState.SPLITBILL) {
          filter.salesModel = AccountModels.SplitBill;
        } else if (self.dropDownFilterSalesModel) {
          filter.salesModel = self.dropDownFilterSalesModel;
        }

        if (self.activeCategoryHasDealer !== null) {
          filter.hasDealer = self.activeCategoryHasDealer;
        }

        filter.billingType = self.activeCategoryBillingType;
        filter.includeLinked = self.activeCategoryIncludeLinked;

        if (self.activeCategoryHasMainClinic !== null) {
          filter.hasMainClinic = self.activeCategoryHasMainClinic;
        }

        const result: ApiResponseType<IBillingModelSnapShot[]>
          = yield billingsApi.getBillings(filter);
        if (result.isOk) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const downloadExport = flow(function* downloadExport() {
      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new BaseFilterBilling();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;
        filter.startAt = DateUtils.toServerFormat(self.startDate);
        filter.endAt = DateUtils.toServerFormat(self.endDate);

        if (self.dropDownFilterSalesRep) {
          if (self.activeCategoryHasDealer) {
            filter.dealerId = self.dropDownFilterSalesRep;
          } else {
            filter.salesRepId = self.dropDownFilterSalesRep;
          }
        }

        if (self.activeCategoryValue !== null) {
          filter.type = self.activeCategoryValue;
        }
        
        if (self.activeCategoryState === BillingState.SPLITBILL) {
          filter.salesModel = AccountModels.SplitBill;
        } else if (self.dropDownFilterSalesModel) {
          filter.salesModel = self.dropDownFilterSalesModel;
        }

        if (self.activeCategoryHasDealer !== null) {
          filter.hasDealer = self.activeCategoryHasDealer;
        }

        filter.billingType = self.activeCategoryBillingType;
        filter.includeLinked = self.activeCategoryIncludeLinked;

        if (self.activeCategoryHasMainClinic !== null) {
          filter.hasMainClinic = self.activeCategoryHasMainClinic;
        }

        const exportResult = yield billingsApi.getExport(filter);
        if (!exportResult.isOk) {
          return;
        }

        const startDateF = dayjs(self.startDate).format("YYYY-MM-DD") || "";
        const endDateF = dayjs(self.endDate).format("YYYY-MM-DD") || "";

        ExportUtils.downloadCSV(exportResult.data.data,
          `billing_page_${BillingListCategories
            .find(el => el.id === self.activeCategory)?.state}_${self.page}_${startDateF}_${endDateF}.csv`);
      } catch (error) {
        console.error(error);
      }
    });

    const setSearchSalesRepValue = (search: string) => {
      self.searchSalesRepValue = search;
    };

    const setDropDownFilterSalesRep = (value: string | number) => {
      self.dropDownFilterSalesRep = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const setSearchSalesModelValue = (search: string) => {
      self.searchSalesModelValue = search;
    };

    const setDropDownFilterSalesModel = (value: string | number) => {
      self.dropDownFilterSalesModel = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const setDate = (startDate: Date, endDate: Date): void => {
      self.startDate = startDate;
      self.endDate = endDate;
      self.page = 0;
      getTotalCount();
    };

    const saveAllBilling = flow(function* saveAllBilling() {
      try {
        const result = yield billingsApi
          .saveAllBilling(DateUtils.toServerFormat(self.startDate), DateUtils.toServerFormat(self.endDate));
        return result.isOk;
      } catch {
        return false;
      }
    });

    return {
      nextPage,
      previousPage,
      getList,
      getTotalCount,
      downloadExport,
      setSearch,
      setSortBy,
      setFirstPage,
      setActiveCategory,
      fetchSalesReps,
      setSearchSalesRepValue,
      setDropDownFilterSalesRep,
      setSearchSalesModelValue,
      setDropDownFilterSalesModel,
      setDate,
      saveAllBilling,
      setDefaultParams
    };
  });

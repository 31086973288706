import { States } from "../boot/constants";
import { ICreateOrderModelSnapShot } from "../stores/create-order.store";

export const CreateOrderInitialState: ICreateOrderModelSnapShot = {
  id: 0,
  account: {
    name: "Choose an existing account",
    value: "",
    type: "select",
    fieldId: "accounts",
    options: []
  },
  salesRepId: {
    name: "Sales Rep",
    value: "",
    type: "select",
    fieldId: "salesRepId",
    options: []
  },
  salesManagerId: {
    name: "Sales Manager",
    value: "",
    type: "select",
    fieldId: "salesManagerId",
    options: []
  },

  ticketId: {
    name: "Zendesk/ClaritySoft ticket number",
    value: "",
    type: "input",
    fieldId: "ticketId",
    isOptional: true
  },
  startDate: {
    name: "Device Start Date",
    value: "",
    type: "dateTime",
    fieldId: "startDate",
    isOptional: true
  },
  representative: {
    name: "Clinic Representative",
    value: "",
    type: "input",
    fieldId: "representative"
  },
  syncWithShipStation: {
    name: "Create Ship label",
    value: "true",
    type: "checkbox",
    isChecked: true,
    fieldId: "syncWithShipStation"
  },

  firstName: {
    name: "First Name",
    value: "",
    type: "input",
    fieldId: "firstName"
  },
  lastName: {
    name: "Last Name",
    value: "",
    type: "input",
    fieldId: "lastName"
  },
  email: {
    name: "Billing Email",
    value: "",
    type: "email",
    fieldId: "email"
  },
  phone: {
    name: "Billing Phone Number",
    value: "",
    type: "phone",
    fieldId: "phone"
  },

  streetShipping: {
    name: "Street Address",
    value: "",
    type: "input",
    fieldId: "streetShipping"
  },
  cityShipping: {
    name: "City",
    value: "",
    type: "input",
    fieldId: "cityShipping"
  },
  stateShipping: {
    name: "State/Territory",
    value: States[0],
    type: "select",
    options: States.map(el => ({ value: el, label: el })),
    defaultValue: { value: States[0], label: States[0] },
    fieldId: "stateShipping"
  },
  zipCodeShipping: {
    name: "Zip/Postal Code",
    value: "",
    type: "input",
    fieldId: "zipCodeShipping"
  }
};

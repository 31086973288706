import * as React from "react";
import { FC, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  ForgotPasswordPage,
  InvitationPage,
  LoginPage,
  ProfilePage,
  RecoveryPasswordPage
} from "../pages";
import { useRootStore } from "../stores/storeContext";
import { Permissions } from "../services";
import { VQSpinner } from "vivaquant-components-library";
import { SlLayout } from "../components/sl-layout";
import { SlDashboardLayout } from "../components/sl-dashboard-layout";

const AccountPage = React.lazy(() => import("../pages/Dashboard/Account")
  .then(r => ({ default: r.AccountPage })));

const CreateAccountPage = React.lazy(() => import("../pages/CreateAccount")
  .then(r => ({ default: r.CreateAccountPage })));

const OrdersPage = React.lazy(() => import("../pages/Dashboard/Orders/Orders.page")
  .then(r => ({ default: r.OrdersPage })));

const CreateOrderPage = React.lazy(() => import("../pages/CreateOrder")
  .then(r => ({ default: r.CreateOrderPage })));

const EditOrderPage = React.lazy(() => import("../pages/EditOrder")
  .then(r => ({ default: r.EditOrderPage })));

const BillingPage = React.lazy(() => import("../pages/Dashboard/Billing/Billing.page")
  .then(r => ({ default: r.BillingPage })));

const RmaReturnPage = React.lazy(() => import("../pages/Dashboard/RmaReturn/RmaReturn.page")
  .then(r => ({ default: r.RmaReturnPage })));

const CreateRmaReturnPage = React.lazy(() => import("../pages/CreateRmaReturn")
  .then(r => ({ default: r.CreateRmaReturnPage })));

const DevicesPage = React.lazy(() => import("../pages/Dashboard/Devices")
  .then(r => ({ default: r.DevicesPage })));

const AdminPage = React.lazy(() => import("../pages/Dashboard/Admin")
  .then(r => ({ default: r.AdminPage })));

export const GlobalRoutes: FC = observer(() => {
  const { isAuth, profile, hasPermission } = useRootStore().userStore;
  const { showGlobalSpiner } = useRootStore().globalStore;
  useRootStore().userStore.checkAuthorized();

  if (typeof useRootStore().userStore.isAuth === "undefined") {
    return (<></>);
  } else {
    if (isAuth && profile?.permissions) {
      const isShowAdminPage = hasPermission(Permissions.InviteUser);
      return (
        <>
          {showGlobalSpiner && <div className="global-spiner">
            <VQSpinner></VQSpinner>
          </div>}
          <Routes>
            <Route path="/" element={<SlLayout/>}>
              <Route path="profile" element={<ProfilePage/>}/>
              <Route path="create-order" element={
                <Suspense fallback={<div>loading</div>}>
                  <CreateOrderPage/>
                </Suspense>
              }/>
              <Route path="edit-order/:id" element={
                <Suspense fallback={<div>loading</div>}>
                  <EditOrderPage/>
                </Suspense>
              }/>
              <Route path="create-rma" element={
                <Suspense fallback={<div>loading</div>}>
                  <CreateRmaReturnPage/>
                </Suspense>
              }/>
              <Route path="edit-rma/:rmaId" element={
                <Suspense fallback={<div>loading</div>}>
                  <CreateRmaReturnPage/>
                </Suspense>
              }/>
              <Route path="create-return" element={
                <Suspense fallback={<div>loading</div>}>
                  <CreateRmaReturnPage/>
                </Suspense>
              }/>
              <Route path="edit-return/:returnId" element={
                <Suspense fallback={<div>loading</div>}>
                  <CreateRmaReturnPage/>
                </Suspense>
              }/>
              <Route path="create-account" element={
                <Suspense fallback={<div>loading</div>}>
                  <CreateAccountPage/>
                </Suspense>
              }/>
              <Route path="edit-account/:id" element={
                <Suspense fallback={<div>loading</div>}>
                  <CreateAccountPage/>
                </Suspense>
              }/>
              <Route path="dashboard" element={
                <SlDashboardLayout/>
              }>
                <Route path="accounts" element={
                  <Suspense fallback={<div>loading</div>}>
                    <AccountPage/>
                  </Suspense>
                }/>
                <Route path="devices" element={
                  <Suspense fallback={<div>loading</div>}>
                    <DevicesPage/>
                  </Suspense>
                }/>
                <Route path="orders" element={
                  <Suspense fallback={<div>loading</div>}>
                    <OrdersPage/>
                  </Suspense>
                }/>
                <Route path="rma" element={
                  <Suspense fallback={<div>loading</div>}>
                    <RmaReturnPage/>
                  </Suspense>
                }/>
                <Route path="billing" element={
                  <Suspense fallback={<div>loading</div>}>
                    <BillingPage/>
                  </Suspense>
                }/>
                {isShowAdminPage && <Route path="admin" element={
                  <Suspense fallback={<div>loading</div>}>
                    <AdminPage/>
                  </Suspense>
                }/>}
                <Route index element={<Navigate to="orders" replace={true}/>}/>
              </Route>
              <Route path="*" element={<Navigate to={"dashboard"} replace={true}/>} />
              <Route path="/" element={<Navigate to={"dashboard"} replace={true}/>} />
            </Route>
          </Routes>
        </>
      );
    }

    if (!isAuth) {
      return (
        <Routes>
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
          <Route path={"/recovery-password/:token"} element={<RecoveryPasswordPage/>} />
          <Route path={"/invitation/:token"} element={<InvitationPage/>}/>
          <Route path="*" element={<Navigate to={"/login"}/>} />
        </Routes>
      );
    }
  }
});

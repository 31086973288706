export abstract class ValidationUtils {
  // eslint-disable-next-line max-len
  private static EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  private static PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  private static PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  private static SERIAL_NUM = /^(RX)\d{6}$/;

  private static USERNAME_REGEX = /^[a-zA-Z0-9_]{6,}$/;

  private static ZIP_CODE_REGEX = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;

  public static isEmailValid(email: string): boolean {
    return ValidationUtils.EMAIL_REGEX.test(email.toLowerCase());
  }

  public static isOptionalEmailValid(email: string): boolean {
    return email.length === 0 ? true : ValidationUtils.EMAIL_REGEX.test(email.toLowerCase());
  }

  public static isZipCodeValid(zip: string): boolean {
    return ValidationUtils.ZIP_CODE_REGEX.test(zip);
  }

  public static isLoginValueValid(value: string): boolean {
    if (value.includes("@")) {
      return ValidationUtils.isEmailValid(value);
    }
    return ValidationUtils.USERNAME_REGEX.test(value);
  }

  public static isUsernameValid(username: string): boolean {
    return ValidationUtils.USERNAME_REGEX.test(username);
  }

  public static isPasswordValid(password: string): boolean {
    return ValidationUtils.PASSWORD_REGEX.test(password);
  }

  public static isPhonedValid(phone: string): boolean {
    return ValidationUtils.PHONE_REGEX.test(ValidationUtils.setPhoneFormat(phone));
  }

  public static isOptionalPhonedValid(phone: string): boolean {
    return phone.length === 0 ? true : ValidationUtils.PHONE_REGEX.test(ValidationUtils.setPhoneFormat(phone));
  }

  public static setPhoneFormat(phone: string): string {
    if (!phone) {
      return "";
    }
    return phone.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
  }

  public static isFieldValid(field: string): boolean {
    return field ? field.length > 0 : false;
  }

  public static isSerialNum(sn: string): boolean {
    return ValidationUtils.SERIAL_NUM.test(sn.trim());
  }
}

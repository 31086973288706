import { types, flow, Instance, SnapshotIn, cast } from "mobx-state-tree";
import { SortDirection, AccountsSort, PAGINATION } from "../boot/constants";
import { BaseFilterAccount } from "../core";
import { ApiResponseType, accountsAPI, ExportUtils, TPaginated, userAPI } from "../services";
import { IObservableArray } from "mobx";
import { ApiResponseDto } from "../models";
import { TBriefAccount, TShortAccount } from "../models/account";
import { AdminModelShort } from "./admin-list.store";

export const AccountModel = types
  .model("AccountModel", {
    id: types.maybe(types.number),
    accountNumber: types.maybeNull(types.number),
    clinicId: types.maybeNull(types.number),
    accountName: types.maybeNull(types.string),
    cityAndState: types.maybeNull(types.string),
    type: types.maybeNull(types.number),
    typeName: types.maybeNull(types.string),
    model: types.maybeNull(types.number),
    modelName: types.maybeNull(types.string),
    stdPrice: types.maybeNull(types.string),
    onBoardingDate: types.maybeNull(types.string),
    status: types.maybeNull(types.number),
    statusName: types.maybeNull(types.string),
    salesRep: types.maybeNull(AdminModelShort),
    billingContact: types.maybeNull(types.string),
    isMainClinic: types.maybeNull(types.boolean),
    mainClinicName: types.maybeNull(types.string),
    tiers: types.maybeNull(types.array(AdminModelShort))
  });

export interface IAccountModel extends Instance<typeof AccountModel> {}
export interface IAccountModelSnapShot extends SnapshotIn<typeof AccountModel> {}

export const AccountsListModel = types
  .model("AccountsListModel", {
    page: 0,
    total: types.optional(types.number, 0),
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, AccountsSort.accountname),
    sortDirection: types.optional(types.number, SortDirection.ASC),
    dropDownFilterStatus: types.optional(types.string, ""),
    dropDownFilterLinked: types.optional(types.string, ""),
    dropDownFilterSalesRep: types.optional(types.string, ""),
    searchStatusValue: types.optional(types.string, ""),
    searchLinkedValue: types.optional(types.string, ""),
    searchSalesRepValue: types.optional(types.string, ""),
    isLoading: types.optional(types.boolean, true),
    items: types.optional(types.array(AccountModel), []),
    mainClinics: types.optional(types.array(types.model({ value: types.string, label: types.string })), []),
    salesReps: types.optional(types.array(types.model({ value: types.string, label: types.string })), [])
  })
  .actions(self => ({
    setList(dto: IAccountModelSnapShot[]) {
      self.items = dto as IObservableArray;
    },
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {
    const setFirstPage = () => {
      self.page = 0;
    };

    const setDefaultParams = () => {
      self.page = 0;
      self.searchValue = "";
      self.sortBy = AccountsSort.accountname;
      self.sortDirection = SortDirection.ASC;
      self.dropDownFilterStatus = "";
      self.dropDownFilterLinked = "";
      self.dropDownFilterSalesRep = "";
      self.searchStatusValue = "";
      self.searchLinkedValue = "";
      self.searchSalesRepValue = "";
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const setSearchStatusValue = (search: string) => {
      self.searchStatusValue = search;
    };

    const setSearchLinkedValue = (search: string) => {
      self.searchLinkedValue = search;
    };

    const setSearchSalesRepValue = (search: string) => {
      self.searchSalesRepValue = search;
    };

    const setDropDownFilterSalesRep = (value: string | number) => {
      self.dropDownFilterSalesRep = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const setDropDownFilterStatus = (value: string | number) => {
      self.dropDownFilterStatus = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const setDropDownFilterLinked = (value: string | number) => {
      self.dropDownFilterLinked = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const fetchMainClinics = flow(function* fetchMainClinics() {
      const { data }: ApiResponseDto<TPaginated<TBriefAccount>>
        = yield accountsAPI.getAccountsMainClinic();
      if (!data) return;
      self.mainClinics = cast(data.entries.map((item: TBriefAccount) => {
        return { value: item.id.toString(), label: item.name };
      }));
    });

    const fetchSalesReps = flow(function* fetchSalesReps() {
      const salesRepData: ApiResponseType<TPaginated<TShortAccount>>
        = yield userAPI.getSalesReps(0, 9999, "Sales Rep", "");
      if (!salesRepData.isOk) {
        self.salesReps = cast([]);
        return;
      }
      self.salesReps = cast(salesRepData.data.entries.map((item: TShortAccount) => {
        return { value: item.id.toString(), label: item.name };
      }));
    });

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new BaseFilterAccount();
        filter.search = self.searchValue || "";

        if (self.dropDownFilterStatus) {
          filter.status = self.dropDownFilterStatus;
        }

        if (self.dropDownFilterLinked) {
          if (+self.dropDownFilterLinked === -1) {
            filter.isLinked = true;
          } else {
            filter.mainClinicId = +self.dropDownFilterLinked;
          }
        }

        if (self.dropDownFilterSalesRep) {
          filter.salesRepId = self.dropDownFilterSalesRep;
        }

        const result: ApiResponseType<{value: number}> = yield accountsAPI.getCounters(filter);
        if (result.isOk) {
          self.total = result.data.value || 0;
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new BaseFilterAccount();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        if (self.dropDownFilterStatus) {
          filter.status = self.dropDownFilterStatus;
        }

        if (self.dropDownFilterLinked) {
          if (+self.dropDownFilterLinked === -1) {
            filter.isLinked = true;
          } else {
            filter.mainClinicId = +self.dropDownFilterLinked;
          }
        }

        if (self.dropDownFilterSalesRep) {
          filter.salesRepId = self.dropDownFilterSalesRep;
        }

        const result: ApiResponseType<IAccountModelSnapShot[]> = yield accountsAPI.getAccountsList(filter);
        if (result.isOk) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const downloadExport = flow(function* downloadExport() {
      const filter = new BaseFilterAccount();
      filter.search = self.searchValue || "";
      filter.sortBy = self.sortBy;
      filter.sortDirection = self.sortDirection;

      if (self.dropDownFilterStatus) {
        filter.status = self.dropDownFilterStatus;
      }

      if (self.dropDownFilterLinked) {
        if (+self.dropDownFilterLinked === -1) {
          filter.isLinked = true;
        } else {
          filter.mainClinicId = +self.dropDownFilterLinked;
        }
      }

      if (self.dropDownFilterSalesRep) {
        filter.salesRepId = self.dropDownFilterSalesRep;
      }

      try {
        const exportResult = yield accountsAPI.getExport(filter);
        if (!exportResult.isOk) {
          return;
        }

        ExportUtils.downloadCSV(exportResult.data.data, "Accounts.csv");
      } catch (error) {
        console.error(error);
      }
    });

    return {
      nextPage,
      previousPage,
      fetchMainClinics,
      getList,
      getTotalCount,
      setSearch,
      setSortBy,
      setFirstPage,
      downloadExport,
      setSearchStatusValue,
      setSearchLinkedValue,
      setDropDownFilterStatus,
      setDropDownFilterLinked,
      setDefaultParams,
      setSearchSalesRepValue,
      setDropDownFilterSalesRep,
      fetchSalesReps
    };
  });

import { ProcessAPIError } from "../decorators";
import { ApiResponseDto } from "../../models";
import { ApiResponseType, TPaginated } from "../types";
import { API } from "../../boot";
import { TAccountData, TAccountForRma, TBriefAccount, TShortAccount } from "../../models/account";
import { AccountTypes, SortDirection } from "../../boot/constants";
import axios, { CancelTokenSource } from "axios";
import { BaseFilterAccount } from "../../core";
import { IAccountModelSnapShot } from "../../stores/accounts-list.store";

class AccountsApi {
  private getOrdersCancelToken: CancelTokenSource;
  private accountsToken: CancelTokenSource;

  @ProcessAPIError()
  public async getAccountsForRma()
      : Promise<ApiResponseDto<TAccountForRma[]>> {
    if (this.getOrdersCancelToken) {
      this.getOrdersCancelToken.cancel();
    }
    this.getOrdersCancelToken = axios.CancelToken.source();
    const { data } = await API.get<TAccountForRma[]>("/customers/for-rma", {
      cancelToken: this.getOrdersCancelToken.token
    });
    delete this.getOrdersCancelToken;
    return new ApiResponseDto<TAccountForRma[]>(data);
  }

  @ProcessAPIError()
  public async getAccountsByType(
    type: AccountTypes | number,
    search?: string,
    take?: number,
    availableForType?:  AccountTypes 
  ): Promise<ApiResponseType<TBriefAccount[]>> {
    this.accountsToken?.cancel();
    this.accountsToken = axios.CancelToken.source();
    const params: any = {
      type,
      take: take || 9999,
      search: search || ""
    };

    if (typeof availableForType !== "undefined") {
      params.availableForType = availableForType;
    }
    const { data } = await API
      .get<TBriefAccount[]>("/customers/brief", {
        cancelToken: this.accountsToken.token,
        params: params
      });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getAccountsMainClinic(): Promise<ApiResponseType<TBriefAccount[]>> {
    this.accountsToken?.cancel();
    this.accountsToken = axios.CancelToken.source();
    const { data } = await API
      .get<TBriefAccount[]>("/customers/brief", {
        cancelToken: this.accountsToken.token,
        params: {
          take: 9999,
          isMainClinic: true,
          sortBy: "accountname",
          sortDirection: SortDirection.ASC
        }
      });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getAccountOrderCreationData(accId: number): Promise<ApiResponseType<TAccountData>> {
    const { data } = await API.get(`customers/${accId}/order-creation/details`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getCounters(filter: BaseFilterAccount) : Promise<ApiResponseType<{total: number}>> {
    const result = await API.get("/customers/counter", {
      params: filter
    }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getAccountsList(filter: BaseFilterAccount): Promise<ApiResponseType<IAccountModelSnapShot[]>> {
    const result = await API.get("/customers/detailed", {
      params: filter
    }).then(r => r.data);
    return new ApiResponseDto(result.entries);
  }

  @ProcessAPIError()
  public async getExport(filter: BaseFilterAccount): Promise<ApiResponseType<any>> {
    const result = await API.get("/customers/export", { params: filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async addAccount(data: {
    type: number,
    name: string,
    city: string,
    state: string,
    salesRepId: number,
    salesManagerId: number,
    status: number,
    model: number,
    standardPrice: number,
    mctRecordingPrice: number,
    eventRecordingPrice: number,
    extendedHolterPrice: number,
    shortTermHolterPrice: number,
    homeHookupPrice: number,
    isHomeHookupEnabled: boolean,
    onBoardingDate: string,
    effectiveDate: string }
  ) {
    const result = await API.post("/customers/detailed", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async updateAccount(data: {
    id: number,
    type: number,
    name: string,
    city: string,
    state: string,
    salesRepId: number,
    salesManagerId: number,
    status: number,
    model: number,
    standardPrice: number,
    mctRecordingPrice: number,
    eventRecordingPrice: number,
    extendedHolterPrice: number,
    shortTermHolterPrice: number,
    homeHookupPrice: number,
    isHomeHookupEnabled: boolean,
    onBoardingDate: string,
    effectiveDate: string }
  ) {
    const result = await API.put("/customers/detailed", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async updateContact(data: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    id: number }
  ) {
    const result = await API.put("/customers/contact/billing", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async updateShipping(data: {
    street: string,
    city: string,
    state: string,
    zipCode: string,
    id: number }
  ) {
    const result = await API.put("/customers/shipping-address", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getAccountInfo(id: number) {
    const result = await API.get(`/customers/${id}/details`);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getConnectionNotAssigned(): Promise<ApiResponseType<TPaginated<TShortAccount>>> {
    const result = await API.get("customers/not-assigned", {
      params: { type: AccountTypes.Clinic, take: 99999 }
    });
    return new ApiResponseDto(result.data);
  }
}

export const accountsAPI = new AccountsApi();

import { applySnapshot, flow, Instance, SnapshotIn, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { AccountTypes } from "../boot/constants";
import { TAccountContactData, TAccountData, TBriefAccount, TShortAccount } from "../models/account";
import {
  accountsAPI,
  ApiResponseType,
  DateUtils,
  orderAPI,
  salesItemAPI,
  TPaginated,
  userAPI,
  ValidationUtils
} from "../services";
import { CreateOrderInitialState } from "../states/create-order.state";
import { ApiResponseDto } from "../models";
import { TOrder } from "../models/order";

export const AccessoriesSalesItemModel = types
  .model("AccessoriesSalesItemModel", {
    id: types.number,
    isSerialized: types.boolean,
    name: types.string,
    price: types.number,
    sku: types.string,
    quantityPerOneItem: types.number
  });

export const SalesItemModel = types
  .model("SalesItemModel", {
    id: types.maybeNull(types.number),
    name: types.string,
    price: types.number,
    amount: types.number,
    type: types.number,
    isSerialized: types.boolean,
    isSubscription: types.boolean,
    sku: types.maybeNull(types.optional(types.string, "")),
    items: types.maybeNull(types.optional(types.array(AccessoriesSalesItemModel), []))
  });

export const ItemsModel = types
  .model("ItemsModel", {
    handling: types.maybeNull(SalesItemModel),
    shipping: types.maybeNull(SalesItemModel),
    salesItems: types.maybeNull(types.optional(types.array(SalesItemModel), []))
  });

export interface IAccessoriesSalesItemModel extends Instance<typeof AccessoriesSalesItemModel> {}
export interface IAccessoriesSalesItemModelSnapShot extends SnapshotIn<typeof AccessoriesSalesItemModel> {}
export interface ISalesItemModel extends Instance<typeof SalesItemModel> {}
export interface ISalesItemModelSnapShot extends SnapshotIn<typeof SalesItemModel> {}
export interface IItemsModel extends Instance<typeof ItemsModel> {}
export interface IItemsModelSnapShot extends SnapshotIn<typeof ItemsModel> {}

export const CreateOrderModel = types
  .model("CreateOrderModel", {
    openedStep: types.optional(types.number, 1),
    isLoading: types.optional(types.boolean, false),
    id: types.maybeNull(types.number),
    availableAccounts: types.frozen(),
    openedContact: types.optional(types.boolean, false),
    openedShipping: types.optional(types.boolean, false),
    accountName: types.optional(types.string, ""),
    accountSalesModel: types.maybeNull(types.number),

    type: types.optional(types.number, AccountTypes.Clinic),
    account: types.maybe(InputFormModel),
    salesRepId: types.maybe(InputFormModel),
    salesManagerId: types.maybe(InputFormModel),

    items: types.maybe(ItemsModel),
    ticketId: types.maybe(InputFormModel),
    startDate: types.maybe(InputFormModel),
    representative: types.maybe(InputFormModel),
    syncWithShipStation: types.maybe(InputFormModel),

    firstName: types.maybe(InputFormModel),
    lastName: types.maybe(InputFormModel),
    email: types.maybe(InputFormModel),
    phone: types.maybe(InputFormModel),

    streetShipping: types.maybe(InputFormModel),
    cityShipping: types.maybe(InputFormModel),
    stateShipping: types.maybe(InputFormModel),
    zipCodeShipping: types.maybe(InputFormModel)
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {
    const setStep = (step: number) => {
      self.openedStep = step;
    };

    const setOpenedContact = (opened: boolean) => {
      self.openedContact = opened;
    };

    const setOpenedShipping = (opened: boolean) => {
      self.openedShipping = opened;
    };

    const resetForm = () => {
      self.openedStep = 0;
      self.type = AccountTypes.Clinic;
      self.id = null;
      self.accountName = "";
      self.accountSalesModel = null;
      if (self.items) {
        self.items.handling = null;
        self.items.shipping = null;
        self.items.salesItems = null;
      }
      applySnapshot(self, CreateOrderInitialState);
    };

    const setAccountType = flow(function* setAccountType(type: number) {
      self.setLoading(true);
      self.type = type;
      self.account.setValue("");
      self.account.setDefaultValue({ value: "", label: "" });
      self.salesRepId.setValue("");
      self.salesRepId.setDefaultValue({ value: "", label: "" });
      self.salesManagerId.setValue("");
      self.salesManagerId.setDefaultValue({ value: "", label: "" });
      self.accountSalesModel = null;
      yield fetchAccounts();
      self.setLoading(false);
    });

    const setAccount = () => {
      self.setLoading(true);
      const accountInfo = self.availableAccounts
        .find((item: TBriefAccount) => item.id.toString() === self.account?.value);
      if (accountInfo && accountInfo.salesRepId) {
        self.salesRepId.isLoading = true;
        self.salesRepId.setValue(accountInfo.salesRepId.toString());
        const optionSalesRep = self.salesRepId.options
          .find((item) => item.value === accountInfo.salesRepId.toString());
        if (optionSalesRep) {
          self.salesRepId.setDefaultValue({ value: optionSalesRep.value, label: optionSalesRep.label });
        }
      } else {
        self.salesRepId.setValue("");
        self.salesRepId.setDefaultValue({ value: "", label: "" });
      }

      if (accountInfo && accountInfo.salesManagerId) {
        self.salesManagerId.setValue(accountInfo.salesManagerId.toString());
        const optionSalesManager = self.salesManagerId.options
          .find((item) => item.value === accountInfo.salesManagerId.toString());
        if (optionSalesManager) {
          self.salesManagerId.setDefaultValue({ value: optionSalesManager.value, label: optionSalesManager.label });
        }
      } else {
        self.salesManagerId.setValue("");
        self.salesManagerId.setDefaultValue({ value: "", label: "" });
      }

      if (accountInfo && accountInfo.name) {
        self.accountName = accountInfo.name;
        self.accountSalesModel = accountInfo.salesModel;
      } else {
        self.accountName = "";
        self.accountSalesModel = null;
      }
      self.setLoading(false);
    };

    const plusItem = (id: number) => {
      const item = self.items.salesItems?.find(i => i.id === id);
      if (item) {
        item.amount++;
      }
    };

    const minusItem = (id: number) => {
      const item = self.items.salesItems?.find(i => i.id === id);
      if (item) {
        item.amount--;
      }
    };

    const setItemValue = (id: number, value: number) => {
      const item = self.items.salesItems?.find(i => i.id === id);
      if (item) {
        item.amount = value;
      }
    };

    const totalItems = () => {
      return self.items.salesItems.reduce((acc, curr) => acc + curr.amount, 0);
    };

    const shippingAndHandlingPrice = () => {
      return (totalItems() * self.items.shipping.price) + self.items.handling.price;
    };

    const validateFormAccount = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.account?.value) {
        errors.account = "Please fill the field";
        isValid = false;
      }

      return {
        errors,
        isValid
      };
    };

    const validateFormInfo = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.representative?.value) {
        errors.representative = "Please fill the field";
        isValid = false;
      }

      return {
        errors,
        isValid
      };
    };

    const validateFormContact = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.firstName?.value) {
        errors.firstName = "Please fill the field";
        isValid = false;
      }

      if (!self.lastName?.value) {
        errors.lastName = "Please fill the field";
        isValid = false;
      }

      if (!self.email?.value) {
        errors.email = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isEmailValid(self.email?.value)) {
          errors.email = "Wrong email";
          isValid = false;
        }
      }

      if (!self.phone?.value) {
        errors.phone = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isPhonedValid(self.phone?.value)) {
          errors.phone = "Please enter valid phone number";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const validateFormShipping = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.streetShipping?.value) {
        errors.streetShipping = "Please fill the field";
        isValid = false;
      }

      if (!self.cityShipping?.value) {
        errors.cityShipping = "Please fill the field";
        isValid = false;
      }

      if (!self.zipCodeShipping?.value) {
        errors.zipCodeShipping = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isZipCodeValid(self.zipCodeShipping?.value)) {
          errors.zipCodeShipping = "Please enter valid Zip/Postal Code";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const saveOrder = flow(function* saveOrder() {
      try {
        const newItems = self.items.salesItems
          .filter(el => el.amount)
          .map(el => { return { id: el.id, quantity: el.amount, price: el.price }; });
        if (!newItems.length) {
          return { error: { message: "Choose at least one sales item" } };
        }
        const payload = {
          customerId: +self.account.value,
          salesRepId: +self.salesRepId.value,
          salesManagerId: +self.salesManagerId.value,
          items: newItems,
          accountRepName: self.representative.value,
          accountRepSignature: "",
          deviceStartDate: self.startDate.value ? DateUtils.toServerFormat(new Date(self.startDate.value)) : null,
          ticketId: self.ticketId.value || "",
          orderNumber: "",
          trackingNumber: "",
          syncWithShipStation: self.syncWithShipStation?.value === "true"
        };

        const r: ApiResponseType<TOrder> = yield orderAPI.createOrder(payload);
        if (r.isOk) {
          self.id = r.data.id;
        }
        return r;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const saveContact = flow(function* saveContact() {
      try {
        const data = {
          firstName: self.firstName.value,
          lastName: self.lastName.value,
          email: self.email.value,
          phone: "+" + self.phone.value.replace(/[^\w\s]/gi, "").replace(/\s/g, ""),
        };
        const result = yield accountsAPI.updateContact({
          ...data,
          id: +self.account.value
        });
        
        yield orderAPI.updateContactShipStation(+self.id, data);

        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const saveShipping = flow(function* saveContact() {
      try {
        const result = yield orderAPI.updateOrderShipping({
          street: self.streetShipping.value,
          city: self.cityShipping.value,
          state: self.stateShipping.value,
          zipCode: self.zipCodeShipping.value,
          id: +self.id
        });
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const fetchData = flow(function* fetchData() {
      self.setLoading(true);
      yield Promise.all([
        fetchSalesReps(),
        fetchSalesManagers(),
        fetchAccounts()
      ]);
      self.setLoading(false);
    });

    const fetchAccounts = flow(function* fetchAccounts() {
      const response: ApiResponseType<TPaginated<TBriefAccount>>
        = yield accountsAPI.getAccountsByType(self.type);
      if (!response.isOk || !response.data) {
        return;
      }
      self.account.setOptions(response.data.entries.map((item: TBriefAccount) => {
        return { value: item.id.toString(), label: item.name };
      }));
      self.availableAccounts = response.data.entries;
    });

    const fetchSalesReps = flow(function* fetchSalesReps() {
      const { data }: ApiResponseDto<TPaginated<TShortAccount>> = yield userAPI.getSalesReps(0, 9999);
      const { data: dataDealers }: ApiResponseDto<TPaginated<TShortAccount>>
        = yield userAPI.getSalesReps(0, 9999, "Dealer");

      const options = data.entries.map((item: TShortAccount) => {
        return { value: item.id.toString(), label: item.name };
      });

      dataDealers.entries.forEach((item: TShortAccount) => {
        options.push({ value: item.id.toString(), label: item.name });
      });

      self.salesRepId.setOptions(options);
    });

    const fetchSalesManagers = flow(function* fetchSalesManagers() {
      const { data }: ApiResponseDto<TPaginated<TShortAccount>>
        = yield userAPI.getSalesReps(0, 9999, "Sales Manager");
      self.salesManagerId.setOptions(data.entries.map((item: TShortAccount) => {
        return { value: item.id.toString(), label: item.name };
      }));
    });

    const fetchItems = flow(function* fetchItems() {
      const r: ApiResponseType<IItemsModel> = yield salesItemAPI.getItemsByAccountId(+self.account.value);
      if (r.isOk) {
        self.items = r.data;
      } else {
        self.items.handling = null;
        self.items.shipping = null;
        self.items.salesItems = null;
      }
    });

    const fetchCreationData = flow(function* fetchCreationData() {
      const accData: ApiResponseType<TAccountData> = yield accountsAPI.getAccountOrderCreationData(+self.account.value);
      if (accData.isOk) {
        self.representative.setValue(accData.data.representativeName || "");
      } else {
        self.representative.setValue("");
      }
    });

    const fetchContactData = flow(function* fetchContactData() {
      if (!self.id) return;
      const res: ApiResponseType<TAccountContactData> = yield orderAPI.getAccountContactData(self.id);
      if (!res.isOk) {
        return;
      }
      const { billingAddress, shippingAddress } = res.data;
      self.firstName.setValue(billingAddress?.firstname || "");
      self.lastName.setValue(billingAddress?.lastname || "");
      self.email.setValue(billingAddress?.email || "");
      self.phone.setValue(billingAddress?.phoneNumber || "");

      self.streetShipping.setValue(shippingAddress?.street || "");
      self.cityShipping.setValue(shippingAddress?.city || "");
      self.stateShipping.setValue(shippingAddress?.state || "");
      const optionState = self.stateShipping.options
        .find((item) => item.value === shippingAddress?.state);
      if (optionState) {
        self.stateShipping.setDefaultValue({ value: optionState.value, label: optionState.label });
      }
      self.zipCodeShipping.setValue(shippingAddress?.zipCode || "");
    });

    return {
      setStep,
      setOpenedContact,
      setOpenedShipping,
      resetForm,
      setAccountType,
      setAccount,
      fetchData,
      validateFormAccount,
      validateFormInfo,
      validateFormContact,
      validateFormShipping,
      saveOrder,
      saveContact,
      saveShipping,
      fetchContactData,
      fetchItems,
      fetchCreationData,
      plusItem,
      minusItem,
      setItemValue,
      shippingAndHandlingPrice
    };
  });

export interface ICreateOrderModelSnapShot extends SnapshotIn<typeof CreateOrderModel> {}

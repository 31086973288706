import { ProcessAPIError } from "../decorators";
import { ApiResponseDto } from "../../models";
import { API } from "../../boot";
import { ILoginResponse } from "../../models/v2/auth/ILoginResponse";
import { IIsRecoveryLinkValidResponse } from "../../models/v2/auth/IIsRecoveryLinkValidResponse";
import { ApiResponseType } from "../types";
import { IIsInviteTokenValidRequest } from "../../models/v2/auth/IIsInviteTokenValidRequest";

class AuthApi {
  @ProcessAPIError()
  public async login(login: string, password: string): Promise<ApiResponseDto<ILoginResponse>> {
    const { data } = await API.post("auth/login", { login, password });
    return new ApiResponseDto<ILoginResponse>(data);
  }

  @ProcessAPIError()
  public async loginTwoFactor(login: string, code: string): Promise<ApiResponseType<ILoginResponse>> {
    const result = await API.post("auth/login/2fa", { login, code });
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async resendLoginCode(email: string): Promise<ApiResponseType<null>> {
    await API.post("auth/2fa", { email });
    return new ApiResponseDto(null);
  }

  @ProcessAPIError()
  public async resetPassword(email: string) : Promise<ApiResponseDto<{isResetLinkSent: boolean}>> {
    const { data } = await API.post<{isResetLinkSent: boolean}>("auth/reset-password", { email });
    return new ApiResponseDto<{isResetLinkSent: boolean}>(data);
  }

  @ProcessAPIError()
  public async isRecoveryLinkValid(token: string) : Promise<ApiResponseDto<IIsRecoveryLinkValidResponse>> {
    const { data } = await API.get<IIsRecoveryLinkValidResponse>(`auth/reset-password/${token}`);
    return new ApiResponseDto<IIsRecoveryLinkValidResponse>(data);
  }

  @ProcessAPIError()
  public async recoveryPassword(link: string, password: string) : Promise<ApiResponseDto<null>> {
    await API.patch<IIsRecoveryLinkValidResponse>("auth/recover-password", { link, password });
    return new ApiResponseDto<null>(null);
  }

  @ProcessAPIError()
  public async isInviteTokenValid(token: string): Promise<ApiResponseType<IIsInviteTokenValidRequest>> {
    const result = await API.get(`invite/${token}`);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async completeInvite(link: string, username: string, password: string): Promise<ApiResponseType<null>> {
    await API.patch("/invite", { link, username, password });
    return new ApiResponseDto(null);
  }
}

export const authAPI = new AuthApi();

import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { HeaderWrapper } from "./sl-header.presets";
import Logo from "../../assets/img/rhythm-express-by-viva-quant.png";
import { observer } from "mobx-react-lite";
import { DASHBOARD_ROUTES } from "./sl-header-links";
import { useRootStore } from "../../stores/storeContext";
import { SlNavItem } from "../sl-nav-item";
import { SlDashboardDropdown } from "../sl-dashboard-dropdown";

export const SlHeader: FC = observer(() => {
  const { pathname } = useLocation();
  const { hasPermission } = useRootStore().userStore;

  const currentPage = useMemo(() => {
    return pathname.substring(1);
  }, [pathname]);

  return (
    <HeaderWrapper shadow={true} className={"top"}>
      <img className="logo" src={Logo} alt="Rhythm express logo"/>
      {DASHBOARD_ROUTES
        .map(({ link, title, permission }) =>
          !permission || hasPermission(permission)
            ? <SlNavItem
              key={link}
              isActive={link === currentPage}
              link={link}
            >
              {title}
            </SlNavItem>
            : null)
      }
      <SlDashboardDropdown/>
    </HeaderWrapper>
  );
});

import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { message } from "antd";
import { useRootStore } from "../../stores/storeContext";
import { VQProfile } from "vivaquant-components-library";

export const ProfilePage: FC = observer(() => {
  const {
    initProfile,
    firstName,
    lastName,
    email,
    username,
    phoneNumber,
    is2FaEnabled,
    showModal,
    closeModal,
    openModal,
    validateCode,
    validateField,
    saveForm,
    getDataForSave
  } = useRootStore().profileStore;

  const { updateProfileData, profile, resendUpdateCode } = useRootStore().userStore;

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorCode, setErrorCode] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [isResentCode, setIsResentCode] = useState(false);

  useEffect(() => {
    initProfile(profile);
  }, []);

  const validateForm = () => {
    const { errors, isValid } = validateField();
    setErrors(errors);
    return isValid;
  };

  const onSubmit = async(event: any) => {
    event.preventDefault();
    if (!validateForm()) {
      setFormInvalid(true);
      message.error({ content: "Please fill the fields!", duration: 2 });
    } else {
      setFormInvalid(false);
      const key = "updatable";
      const result = await saveForm();

      if (!result) {
        message.error({ content: "Error, Something went wrong!", key, duration: 2 });
      }

      if (result.isOk && !result.data.confirm2Fa) {
        updateProfileData(getDataForSave());
        message.success({ content: "User profile successfully updated!", key, duration: 2 });
      }

      if (result.isOk && result.data.confirm2Fa) {
        return openModal();
      }

      if (!result.isOk) {
        message.error({ content: result.error?.detail, key, duration: 2 });
      }
    }
  };

  const handleOnChangeAuthCode = (res: string) => {
    setAuthCode(res);
    setErrorCode(false);
  };

  const hideModal = () => {
    setAuthCode("");
    setIsResentCode(false);
    closeModal();
  };

  const onValidateCode = async(event: any) => {
    event.preventDefault();
    const key = "updatable";
    const response = await validateCode(authCode);
    if (response) {
      setAuthCode("");
      closeModal();
      message.success({ content: "User profile successfully updated!", key, duration: 2 });
    } else {
      setErrorCode(true);
    }
  };

  const getTwoFactorAuthenticationMessage = () => {
    const replaced = phoneNumber?.value.replace(/.(?=....)/g, "*");
    const sentText = isResentCode ? "resent" : "sent";
    return `A message with a verification code has been ${sentText} to your phone number ${replaced}.
    Enter the code to continue.`;
  };

  const retryCode = () => {
    setIsResentCode(true);
    resendUpdateCode();
  };

  return (
    <VQProfile
      onSubmit={onSubmit}
      hideModal={hideModal}
      handleOnChangeAuthCode={handleOnChangeAuthCode}
      getTwoFactorAuthenticationMessage={getTwoFactorAuthenticationMessage}
      onValidateCode={onValidateCode}
      authCode={authCode}
      errorCode={errorCode}
      errors={errors}
      formInvalid={formInvalid}
      showModal={showModal}
      firstName={firstName}
      lastName={lastName}
      email={email}
      username={username}
      phoneNumber={phoneNumber}
      is2FaEnabled={is2FaEnabled}
      signAs={` ${firstName.value} ${lastName.value}`}
      retryCode={retryCode}
    ></VQProfile>
  );
});

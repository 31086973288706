import { ProcessAPIError } from "../decorators";
import { ApiResponseType } from "../types";
import { ApiResponseDto } from "../../models";
import { IRmaReturnModelSnapShot } from "../../stores/rma-return.store";
import { BaseFilterRma } from "../../core";
import { API } from "../../boot";
import { DoesRmaShipmentExistDto, RmaShipmentExistsDto } from "../../models/rma";
import { InventoryDto, InventorySerialNumberDto } from "../../models/inventory.dto";

class RMAReturnApi {
  @ProcessAPIError()
  public async getCounters(filter: BaseFilterRma): Promise<ApiResponseType<{ returns: number, rmas: number }>> {
    const result = await API.get("/returns/counters", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getRmaList(filter: BaseFilterRma): Promise<ApiResponseType<IRmaReturnModelSnapShot[]>> {
    const result = await API.get("/rma", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getReturnList(filter: BaseFilterRma): Promise<ApiResponseType<IRmaReturnModelSnapShot[]>> {
    const result = await API.get("/returns", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getExportRma(filter: BaseFilterRma): Promise<ApiResponseType<any>> {
    const result = await API.get("/rma/export", { params: filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getExportReturn(filter: BaseFilterRma): Promise<ApiResponseType<any>> {
    const result = await API.get("/returns/export", { params: filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getExportBatchMoveRma(filter: BaseFilterRma): Promise<ApiResponseType<any>> {
    const result = await API.get("/rma/export/batch-move", { params: filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getExportBatchMoveReturn(filter: BaseFilterRma): Promise<ApiResponseType<any>> {
    const result = await API.get("/returns/export/batch-move", { params: filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getDetailsRma(id: number): Promise<ApiResponseType<any>> {
    const result = await API.get(`/rma/detailed/${id}`);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getDetailsRmaShipStation(id: number): Promise<ApiResponseType<any>> {
    const result = await API.get(`/rma/${id}/ship-station`);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getDetailsReturn(id: number): Promise<ApiResponseType<any>> {
    const result = await API.get(`/returns/detailed/${id}`);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async deleteRma(id: number): Promise<ApiResponseType<any>> {
    const result = await API.delete("/rma/" + id);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async deleteReturn(id: number): Promise<ApiResponseType<any>> {
    const result = await API.delete("/returns/" + id);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async createRma(data: any): Promise<ApiResponseType<any>> {
    const result = await API.post("/rma", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async createReturn(data: any): Promise<ApiResponseType<any>> {
    const result = await API.post("/returns", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async editRma(data: any): Promise<ApiResponseType<any>> {
    const result = await API.put("/rma", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async editReturn(data: any): Promise<ApiResponseType<any>> {
    const result = await API.put("/returns", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async doesShipmentExist(dto: DoesRmaShipmentExistDto): Promise<ApiResponseDto<RmaShipmentExistsDto>> {
    const result = await API.get("/rma-return-shipment/shipment-info", { params: dto });
    return new ApiResponseDto<RmaShipmentExistsDto>(result.data);
  }

  @ProcessAPIError()
  public async doesAvailableSerialNumber(
    customerId: number | string,
    salesItemId: number | string,
    serialNumber: string,
    requestDate: string
  ): Promise<ApiResponseDto<RmaShipmentExistsDto>> {
    // eslint-disable-next-line max-len
    const result = await API.get(`/returns/customers/${customerId}/sales-items/${salesItemId}/serial-numbers/${serialNumber}/is-available?requestDate=${requestDate}`);
    return new ApiResponseDto<RmaShipmentExistsDto>(result.data);
  }

  @ProcessAPIError()
  public async getItemsInventory(customerId: number | string, requestDate: string, returnId?: number)
    : Promise<ApiResponseType<InventoryDto[]>> {
    const params : any = { 
      requestDate : requestDate 
    };
    if (returnId) {
      params.returnId = returnId;
    }
    const { data } = await API.get(`returns/customers/${customerId}/sales-items`, {
      params: params
    });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getItemsInventoryNumber(
    customerId: number | string,
    salesItemId: number | string, requestDate: string,
    skip: number,
    take: number,
    search?: string)
    : Promise<ApiResponseType<InventorySerialNumberDto[]>> {
    const { data } = await API.get(`returns/customers/${customerId}/sales-items/${salesItemId}/serial-numbers`, {
      params: { requestDate, skip, take, search }
    });
    return new ApiResponseDto(data);
  }
}

export const rmaReturnApi = new RMAReturnApi();

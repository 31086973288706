import { API } from "../../boot";
import { BaseFilter } from "../../core";
import { ApiResponseDto } from "../../models";
import { IDevicesHistoryModelSnapShot } from "../../stores/devices-history-list.store";
import { ProcessAPIError } from "../decorators";
import { ApiResponseType } from "../types";

class DevicesApi {
  @ProcessAPIError()
  public async getHistoryCounter(filter: BaseFilter): Promise<ApiResponseType<number>> {
    const result = await API.get("/devices-history/counter", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getHistoryList(filter: BaseFilter): Promise<ApiResponseType<IDevicesHistoryModelSnapShot[]>> {
    const result = await API.get("/devices-history", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getHistoryExport(filter: BaseFilter): Promise<ApiResponseType<any>> {
    const result = await API.get("/devices-history/export", { params: filter });
    return new ApiResponseDto(result);
  }
}

export const devicesApi = new DevicesApi();

import { types } from "mobx-state-tree";
import { ValidationUtils } from "../../services";

export const ForgotFormModel = types
  .model("ForgotFormModel", {
    email: types.optional(types.string, ""),
    invalidEmail: types.optional(types.boolean, false),
    isSubmit: types.optional(types.boolean, false),
    isErrorForgot: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false)
  })
  .actions(self => {
    const validateEmail = () => {
      self.invalidEmail = !ValidationUtils.isEmailValid(self.email);
    };

    const setEmail = (value: string) => {
      self.email = value;
      setErrorForgot(false);
      validateEmail();
    };

    const setSubmit = () => {
      self.isSubmit = true;
      validateEmail();
    };

    const setErrorForgot = (value: boolean) => {
      self.isErrorForgot = value;
    };

    const setLoading = (isLoading: boolean) => {
      self.isLoading = isLoading;
    };

    const cleanData = () => {
      self.email = "";
      self.isSubmit = false;
    };

    return { validateEmail, setEmail, setSubmit, setErrorForgot, setLoading, cleanData };
  });

import { SnapshotIn, types, applySnapshot, flow, Instance, cast } from "mobx-state-tree";
import dayjs from "dayjs";
import { InputFormModel } from "vivaquant-components-library";
import {
  ApiResponseType,
  DateUtils,
  orderAPI,
  salesItemAPI,
  ValidationUtils
} from "../services";
import { EditOrderInitialState } from "../states/edit-order.state";
import { OrderStateNum, OrderStateOptions } from "../boot/constants";
import { TAccountContactData } from "../models/account";
import {
  IItemsModel,
  ItemsModel,
  AccessoriesSalesItemModel,
  IAccessoriesSalesItemModel,
  ISalesItemModel
} from "./create-order.store";
import { TEditOrder, TOrder } from "../models/order";

export const SelectedEditItemModel = types
  .model("SelectedEditItemModel", {
    id: types.maybeNull(types.number),
    salesItemId: types.number,
    name: types.string,
    price: types.number,
    quantityOrdered: types.number,
    isSerialized: types.boolean,
    isSubscription: types.boolean,
    serialNumbers: types.maybeNull(types.array(types.string)),
    serialNumbersValueInput: types.maybeNull(types.string),
    errorSerialNumbersCount: types.maybeNull(types.string),
    warningSerialNumbers: types.maybeNull(types.string),
    accessories: types.optional(types.array(AccessoriesSalesItemModel), [])
  });

export interface ISelectedEditItemModel extends Instance<typeof SelectedEditItemModel> {}
export interface ISelectedEditItemModelSnapShot extends SnapshotIn<typeof SelectedEditItemModel> {}

export const EditOrderModel = types
  .model("EditOrderModel", {
    isLoading: types.optional(types.boolean, false),
    isSynchronized: types.optional(types.boolean, true),
    accountName: types.maybe(types.string),
    customerId: types.maybe(types.number),
    isSplited: types.optional(types.boolean, false),
    availableSalesItems: types.maybe(ItemsModel),
    showWarningForStatus: types.optional(types.boolean, false),

    items: types.array(SelectedEditItemModel),
    serialNumber: types.maybe(InputFormModel),
    ticketId: types.maybe(InputFormModel),
    description: types.maybe(InputFormModel),
    shippingServiceType: types.maybe(InputFormModel),
    syncWithShipStation: types.maybe(InputFormModel),

    streetShipping: types.maybe(InputFormModel),
    cityShipping: types.maybe(InputFormModel),
    stateShipping: types.maybe(InputFormModel),
    zipCodeShipping: types.maybe(InputFormModel),

    trackingNumber: types.maybe(InputFormModel),
    shippingPrice: types.maybe(InputFormModel),
    orderState: types.maybe(InputFormModel),
    shippingDate: types.maybe(InputFormModel),
    deviceStartDate: types.maybe(InputFormModel)
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {
    const resetForm = () => {
      self.isLoading = false;
      self.accountName = "";
      self.customerId = 0;
      self.isSplited = false;
      self.showWarningForStatus = false;
      applySnapshot(self, EditOrderInitialState);
    };

    const isHasNoSerializedItem = () => {
      if (self.items && self.items.length) {
        const noSerializedItem = self.items.find(el => !el.isSerialized);
        if (noSerializedItem) {
          return true;
        }
      }
      return false;
    };

    const checkWarningForStatus = () => {
      if (+self.orderState.value !== OrderStateNum.ShippedActive
        && self.trackingNumber?.value
        && isValidateSerialNumbersCount()
      ) {
        self.showWarningForStatus = true;
      } else {
        self.showWarningForStatus = false;
      }
    };

    const deleteItem = (id: number) => {
      self.items = cast(self.items.filter((el: ISelectedEditItemModel) => el.salesItemId !== id));
    };

    const addItem = (salesItem: number, quantityOrdered: number) => {
      const newItem = self.availableSalesItems.salesItems.find((el: ISalesItemModel) => salesItem === el.id);
      if (newItem) {
        const data: ISelectedEditItemModel = cast({
          salesItemId: newItem.id,
          name: newItem.name,
          price: newItem.price,
          quantityOrdered,
          isSerialized: newItem.isSerialized,
          isSubscription: newItem.isSubscription,
          serialNumbers: [],
          serialNumbersValueInput: "",
          errorSerialNumbersCount: null,
          warningSerialNumbers: null,
          accessories: (newItem.items || [])
            .map((elAccessory: IAccessoriesSalesItemModel) => { return { ...elAccessory }; })
        });
        self.items.push(data);
      }
    };

    const setQuantity = (id: number, value: number) => {
      const item = self.items?.find(i => i.salesItemId === id);
      if (item) {
        item.quantityOrdered = value;
      }
    };

    const setSerial = (id: number, value: string) => {
      const item = self.items?.find(i => i.salesItemId === id);
      if (item) {
        item.serialNumbersValueInput = value;
      }
    };

    const fetchData = flow(function* fetchData(id: number) {
      self.setLoading(true);
      yield Promise.all([
        fetchContactData(id),
        fetchAvailableSalesItems(id)
      ]);
      yield fetchOrderData(id);

      checkWarningForStatus();
      self.setLoading(false);

      if (!self.isSynchronized) {
        fetchOrderDataShipStation(id);
      }
    });
    
    const updateState = (state: number) => {
      const optionsState = (state === OrderStateNum.ShippedActive
        || state === OrderStateNum.ShippedSuspended || state === OrderStateNum.Cancelled)
        ? OrderStateOptions
          .filter(el => el.value !== OrderStateNum.CompleteNotShipped)
        : OrderStateOptions;
      self.orderState.setOptions(optionsState.map(el => {
        return { value: el.value.toString(), label: el.label };
      }));
      self.orderState.setValue(state.toString());
      const optionStatus = self.orderState.options.find((item) => item.value === state.toString());
      if (optionStatus) {
        self.orderState.setDefaultValue({ value: optionStatus.value.toString(), label: optionStatus.label });
      }
    };

    const fetchOrderData = flow(function* fetchOrderData(id: number) {
      if (!id) { return; }
      try {
        const response: ApiResponseType<any> = yield orderAPI.getOrderInfo(id);
        if (response.isOk) {
          const { data } = response.data;

          self.accountName = data.accountName;
          self.customerId = data.customerId;
          self.isSplited = data.isSplited;
          self.items = data.items.map((el: ISelectedEditItemModel) => {
            let accessories: IAccessoriesSalesItemModel[] = [];
            let name = el.name;
            const salesItem = self.availableSalesItems.salesItems.find(i => i.id === el.salesItemId);
            if (salesItem) {
              name = salesItem.name;
              accessories = salesItem.items
                .map((elAccessory: IAccessoriesSalesItemModel) => { return { ...elAccessory }; });
            }
            return !el.serialNumbers
              ? { ...el, name }
              : {
                ...el,
                name,
                serialNumbersValueInput: el.serialNumbers.join("\n"),
                accessories
              };
          });
          self.description.setValue(data.description || "");
          self.serialNumber.setValue(data.serialNumber || "");
          self.ticketId.setValue(data.ticketId || "");
          self.trackingNumber.setValue(data.trackingNumber || "");
          self.shippingPrice.setValue(data.shippingPrice ? data.shippingPrice.toString() : "");

          self.deviceStartDate.setValue(data.deviceStartDate
            ? dayjs(data.deviceStartDate).format("MM/DD/YYYY")
            : "");
          self.shippingDate.setValue(data.shippingDate
            ? dayjs(data.shippingDate).format("MM/DD/YYYY")
            : "");

          updateState(data.orderState);

          self.shippingServiceType.setValue(data.shippingServiceType.toString());
          const optionShippingServiceType = self.shippingServiceType.options
            .find((item) => item.value === data.shippingServiceType.toString());
          if (optionShippingServiceType) {
            self.shippingServiceType.setDefaultValue({
              value: optionShippingServiceType.value.toString(),
              label: optionShippingServiceType.label
            });
          }
          self.syncWithShipStation.setValue(data?.shipStationSync?.shouldBeSynced.toString());
          self.isSynchronized = data?.shipStationSync?.isSynchronized;
        }
      } catch (error) {
        console.error(error);
      }
    });

    const fetchOrderDataShipStation = flow(function* fetchOrderDataShipStation(id: number) {
      if (!id) { return; }
      try {
        const response: ApiResponseType<any> = yield orderAPI.getOrderInfoShipStation(id);
        if (response.isOk) {
          const { data } = response.data;

          if (data.state === null) {
            self.isSynchronized = true;
            return;
          }

          self.trackingNumber.setValue(data.trackingNumber || "");
          self.shippingPrice.setValue(data.shippingPrice ? data.shippingPrice.toString() : "");

          updateState(data.state);

          self.deviceStartDate.setValue(data.usageStartAt
            ? dayjs(data.usageStartAt).format("MM/DD/YYYY")
            : "");
          self.shippingDate.setValue(data.shippedAt
            ? dayjs(data.shippedAt).format("MM/DD/YYYY")
            : "");

          data.items.forEach((el: any) => {
            const salesItem = self.items.find(i => i.salesItemId === el.salesItemId);
            if (salesItem) {
              salesItem.serialNumbers = el.serialNumbers;
              salesItem.serialNumbersValueInput = el.serialNumbers.join("\n");
            }
          });

          self.isSynchronized = true;
        }
      } catch (error) {
        console.error(error);
      }
    });

    const fetchContactData = flow(function* fetchContactData(id: number) {
      if (!id) { return; }
      try {
        const res: ApiResponseType<TAccountContactData> = yield orderAPI.getAccountContactData(id);
        if (!res.isOk) {
          return;
        }
        const { shippingAddress } = res.data;

        self.streetShipping.setValue(shippingAddress?.street || "");
        self.cityShipping.setValue(shippingAddress?.city || "");
        self.stateShipping.setValue(shippingAddress?.state || "");
        const optionState = self.stateShipping.options
          .find((item) => item.value === shippingAddress?.state);
        if (optionState) {
          self.stateShipping.setDefaultValue({ value: optionState.value, label: optionState.label });
        }
        self.zipCodeShipping.setValue(shippingAddress?.zipCode || "");
      } catch (error) {
        console.error(error);
      }
    });

    const fetchAvailableSalesItems = flow(function* fetchAvailableSalesItems(id: number) {
      if (!id) { return; }
      try {
        const r: ApiResponseType<IItemsModel> = yield salesItemAPI.getItemsByOrderId(id);
        if (r.isOk) {
          self.availableSalesItems = r.data;
          self.availableSalesItems.salesItems.map((el) => {
            el.name = `${el.name} ${el.sku ? `(${el.sku})` : ""}`;
          });
        } else {
          self.availableSalesItems.handling = null;
          self.availableSalesItems.shipping = null;
          self.availableSalesItems.salesItems = null;
        }
      } catch (error) {
        console.error(error);
      }
    });

    const checkShipmentInfo = flow(function* checkShipmentInfo(id: number, el: ISelectedEditItemModel) {
      const serialNumbers = el.serialNumbersValueInput?.match(/RX\d{6}/g);
      if (serialNumbers) {
        const result = yield orderAPI.checkShipmentInfo(id, serialNumbers as [string]);
        if (result.isOk && result.data.entries && result.data.entries.length) {
          let warning = "";
          result.data.entries.forEach((item: any) => {
            if (item.serialNumber && item.orderNumber) {
              // eslint-disable-next-line max-len
              warning = warning + `${item.serialNumber} on ${item.orderNumber} order was already shipped to another customer in last two weeks.\n\n`;
            }
            if (item.serialNumber && item.rmaNumber) {
              // eslint-disable-next-line max-len
              warning = warning + `${item.serialNumber} on ${item.rmaNumber} was already shipped to another customer in last two weeks.\n\n`;
            }
          });
          el.warningSerialNumbers = warning;
        } else {
          el.warningSerialNumbers = null;
        }
      }
    });

    const isValidateSerialNumbersCount = () => {
      let isValid = true;
      self.items.forEach((el: ISelectedEditItemModel) => {
        if (el.isSerialized && el.serialNumbersValueInput?.match(/RX\d{6}/g)?.length !== el.quantityOrdered) {
          el.errorSerialNumbersCount = "Entries of DeviceSN do not match Order Qty";
          isValid = false;
        }
      });

      return isValid;
    };

    const validateFormEditOrder = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.serialNumber?.value) {
        errors.serialNumber = "Please fill the field";
        isValid = false;
      }

      if (!self.streetShipping?.value) {
        errors.streetShipping = "Please fill the field";
        isValid = false;
      }

      if (!self.cityShipping?.value) {
        errors.cityShipping = "Please fill the field";
        isValid = false;
      }

      if (!self.zipCodeShipping?.value) {
        errors.zipCodeShipping = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isZipCodeValid(self.zipCodeShipping?.value)) {
          errors.zipCodeShipping = "Please enter valid Zip/Postal Code";
          isValid = false;
        }
      }

      if ((+self.orderState.value === OrderStateNum.ShippedActive
        || +self.orderState.value === OrderStateNum.ShippedSuspended)
          && !self.deviceStartDate.value) {
        errors.deviceStartDate = "Order cannot be in Active or Suspended state unless Device start date is entered";
        isValid = false;
      }

      if (self.shippingDate.value
        && self.deviceStartDate.value
        && dayjs(self.deviceStartDate.value) < dayjs(self.shippingDate.value)
      ) {
        errors.deviceStartDate = "The selected date is less than the Shipping Date";
        isValid = false;
      }

      if (+self.orderState.value === OrderStateNum.ShippedActive
        && isHasNoSerializedItem()
        && !self.shippingPrice.value
      ) {
        errors.shippingPrice = "Please fill the field";
        isValid = false;
      }

      if (+self.orderState.value === OrderStateNum.ShippedActive && !isValidateSerialNumbersCount()) {
        isValid = false;
      }

      checkWarningForStatus();

      return {
        errors,
        isValid
      };
    };

    const saveOrder = flow(function* saveOrder(id: number) {
      try {
        const payload: TEditOrder = {
          id,
          shipDate: self.shippingDate.value
            ? DateUtils.toServerFormat(new Date(self.shippingDate.value))
            : null,
          deviceStartDate: self.deviceStartDate.value
            ? DateUtils.toServerFormat(new Date(self.deviceStartDate.value))
            : null,
          trackingNumber: self.trackingNumber.value,
          orderNumber: self.serialNumber.value,
          orderState: +self.orderState.value,
          ticketId: self.ticketId.value || "",
          description: self.description.value || "",
          shippingServiceType: +self.shippingServiceType.value,
          syncWithShipStation: self.syncWithShipStation?.value === "true",
          accessories: self.items.map((el: ISelectedEditItemModel) => {
            return {
              id: el.salesItemId,
              quantityOrdered: el.quantityOrdered,
              serialNumbers: el.serialNumbersValueInput
                ? el.serialNumbersValueInput.match(/RX\d{6}/g)
                : []
            };
          }),
          shippingAddress: {
            street: self.streetShipping.value,
            city: self.cityShipping.value,
            state: self.stateShipping.value,
            zipCode: self.zipCodeShipping.value
          }
        };
        if (isHasNoSerializedItem()) {
          payload.shippingPrice = +self.shippingPrice.value;
        }
        const r: ApiResponseType<TOrder> = yield orderAPI.updateOrder(payload);
        return r;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    return {
      resetForm,
      checkWarningForStatus,
      fetchData,
      validateFormEditOrder,
      isValidateSerialNumbersCount,
      deleteItem,
      addItem,
      setQuantity,
      setSerial,
      saveOrder,
      isHasNoSerializedItem,
      checkShipmentInfo
    };
  });

export interface IEditOrderModelSnapShot extends SnapshotIn<typeof EditOrderModel> {}

import { ICreateAccountModelSnapShot } from "../stores/create-account.store";
import {
  States,
  AccountsStatusInfo,
  AccountsStatus,
  AccountSetTermsDurationInDays
} from "../boot/constants";

export const CreateAccountInitialState: ICreateAccountModelSnapShot = {
  id: 0,
  name: {
    name: "Account Name",
    value: "",
    type: "input",
    fieldId: "name"
  },
  status: {
    name: "Account Status",
    value: AccountsStatus.Inactive.toString(),
    type: "select",
    options: AccountsStatusInfo
      .map(el => ({ value: el.id.toString(), label: el.label })),
    defaultValue: { value: AccountsStatus.Inactive.toString(), label: AccountsStatusInfo[3].label },
    fieldId: "status"
  },
  connectionId: {
    name: "Clinic Connection",
    value: "",
    type: "select",
    fieldId: "connectionId",
    options: [],
    isOptional: true,
    isClearable: true
  },
  monitoringCenterId: {
    name: "Monitoring Center",
    value: "",
    type: "select",
    fieldId: "monitoringCenterId",
    options: [],
    isOptional: true,
    isClearable: true
  },
  salesRepId: {
    name: "Sales Rep",
    value: "",
    type: "select",
    fieldId: "salesRepId",
    options: []
  },
  salesManagerId: {
    name: "Sales Manager",
    value: "",
    type: "select",
    fieldId: "salesManagerId",
    options: []
  },
  tier1: {
    name: "Tier 1",
    value: "",
    type: "select",
    fieldId: "tier1",
    options: [],
    isOptional: true,
    isClearable: true
  },
  tier2: {
    name: "Tier 2",
    value: "",
    type: "select",
    fieldId: "tier2",
    options: [],
    isOptional: true,
    isClearable: true
  },
  netTermsDurationInDays: {
    name: "NET terms",
    value: AccountSetTermsDurationInDays[0].toString(),
    type: "select",
    fieldId: "netTermsDurationInDays",
    options: AccountSetTermsDurationInDays
      .map(el => ({ value: el.toString(), label: el.toString() })),
    defaultValue: {
      value: AccountSetTermsDurationInDays[0].toString(),
      label: AccountSetTermsDurationInDays[0].toString()
    }
  },
  mainClinicId: {
    name: "Select Main Clinic",
    value: "",
    type: "select",
    fieldId: "mainClinicId",
    options: []
  },
  model: {
    name: "Choose Sales Model",
    value: "",
    type: "select",
    fieldId: "model",
    options: []
  },
  standardPrice: {
    name: "Subscription Price, USD",
    value: "",
    type: "input",
    fieldId: "standardPrice"
  },
  mctRecordingPrice: {
    name: "MCT Recording Price, USD",
    value: "",
    type: "input",
    fieldId: "mctRecordingPrice"
  },
  eventRecordingPrice: {
    name: "Event Recording Price, USD",
    value: "",
    type: "input",
    fieldId: "eventRecordingPrice"
  },
  extendedHolterPrice: {
    name: "Extended Holter Price, USD",
    value: "",
    type: "input",
    fieldId: "extendedHolterPrice"
  },
  shortTermHolterPrice: {
    name: "Short Term Holter Price, USD",
    value: "",
    type: "input",
    fieldId: "shortTermHolterPrice"
  },
  homeHookupPrice: {
    name: "Home Hookup Price, USD",
    value: "",
    type: "input",
    fieldId: "homeHookupPrice"
  },



  onBoardingDate: {
    name: "Onboarding Date",
    value: "",
    type: "dateTime",
    fieldId: "onBoardingDate",
    isOptional: true
  },
  effectiveDate: {
    name: "Effective date",
    value: "",
    type: "dateTime",
    fieldId: "effectiveDate",
    isOptional: true
  },

  firstName: {
    name: "First Name",
    value: "",
    type: "input",
    fieldId: "firstName"
  },
  lastName: {
    name: "Last Name",
    value: "",
    type: "input",
    fieldId: "lastName"
  },
  email: {
    name: "Billing Email",
    value: "",
    type: "email",
    fieldId: "email"
  },
  phone: {
    name: "Billing Phone Number",
    value: "",
    type: "phone",
    fieldId: "phone"
  },

  streetShipping: {
    name: "Street Address",
    value: "",
    type: "input",
    fieldId: "streetShipping"
  },
  cityShipping: {
    name: "City",
    value: "",
    type: "input",
    fieldId: "cityShipping"
  },
  stateShipping: {
    name: "State/Territory",
    value: States[0],
    type: "select",
    options: States.map(el => ({ value: el, label: el })),
    defaultValue: { value: States[0], label: States[0] },
    fieldId: "stateShipping"
  },
  zipCodeShipping: {
    name: "Zip/Postal Code",
    value: "",
    type: "input",
    fieldId: "zipCodeShipping"
  }
};

import { API } from "../../boot";
import { BaseFilter } from "../../core";
import { ApiResponseDto } from "../../models";
import { IAdminModelSnapShot } from "../../stores/admin-list.store";
import { ProcessAPIError } from "../decorators";
import { ApiResponseType, TPaginated } from "../types";

class AdminApi {
  @ProcessAPIError()
  public async getCounters(filter: BaseFilter): Promise<ApiResponseType<{
    all: number,
    salesRep: number,
    support: number,
    superusers: number,
    accounting: number,
    executive: number,
    production: number
  }>> {
    const result = await API.get("/admin/counters", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getAdmins(filter: BaseFilter, groupName: string)
    : Promise<ApiResponseDto<TPaginated<IAdminModelSnapShot[]>>> {
    const { data } = await API.get("/employees/detailed",
      { params: groupName ? { ...filter, groupName } : filter });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getGroupsAllowed() : Promise<ApiResponseDto<any>> {
    const { data } = await API.get("/employees/groups/allowed");
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async addAdmin(data: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    groups: [number] }
  ) {
    const result = await API.post("/invite", data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async updateAdmin(data: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    groups: [number] },
  id: number
  ) {
    const result = await API.put(`/employees/${id}`, data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async deleteAdmin(id: number) {
    const result = await API.delete(`/employees/${id}`);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getAdminInfo(id: number) {
    const result = await API.get(`/employees/${id}/creation`);
    return new ApiResponseDto(result);
  }
}

export const adminApi = new AdminApi();

import { IObservableArray } from "mobx";
import { SnapshotIn, types, applySnapshot, flow, Instance, cast } from "mobx-state-tree";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import { InputFormModel } from "vivaquant-components-library";
import { PAGINATION, ReasonList, RMAStatus, RMAStatusInfo } from "../boot/constants";
import { TAccountForRma } from "../models/account";
import { InventoryDto, InventorySerialNumberDto } from "../models/inventory.dto";
import { accountsAPI, ApiResponseType, DateUtils, rmaReturnApi, TPaginated, ValidationUtils } from "../services";
import { CreateRmaReturnInitialState } from "../states/create-rma-return.state";
import uniqBy from "lodash.uniqby";

const SerializedItemErrorsModel = types
  .model("SerializedItemErrorsModel", {
    returnSn: types.maybeNull(types.string),
    returnSnCustom: types.maybeNull(types.string),
    replacementSN: types.maybeNull(types.string),
    returnReason: types.maybeNull(types.string),
    received: types.maybeNull(types.string),
    reproducible: types.maybeNull(types.string)
  });

const SerializedItemModel = types
  .model("SerializedItemModel", {
    returnSn: types.maybeNull(InputFormModel),
    returnSnCustom: types.maybeNull(InputFormModel),
    id: types.number,
    replacementSN: types.maybeNull(InputFormModel),
    returnReason: types.maybe(InputFormModel),
    received: types.maybeNull(InputFormModel),
    reproducible: types.maybeNull(InputFormModel),
    returnWarning: types.optional(types.string, ""),
    returnCustomWarning: types.optional(types.string, ""),
    replacementWarning: types.optional(types.string, ""),
    isAvailableReturnSerial: types.optional(types.boolean, true),
    errors: types.maybeNull(types.optional(SerializedItemErrorsModel, {}))
  });

const ItemModel = types
  .model("ItemModel", {
    id: types.number,
    sku: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    returnQty: types.maybe(types.number),
    receivedQty: types.maybe(types.number),
    amount: types.maybe(types.number),
    isSerialized: types.maybe(types.boolean),
    serializedItems: types.maybeNull(types.optional(types.array(SerializedItemModel), [])),
    isFromOrder: types.boolean,
    canBeReproducible: types.boolean,
    amountFromOrder: types.optional(types.number, 0)
  });

export interface ICreateItemModel extends Instance<typeof ItemModel> {}
export interface ICreateSerializedItemModel extends Instance<typeof SerializedItemModel> {}
export interface ISerializedItemErrorsModel extends Instance<typeof SerializedItemErrorsModel> {}

export const CreateRmaReturnModel = types
  .model("CreateRmaReturnModel", {
    isLoading: types.optional(types.boolean, false),
    isLoadingAcc: types.optional(types.boolean, false),
    isLoadingItems: types.optional(types.boolean, false),
    isSynchronized: types.optional(types.boolean, true),
    isEdit: types.optional(types.boolean, false),
    isSubmited: types.optional(types.boolean, false),
    isRmaType: types.optional(types.boolean, true),
    id: types.maybe(types.number),
    availableAccounts: types.frozen(),
    availableInventory: types.frozen(),
    existingOptionsByItems: types.frozen(),
    accountSalesModel: types.maybeNull(types.number),

    ticketNumber: types.maybe(InputFormModel),
    ticketId: types.maybe(InputFormModel),
    requestDate: types.maybe(InputFormModel),
    itemsReceivedAt: types.maybe(InputFormModel),
    rmaShippedAt: types.maybe(InputFormModel),

    accountId: types.maybe(InputFormModel),
    description: types.maybe(InputFormModel),
    shippingServiceType: types.maybe(InputFormModel),
    streetShipping: types.maybe(InputFormModel),
    cityShipping: types.maybe(InputFormModel),
    stateShipping: types.maybe(InputFormModel),
    zipCodeShipping: types.maybe(InputFormModel),

    returnTracking: types.maybe(InputFormModel),
    replacementTracking: types.maybe(InputFormModel),
    status: types.maybe(InputFormModel),
    syncWithShipStation: types.maybe(InputFormModel),

    items: types.optional(types.array(ItemModel), [])
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setLoadingAcc(isLoading: boolean) {
      self.isLoadingAcc = isLoading;
    },
    setLoadingItems(isLoading: boolean) {
      self.isLoadingItems = isLoading;
    },
    getReturnSnData(returnSn: string, options: any[]) {
      const data = {
        name: "Return device serial number",
        value: "",
        type: "selectPaginate",
        fieldId: "returnSn",
        options,
        defaultValue: { value: "", label: "" },
        isClearable: true
      };
      const option = data.options.find((item: any) => item.value === returnSn);
      if (option) {
        data.value = returnSn;
        data.defaultValue = { value: option.value, label: option.label };
      }
      return data;
    },
    getReturnSnCustomData(returnSn: string) {
      const data = {
        name: "Custom return device serial number",
        value: returnSn,
        type: "input",
        fieldId: "returnSnCustom"
      };

      return data;
    },
    getReplacementSN(replacementSN: string) {
      const data = {
        name: "Replacement Device serial number",
        value: replacementSN,
        type: "input",
        fieldId: "replacementSN"
      };

      return data;
    },
    gerReturnReasonData(reason: string) {
      const data = {
        name: "Return Reason",
        value: reason,
        type: "select",
        fieldId: "returnReason",
        options: ReasonList.map((item: string) => {
          return { value: item, label: item };
        }),
        defaultValue: { value: "", label: "" }
      };
      const option = data.options.find((item) => item.value === reason);
      if (option) {
        data.defaultValue = { value: option.value, label: option.label };
      }

      return data;
    },
    getReceivedData(isReceived: boolean) {
      return {
        name: "Received",
        value: isReceived ? "true" : "false",
        type: "checkbox",
        isChecked: isReceived,
        fieldId: "received"
      };
    },
    getReproducibleData(isReproducible: boolean) {
      return {
        name: "Reproducible",
        value: isReproducible ? "true" : "false",
        type: "checkbox",
        isChecked: isReproducible,
        fieldId: "reproducible"
      };
    }
  }))
  .actions(self => {
    const resetForm = () => {
      self.isLoading = false;
      self.isLoadingAcc = false;
      self.isLoadingItems = false;
      self.items = [] as IObservableArray;
      self.isEdit = false;
      self.isSubmited = false;
      self.availableInventory = [];
      self.existingOptionsByItems = [];
      self.accountSalesModel = null;
      applySnapshot(self, CreateRmaReturnInitialState);
    };

    const resetChangeFormType = () => {
      self.isSubmited = false;
      self.items = [] as IObservableArray;
      applySnapshot(self.status, CreateRmaReturnInitialState.status);
      applySnapshot(self.replacementTracking, CreateRmaReturnInitialState.replacementTracking);
    };

    const resetReturnSnOptions = () => {
      fetchInventory();
      return self.items.forEach(flow(function* (item: ICreateItemModel) {
        if (item.isSerialized) {
          try {
            const response: ApiResponseType<TPaginated<InventorySerialNumberDto>>
              = yield rmaReturnApi.getItemsInventoryNumber(
                self.accountId.value,
                item.id,
                DateUtils.toServerFormat(new Date(self.requestDate.value)),
                0,
                PAGINATION.DEVICE_NUMBERS,
                "");
            let options = (response.isOk ? response.data.entries : []).map(el => {
              return { value: el.serialNumber, label: el.serialNumber };
            });
            const itemDevices = (self.existingOptionsByItems || []).find((el: any) => el.id === item.id);
            if (itemDevices) {
              const data = options.concat(itemDevices.devices);
              options = uniqBy(data, "value");
            }
            self.setLoadingItems(true);
            item.serializedItems.forEach((el: ICreateSerializedItemModel) => {
              setTimeout(() => {
                el.returnSn.setLoading(true);
              }, 1);

              const data = el.isAvailableReturnSerial
                ? self.getReturnSnData(el.returnSn.value || "", options || [])
                : self.getReturnSnData("", options || []);
              el.returnSn.setOptions(data.options);
              el.returnSn.setValue(data.value);
              el.returnSn.setDefaultValue(data.defaultValue);

              const dataCustom = !el.isAvailableReturnSerial
                ? self.getReturnSnCustomData(el.returnSn.value || "")
                : self.getReturnSnCustomData("");
              el.returnSnCustom.setValue(dataCustom.value);

              el.returnWarning = "";
              setTimeout(() => {
                el.returnSn.setLoading(false);
              }, 1);
            });
          } finally {
            self.setLoadingItems(false);
          }
        }
      }));
    };

    const setFormType = (value: boolean) => {
      if (self.isRmaType === value) { return; }
      self.isRmaType = value;
      resetChangeFormType();
    };

    const setReceivedQuantity = (id: number, value: number) => {
      const item = self.items?.find(i => i.id === id);
      if (item) {
        item.receivedQty = value;
      }
    };

    const setAccount = (isSetDefaultValue?: boolean) => {
      self.setLoadingAcc(true);
      const accountInfo = self.availableAccounts
        .find((item: TAccountForRma) => item.id.toString() === self.accountId?.value);
      if (accountInfo) {
        self.accountSalesModel = accountInfo.salesModel;
        self.streetShipping.setValue(accountInfo.street || "");
        self.cityShipping.setValue(accountInfo.city || "");
        self.stateShipping.setValue(accountInfo.state || "");
        const optionState = self.stateShipping.options
          .find((item) => item.value === accountInfo?.state);
        if (optionState) {
          self.stateShipping.setDefaultValue({ value: optionState.value, label: optionState.label });
        }
        self.zipCodeShipping.setValue(accountInfo.zipCode || "");
        self.items = [] as IObservableArray;
        self.availableInventory = [] as IObservableArray;
        fetchInventory();
        if (isSetDefaultValue) {
          const optionAccountId = self.accountId.options
            .find((item) => item.value === self.accountId.value);
          if (optionAccountId) {
            self.accountId.setDefaultValue({ value: optionAccountId.value, label: optionAccountId.label });
          }
        }
      } else {
        self.accountSalesModel = null;
      }
      self.setLoadingAcc(false);
    };

    const validateField = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (self.isEdit && !self.ticketNumber?.value) {
        errors.ticketNumber = "Please fill the field";
        isValid = false;
      }

      if (!self.requestDate?.value) {
        errors.requestDate = "Please fill the field";
        isValid = false;
      }

      if (self.itemsReceivedAt.value && dayjs(self.itemsReceivedAt.value) < dayjs(self.requestDate.value)) {
        errors.itemsReceivedAt = "The selected date is less than the Request Date";
        isValid = false;
      }

      if (self.rmaShippedAt.value && dayjs(self.rmaShippedAt.value) < dayjs(self.requestDate.value)) {
        errors.rmaShippedAt = "The selected date is less than the Request Date";
        isValid = false;
      }

      if (!self.accountId?.value) {
        errors.accountId = "Please fill the field";
        isValid = false;
      }

      if (!self.streetShipping?.value) {
        errors.streetShipping = "Please fill the field";
        isValid = false;
      }

      if (!self.cityShipping?.value) {
        errors.cityShipping = "Please fill the field";
        isValid = false;
      }

      if (!self.zipCodeShipping?.value) {
        errors.zipCodeShipping = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isZipCodeValid(self.zipCodeShipping?.value)) {
          errors.zipCodeShipping = "Please enter valid Zip/Postal Code";
          isValid = false;
        }
      }

      if (self.isRmaType
        && +self.status.value === RMAStatus.ShippedByCustomer
        && (!self.rmaShippedAt.value || !self.replacementTracking.value)) {
        // eslint-disable-next-line max-len
        errors.status = "Tracking numbers and Replacement Ship Date must be specified in order to set Shipped status";
        isValid = false;
      }

      if ((+self.status.value === RMAStatus.Received || +self.status.value === RMAStatus.Completed)
        && (!self.itemsReceivedAt.value || !self.returnTracking.value)) {
        // eslint-disable-next-line max-len
        errors.status = "Return tracking number and Items received/lost date must be specified in order to set Recieved or Complete status";
        isValid = false;
      }

      return {
        errors,
        isValid
      };
    };

    const setSerializedItemError = (el: ICreateSerializedItemModel, errors: ISerializedItemErrorsModel) => {
      el.errors = errors;
    };

    const validateItemData = (el: ICreateSerializedItemModel,
      allEl?: ICreateSerializedItemModel[]): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;
      if (!el.returnReason?.value) {
        errors.returnReason = "Return reason must be specified";
        isValid = false;
      }

      if (el.returnSn
        && el.returnSnCustom
        && !el.returnSn?.value
        && !el.returnSnCustom?.value
      ) {
        errors.returnSn = "Return serial number must be specified";
        isValid = false;
      }

      if (self.isRmaType
        && el.returnSn?.value
        && el.replacementSN?.value
        && el.returnSn?.value === el.replacementSN?.value) {
        errors.replacementSN = "Shouldn't be able to have the same serial";
        isValid = false;
      }

      if (self.isRmaType
        && !el.returnSn?.value
        && el.returnSnCustom?.value
        && el.replacementSN?.value
        && el.returnSnCustom?.value === el.replacementSN?.value) {
        errors.replacementSN = "Shouldn't be able to have the same serial";
        isValid = false;
      }

      if (self.isRmaType
        && el.replacementSN?.value
        && !ValidationUtils.isSerialNum(el.replacementSN?.value)) {
        errors.replacementSN = "Replacement serial number must be of RX000000 format";
        isValid = false;
      }

      if (el.returnSnCustom?.value
        && !ValidationUtils.isSerialNum(el.returnSnCustom?.value)) {
        errors.returnSnCustom = "Return serial number must be of RX000000 format";
        isValid = false;
      }

      if (allEl) {
        allEl.forEach((item: ICreateSerializedItemModel) => {

          if (el.id !== item.id
            && el.returnSn.value
            && item.returnSn.value
            && ValidationUtils.isSerialNum(el.returnSn?.value)
            && ValidationUtils.isSerialNum(item.returnSn?.value)
            && el.returnSn.value === item.returnSn.value
          ) {
            errors.returnSn = "Shouldn't be able to have the same Return device serial number";
            isValid = false;
          }

          if (el.id !== item.id
            && el.returnSn.value
            && item.returnSnCustom.value
            && ValidationUtils.isSerialNum(el.returnSn?.value)
            && ValidationUtils.isSerialNum(item.returnSnCustom?.value)
            && el.returnSn.value === item.returnSnCustom.value
          ) {
            errors.returnSn = "Shouldn't be able to have the same Return device serial number";
            isValid = false;
          }

          if (el.id !== item.id
            && el.returnSnCustom.value
            && item.returnSn.value
            && ValidationUtils.isSerialNum(el.returnSnCustom?.value)
            && ValidationUtils.isSerialNum(item.returnSn?.value)
            && el.returnSnCustom.value === item.returnSn.value
          ) {
            errors.returnSnCustom = "Shouldn't be able to have the same Return device serial number";
            isValid = false;
          }

          if (el.id !== item.id
            && el.returnSnCustom.value
            && item.returnSnCustom.value
            && ValidationUtils.isSerialNum(el.returnSnCustom?.value)
            && ValidationUtils.isSerialNum(item.returnSnCustom?.value)
            && el.returnSnCustom.value === item.returnSnCustom.value
          ) {
            errors.returnSnCustom = "Shouldn't be able to have the same Return device serial number";
            isValid = false;
          }

          if (self.isRmaType
            && item.id !== el.id
            && el.replacementSN.value
            && item.replacementSN.value
            && ValidationUtils.isSerialNum(el.returnSnCustom?.value)
            && ValidationUtils.isSerialNum(item.returnSnCustom?.value)
            && el.replacementSN.value === item.replacementSN.value
          ) {
            errors.replacementSN = "Shouldn't be able to have the same Replacement Device serial number";
            isValid = false;
          }
        });
      }
      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(rmaId: number, returnId: number) {
      self.setLoading(true);
      yield fetchAccounts();
      yield fetchRmaReturnData(rmaId, returnId);
      self.setLoading(false);

      if (self.isEdit && rmaId && !self.isSynchronized) {
        fetchRmaReturnDataShipStation(rmaId);
      }
    });

    const fetchAccounts = flow(function* fetchAccounts() {
      const response: ApiResponseType<TAccountForRma[]> = yield accountsAPI.getAccountsForRma();
      if (!response.isOk || !response.data) {
        return;
      }
      self.accountId.setOptions(response.data.map((item: TAccountForRma) => {
        return { value: item.id.toString(), label: item.name };
      }));

      self.availableAccounts = response.data;
    });

    const fetchInventory = flow(function* fetchInventory() {
      if (!self.accountId || !self.accountId.value) {
        return;
      }
      const response: ApiResponseType<InventoryDto[]>
        = yield rmaReturnApi.getItemsInventory(
          self.accountId.value, DateUtils.toServerFormat(new Date(self.requestDate.value)), self.id);
      if (!response.isOk || !response.data) {
        return;
      }
      self.availableInventory = response.data;
    });

    const fetchRmaReturnItemsData = flow(function* fetchRmaReturnItemsData(items: any, nonSerializedItems: any) {
      self.setLoadingItems(true);

      self.existingOptionsByItems = items.map((item: any) => {
        const devices = item.parts.reduce(function(filtered: any, el: any) {
          if (el.isAvailableReturnSerial) {
            const someNewValue = { value: el.returnSerial, label: el.returnSerial };
            filtered.push(someNewValue);
          }
          return filtered;
        }, []);
        return {
          id: item.inventoryItemId,
          devices
        };
      });

      const dataItems = yield Promise.all(items.map(flow(function* (item: any) {
        const inventoryItem = self.availableInventory.find((el: any) => el.id === item.inventoryItemId);
        if (inventoryItem) {
          const response: ApiResponseType<TPaginated<InventorySerialNumberDto>>
            = yield rmaReturnApi.getItemsInventoryNumber(
              self.accountId.value,
              item.inventoryItemId,
              DateUtils.toServerFormat(new Date(self.requestDate.value)),
              0,
              PAGINATION.DEVICE_NUMBERS,
              "");
          let options = (response.isOk ? response.data.entries : []).map(el => {
            return { value: el.serialNumber, label: el.serialNumber };
          });
          const itemDevices = (self.existingOptionsByItems || []).find((el: any) => el.id === item.inventoryItemId);
          if (itemDevices) {
            const data = options.concat(itemDevices.devices);
            options = uniqBy(data, "value");
          }

          return {
            id: item.inventoryItemId,
            sku: inventoryItem.sku,
            name: inventoryItem.name,
            isFromOrder: inventoryItem.isFromOrder,
            canBeReproducible: inventoryItem.canBeReproducible,
            returnQty: item.parts.length,
            receivedQty: item.parts.map((part: any) => {
              return part.isReceived;
            }).length,
            isSerialized: inventoryItem.isSerialized,
            serializedItems: item.parts.map((part: any) => {
              return {
                returnSn: part.isAvailableReturnSerial
                  ? self.getReturnSnData(part.returnSerial || "", options || [])
                  : self.getReturnSnData("", options || []),
                returnSnCustom: !part.isAvailableReturnSerial
                  ? self.getReturnSnCustomData(part.returnSerial || "")
                  : self.getReturnSnCustomData(""),
                id: part.id,
                replacementSN: self.getReplacementSN(part.replacementSerial || ""),
                returnReason: self.gerReturnReasonData(part.reason || ""),
                received: self.getReceivedData(!!part.isReceived),
                reproducible: self.getReproducibleData(!!part.isReproducible),
                isAvailableReturnSerial: !!part.isAvailableReturnSerial
              };
            })
          };
        }
      })));

      const dataNonSerializedItems = (nonSerializedItems || []).map((item: any) => {
        const inventoryItem = self.availableInventory.find((el: any) => el.id === item.inventoryItemId);

        if (inventoryItem) {
          return {
            id: item.inventoryItemId,
            sku: inventoryItem.sku,
            name: inventoryItem.name,
            isFromOrder: inventoryItem.isFromOrder,
            canBeReproducible: inventoryItem.canBeReproducible,
            returnQty: item.quantityShipped,
            receivedQty: item.quantityReceived,
            isSerialized: inventoryItem.isSerialized,
            serializedItems: [{
              returnSn: cast(null),
              id: 0,
              replacementSN: cast(null),
              returnReason: self.gerReturnReasonData(item.reason || ""),
              received: cast(null),
              reproducible: cast(null)
            }]
          };
        }
      });

      self.items = cast(dataNonSerializedItems.length === 0 ? dataItems : dataItems.concat(dataNonSerializedItems));
      self.setLoadingItems(false);
    });

    const addItem = (salesItem: number, quantity: number) => {
      const item = self.availableInventory.find((el: InventoryDto) => { return el.id === salesItem; });
      if (item) {
        const data: ICreateItemModel = cast({
          id: item.id,
          sku: item.sku,
          name: item.name,
          isFromOrder: item.isFromOrder,
          canBeReproducible: item.canBeReproducible,
          returnQty: quantity,
          receivedQty: 0,
          isSerialized: item.isSerialized,
          serializedItems: null
        });

        if (data.isSerialized) {
          const arr = [];
          for (let i = 0; i < quantity; i++) {
            arr.push({
              returnSn: self.getReturnSnData("", []),
              returnSnCustom: self.getReturnSnCustomData(""),
              id: i,
              replacementSN: self.getReplacementSN(""),
              returnReason: self.gerReturnReasonData(""),
              received: self.getReceivedData(false),
              reproducible: self.getReproducibleData(false)
            });
          }
          data.serializedItems = cast(arr);
        } else {
          data.serializedItems = cast([{
            returnSn: cast(null),
            returnSnCustom: cast(null),
            id: 0,
            replacementSN: cast(null),
            returnReason: self.gerReturnReasonData(""),
            received: cast(null),
            reproducible: cast(null)
          }]);
        }
        self.items.push(data);
      }
    };

    const removeItem = (id: number) => {
      self.items = cast(self.items.filter((el: ICreateItemModel) => el.id !== id));
    };
    
    const updateStatus = (status: number) => {
      const rmaStatuses = (status === RMAStatus.ShippedByCustomer
        || status === RMAStatus.Received || status === RMAStatus.Completed)
        ? RMAStatusInfo.filter(el => el.id !== RMAStatus.NotCompleted && el.id !== RMAStatus.Open)
        : RMAStatusInfo;
      self.status.setOptions(rmaStatuses.map(el => ({ value: el.id.toString(), label: el.label })));
      self.status.setValue(status.toString());
      const optionStatus = self.status.options.find((item) => item.value === status.toString());
      if (optionStatus) {
        self.status.setDefaultValue({ value: optionStatus.value.toString(), label: optionStatus.label });
      }
    };

    const fetchRmaReturnData = flow(function* fetchRmaReturnData(rmaId: number, returnId: number) {
      if (rmaId || returnId) {
        self.isEdit = true;
        try {
          const response = self.isRmaType
            ? yield rmaReturnApi.getDetailsRma(rmaId)
            : yield rmaReturnApi.getDetailsReturn(returnId);

          if (response.isOk) {
            const { data } = response.data;

            self.id = data.id;
            self.ticketNumber.setValue(data.number.toString() || "");
            self.ticketId.setValue(data.ticketId || "");
            self.requestDate.setValue(data.startDate
              ? dayjs.utc(data.startDate).format("MM/DD/YYYY")
              : "");
            self.itemsReceivedAt.setValue(data.itemsReceivedAt
              ? dayjs.utc(data.itemsReceivedAt).format("MM/DD/YYYY")
              : "");
            self.rmaShippedAt.setValue(data.rmaShippedAt
              ? dayjs.utc(data.rmaShippedAt).format("MM/DD/YYYY")
              : "");

            self.streetShipping.setValue(data.address?.street || "");
            self.cityShipping.setValue(data.address?.city || "");
            self.zipCodeShipping.setValue(data.address?.zipCode || "");
            self.description.setValue(data.description || "");

            self.shippingServiceType.setValue(data.shippingServiceType.toString());
            const optionShippingServiceType = self.shippingServiceType.options
              .find((item) => item.value === data.shippingServiceType.toString());
            if (optionShippingServiceType) {
              self.shippingServiceType.setDefaultValue({
                value: optionShippingServiceType.value.toString(),
                label: optionShippingServiceType.label
              });
            }

            self.stateShipping.setValue(data.address?.state || "");
            const optionState = self.stateShipping.options
              .find((item) => item.value === data.address?.state);
            if (optionState) {
              self.stateShipping.setDefaultValue({ value: optionState.value, label: optionState.label });
            }
            
            self.setLoadingAcc(true);
            self.accountId.setValue(data.accountId.toString());
            const optionAccountId = self.accountId.options
              .find((item) => item.value === data.accountId.toString());
            if (optionAccountId) {
              self.accountId.setDefaultValue({ value: optionAccountId.value, label: optionAccountId.label });
            }

            const accountInfo = self.availableAccounts
              .find((item: TAccountForRma) => item.id.toString() === self.accountId?.value);
            if (accountInfo) {
              self.accountSalesModel = accountInfo.salesModel;
            } else {
              self.accountSalesModel = null;
            }
            self.setLoadingAcc(false);

            self.returnTracking.setValue(data.returnTracking || "");
            self.replacementTracking.setValue(data.replacementTracking || "");
            
            updateStatus(data.status);

            yield fetchInventory();
            yield fetchRmaReturnItemsData(data.items, data.nonSerializedItems);

            self.syncWithShipStation.name = "Update Ship label";

            if (rmaId) {
              self.syncWithShipStation.setValue(data?.shipStationSync?.shouldBeSynced.toString());
              self.isSynchronized = data?.shipStationSync?.isSynchronized;
            }
            
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        self.status.setOptions(RMAStatusInfo.map(el => ({ value: el.id.toString(), label: el.label })));
      }
    });

    const fetchRmaReturnDataShipStation = flow(function* fetchRmaReturnDataShipStation(rmaId: number) {
      try {
        const response = yield rmaReturnApi.getDetailsRmaShipStation(rmaId);
        if (response.isOk) {
          const { data } = response.data;

          if (data.status === null) {
            self.isSynchronized = true;
            return;
          }

          self.returnTracking.setValue(data.returnTrackingNumber || "");
          self.replacementTracking.setValue(data.trackingNumber || "");

          self.rmaShippedAt.setValue(data.rmaShippedAt
            ? dayjs.utc(data.rmaShippedAt).format("MM/DD/YYYY")
            : "");

          data.items.forEach((el: any) => {
            const salesItem = self.items.find(i => i.id === el.salesItemId);
            if (salesItem) {
              el.parts.forEach((elPart: any) => {
                const part = salesItem.serializedItems.find(y => y.id === elPart.id);
                if (part) {
                  part.replacementSN.setValue(elPart.replacementSerialNumber);
                }
              });
            }
          });
        
          updateStatus(data.status);

          self.isSynchronized = true;
        }
      } catch (error) {
        console.error(error);
      }
    });

    const checkDateRequst = flow(function* (el: ICreateSerializedItemModel, serial: string, type: "rma" | "return") {
      try {
        const res = yield rmaReturnApi.doesShipmentExist({
          serialNumber: serial,
          byReturn: type === "return",
          customerId: +self.accountId.value
        });
        let msg = "";
        if (res.data.isTaken) {
          if (self.id === res.data.rmaId) {
            return;
          }
          msg = (`\nDevice ${serial} already shipped in ${res.data.rmaNumber}, ${res.data.customerName}`);
        }
        if (type === "return") {
          el.returnWarning = msg;
        } else {
          el.replacementWarning = msg;
        }
      } catch (e) {
        console.error(e);
      }
    });

    const checkAvailableRequet = flow(function* (
      el: ICreateSerializedItemModel,
      serial: string,
      salesItemId: string | number
    ) {
      try {
        const res = yield rmaReturnApi.doesAvailableSerialNumber(
          self.accountId.value,
          salesItemId,
          serial,
          DateUtils.toServerFormat(new Date(self.requestDate.value))
        );
        if (!res.data) {
          el.returnCustomWarning
            = "This DeviceSN was not found in Orders and RMAs. Please confirm that clinic has DeviceSN.";
        } else {
          el.returnCustomWarning = "";
        }
      } catch (e) {
        console.error(e);
      }
    });

    const checkDate = (el: ICreateSerializedItemModel, field: string, salesItemId?: string | number) => {
      if (field === "returnSn") {
        if (ValidationUtils.isSerialNum(el.returnSn.value)) {
          checkDateRequst(el, el.returnSn.value, "return");
        } else {
          el.returnWarning = "";
        }
      }

      if (field === "returnSnCustom") {
        if (ValidationUtils.isSerialNum(el.returnSnCustom.value)) {
          checkAvailableRequet(el, el.returnSnCustom.value, salesItemId);
        } else {
          el.returnCustomWarning = "";
        }
      }

      if (field === "replacementSN") {
        if (ValidationUtils.isSerialNum(el.replacementSN.value)) {
          checkDateRequst(el, el.replacementSN.value, "rma");
        } else {
          el.replacementWarning = "";
        }
      }
    };

    const getData = () => {
      const items: any[] = [];
      const nonSerializedItems: any[] = [];

      self.items.forEach((item: ICreateItemModel) => {
        if (item.isSerialized) {
          items.push({
            id: item.id,
            parts: item.serializedItems.map((serializedItem: ICreateSerializedItemModel) => {
              const itemData = {
                id: serializedItem.id,
                returnSerial: serializedItem.returnSn.value
                  ? serializedItem.returnSn.value?.trim()
                  : serializedItem.returnSnCustom.value?.trim(),
                replacementSerial: self.isRmaType ? serializedItem.replacementSN.value?.trim() || "" : "",
                reason: serializedItem.returnReason.value || "",
                isReceived: serializedItem.received.isChecked,
                isReproducible: serializedItem.reproducible.isChecked,
                isSerialized: item.isSerialized
              };
              if (!self.isRmaType) {
                delete itemData.isReproducible;
              } else if (!item.canBeReproducible) {
                itemData.isReproducible = false;
              }
              return itemData;
            })
          });
        } else {
          nonSerializedItems.push({
            id: item.id,
            reason: item.serializedItems[0].returnReason.value,
            quantityShipped: item.returnQty,
            quantityReceived: item.receivedQty
          });
        }
      });

      const data = {
        id: self.id || null,
        number: +self.ticketNumber.value,
        ticketId: self.ticketId.value,
        startDate: DateUtils.toServerFormat(new Date(self.requestDate.value)),
        rmaShippedAt: self.rmaShippedAt.value
          ? DateUtils.toServerFormat(new Date(self.rmaShippedAt.value))
          : null,
        itemsReceivedAt: self.itemsReceivedAt.value
          ? DateUtils.toServerFormat(new Date(self.itemsReceivedAt.value))
          : null,
        accountId: +self.accountId.value,
        returnTracking: self.returnTracking.value || "",
        replacementTracking: self.replacementTracking.value || "",
        syncWithShipStation: self.syncWithShipStation?.value === "true",
        status: +self.status.value,
        description: self.description.value || "",
        shippingServiceType: +self.shippingServiceType.value,
        address: {
          street: self.streetShipping.value,
          city: self.cityShipping.value,
          state: self.stateShipping.value,
          zipCode: self.zipCodeShipping.value
        },
        items,
        nonSerializedItems
      };

      return data;
    };

    const saveRmaReturn = flow(function* saveRmaReturn() {
      self.isSubmited = true;
      if (self.items.length === 0) {
        return { error: { detail: "No item selected" } };
      }

      self.items.forEach((item: ICreateItemModel) => {
        item.serializedItems.forEach((serializedItem: ICreateSerializedItemModel) => {
          const { errors, isValid } = validateItemData(serializedItem, item.serializedItems);
          setSerializedItemError(serializedItem, cast(errors));
          if (!isValid) {
            const error = { detail: "Please check the all fields" };
            throw error;
          }
        });
      });
      try {
        const data = getData();
        let result = null;
        if (!self.isEdit) {
          result = self.isRmaType
            ? yield rmaReturnApi.createRma(data)
            : yield rmaReturnApi.createReturn(data);
        } else {
          result = self.isRmaType
            ? yield rmaReturnApi.editRma(data)
            : yield rmaReturnApi.editReturn(data);
        }
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const loadOptions = flow(function* loadOptions(item: ICreateSerializedItemModel, id: number,
      search: string, loadedOptions: any
    ) {
      const response: ApiResponseType<TPaginated<InventorySerialNumberDto>>
        = yield rmaReturnApi.getItemsInventoryNumber(
          self.accountId.value,
          id,
          DateUtils.toServerFormat(new Date(self.requestDate.value)),
          loadedOptions.length,
          PAGINATION.DEVICE_NUMBERS,
          search);
      if (response.isOk) {
        const oldOptions: {
          value: string;
          label: string;
        }[] = (loadedOptions).map((el: any) => {
          return { value: el.value, label: el.label };
        });
        const newOptions = (response.data?.entries || []).map(el => {
          return { value: el.serialNumber, label: el.serialNumber };
        });
        const options = oldOptions.concat(newOptions);

        const data = uniqBy(options, "value");
        item.returnSn.setOptions(data);
        return {
          options: newOptions,
          hasMore: response.data.total > loadedOptions.length
        };
      } else {
        return {
          options: [],
          hasMore: false
        };
      }
    });

    return {
      resetForm,
      resetReturnSnOptions,
      setReceivedQuantity,
      setFormType,
      validateField,
      validateItemData,
      setAccount,
      fetchData,
      saveRmaReturn,
      addItem,
      removeItem,
      checkDate,
      loadOptions,
      setSerializedItemError
    };
  });

export interface ICreateRmaReturnModelSnapShot extends SnapshotIn<typeof CreateRmaReturnModel> {}

import { SLColors } from "./colors";
import { SLOffsets } from "./offsets";
import { SLSize } from "./size";
import { SLBreakPoints } from "./breakPoints";

export const theme = {
  colors: SLColors,
  offsets: SLOffsets,
  size: SLSize,
  breakpoints: SLBreakPoints
};

export type SLTheme = typeof theme;

import styled from "styled-components";
import { theme } from "../../theme";

export const DashboardDropdownWrapper = styled.div`
  position: absolute;
  right: 60px;

  .dropdown {
    &.show {
      .dropdown-chevron {
        transform: rotate(180deg);
      }
    }

    button {
      background: ${theme.colors.light} !important;
      border: none !important;
      color: ${theme.colors.text} !important;
      cursor: pointer;
      padding: 0;
      box-shadow: none !important;

      .text {
        display: inline-block;
        line-height: 24px;
        margin-right: 8px;
        margin-left: 8px;
      }

      img {
        display: inline-block;
        vertical-align: middle;
        border-radius: 8px;
        width: 40px;
        height: 40px;
      }

      ::after {
        display: none;
      }
    }

    .dropdown-toggle::after {
      display: none
    }

    .dropdown-menu {
      padding:8px;
      border-radius: 10px;
    }

    .dropdown-item {
      text-decoration: none !important;
      min-width: 227px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
      height: 48px;

      display: flex;
      align-items: center;
      color: ${theme.colors.text};
      padding: 12px 16px;
      border-radius: 6px;
      position: relative;
      box-sizing: border-box;
      .disabled {
        opacity: 0.5;
      }
      svg {
        margin-right: 8px;
      }
      &.logout {
        color: #F45B5B;
      }
      
      :hover {
        background: #F1F8FF;
      }

      
      &:not(:last-child) {
        margin-bottom: 16px;
        :after {
          content: "";
          position: absolute;
          bottom: -8px;
          left: 0;
          background-color: #E5E5E5;
          width: 100%;
          height: 1px;
        }
      }
    }
  }
`;

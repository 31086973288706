import styled, { css } from "styled-components";
import { theme } from "../../theme";

export const NavItemWrapper = styled.div<{isActive: boolean}>`
  text-align: center;
  box-sizing: border-box;
  width: auto;
  position: relative;
  height: 100%;
  
  a {
    text-decoration: none;
    background: ${theme.colors.light};
    color: ${theme.colors.text};
    padding-right: 24px;
    padding-left: 24px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;

  }
  
  ${({ isActive }) => isActive
    ? css`
      a {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }
      :after {
        content: "";
        height: 3px;
        background: ${theme.colors.blue};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }`
    : ""}
`;

import dayjs from "dayjs";

export class DateUtils {
  private static formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });

  public static DATE_FORMAT = "MMM DD, YYYY";

  public static format(date: Date): string {
    return date ? this.formatter.format(date) : "";
  }

  public static formatDate(date: Date | string): string {
    return dayjs(date).format("MM/DD/YYYY");
  }

  public static isDateNotEmpty(date: string): boolean {
    return !!date && new Date(date).getFullYear() !== 1;
  }

  public static isDateEmpty(date: Date): boolean {
    if (!date) {
      return true;
    }
    return date.getFullYear() <= 1;
  }

  public static getMomentDateFormat(date?: string) {
    return dayjs(date ? new Date(date) : new Date(), DateUtils.DATE_FORMAT);
  }

  public static toServerFormat(date?: Date) : string {
    if (!date) {
      return null;
    }
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset * 60 * 1000));
    return date.toISOString().split("T")[0];
  }
}

import { FC } from "react";
import LogoImgAccess from "../../assets/img/rhythm-access.png";
import { HeaderWrapper } from "./sl-header.presets";

export const SlHeaderLogoOnly: FC = () => {
  return (
    <HeaderWrapper className={"center"}>
      <img className="logo-access" src={LogoImgAccess} alt="Rhythm access logo"/>
    </HeaderWrapper>
  );
};

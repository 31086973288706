import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import { ShippingServiceTypeOptions, States } from "../boot/constants";
import { ICreateRmaReturnModelSnapShot } from "../stores/create-rma-return.store";

export const CreateRmaReturnInitialState: ICreateRmaReturnModelSnapShot = {
  id: 0,

  ticketNumber: {
    name: "Id",
    value: "",
    type: "input",
    fieldId: "ticketNumber"
  },
  ticketId: {
    name: "Zendesk/ClaritySoft ticket number",
    value: "",
    type: "input",
    fieldId: "ticketId"
  },
  requestDate: {
    name: "RMA Request Date",
    value: dayjs.utc().format("MM/DD/YYYY"),
    type: "dateTime",
    fieldId: "requestDate"
  },
  itemsReceivedAt: {
    name: "Items received/lost date",
    value: "",
    type: "dateTime",
    fieldId: "itemsReceivedAt"
  },
  rmaShippedAt: {
    name: "Replacement Ship Date",
    value: "",
    type: "dateTime",
    fieldId: "rmaShippedAt"
  },

  accountId: {
    name: "Account Name",
    value: "",
    type: "select",
    fieldId: "accountId",
    options: []
  },
  description: {
    name: "Attention To",
    value: "",
    type: "input",
    fieldId: "description",
    isOptional: true
  },
  shippingServiceType: {
    name: "Shipping Service",
    value: "",
    type: "select",
    fieldId: "shippingServiceType",
    options: ShippingServiceTypeOptions,
    defaultValue: { value: ShippingServiceTypeOptions[0].value, label: ShippingServiceTypeOptions[0].label },
  },
  streetShipping: {
    name: "Street Address",
    value: "",
    type: "input",
    fieldId: "streetShipping"
  },
  cityShipping: {
    name: "City",
    value: "",
    type: "input",
    fieldId: "cityShipping"
  },
  stateShipping: {
    name: "State/Territory",
    value: States[0],
    type: "select",
    options: States.map(el => ({ value: el, label: el })),
    defaultValue: { value: States[0], label: States[0] },
    fieldId: "stateShipping"
  },
  zipCodeShipping: {
    name: "Zip/Postal Code",
    value: "",
    type: "input",
    fieldId: "zipCodeShipping"
  },

  returnTracking: {
    name: "Return tracking number",
    value: "",
    type: "input",
    fieldId: "returnTracking",
    isOptional: true
  },
  replacementTracking: {
    name: "Replacement tracking number",
    value: "",
    type: "input",
    fieldId: "replacementTracking",
    isOptional: true
  },
  status: {
    name: "Status",
    value: "",
    options: [],
    type: "select",
    fieldId: "status"
  },
  syncWithShipStation: {
    name: "Create Ship label",
    value: "true",
    type: "checkbox",
    isChecked: true,
    fieldId: "syncWithShipStation"
  },
};

import styled from "styled-components";
import { theme } from "./theme";

export const GlobalStyles = styled.div`
  min-height: 100vh;

  * {
    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap');
    -webkit-tap-highlight-color: transparent;
    //font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .global-spinner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }

  .invalid-text {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${theme.colors.red};
    margin-top: 4px;
  }

  .global-spiner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }

  .margin-table {
    margin: -1px -24px -24px -24px;
  }

  .m-t-24 {
    margin-top: 24px;
  }

  .dropdown-item {
    div:after {
      display: none;
    }
  }

  .dropdown-item:active {
    background-color: transparent;
    span, div {
      background-color: transparent;
    }
  }

  .inline-middle {
    display: inline-block;
    vertical-align: middle;
  }
`;

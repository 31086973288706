import { Instance, SnapshotIn, flow, types } from "mobx-state-tree";
import { DevicesHistorySort, PAGINATION, SortDirection } from "../boot/constants";
import { AdminModelShort } from "./admin-list.store";
import { IObservableArray } from "mobx";
import { BaseFilter } from "../core";
import { ApiResponseType, ExportUtils, devicesApi } from "../services";

const DevicesHistoryModel = types
  .model("DevicesHistoryModel", {
    serialNumber: types.maybeNull(types.string),
    orderDate: types.maybeNull(types.string),
    rmaReturnDate: types.maybeNull(types.string),
    customer: types.model({
      name: types.maybeNull(types.string),
      city: types.maybeNull(types.string),
      state: types.maybeNull(types.string)
    }),
    rmaReturnReason: types.maybeNull(types.string),
    creator: types.maybeNull(AdminModelShort),
    ticketId: types.maybeNull(types.string),
    salesRep: types.maybeNull(AdminModelShort)
  });

export interface IDevicesHistoryModel extends Instance<typeof DevicesHistoryModel> {}
export interface IDevicesHistoryModelSnapShot extends SnapshotIn<typeof DevicesHistoryModel> {}

export const DevicesHistoryListModel = types
  .model("DevicesHistoryListModel", {
    page: 0,
    total: 0,
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, DevicesHistorySort.serialNumber),
    sortDirection: types.optional(types.number, SortDirection.DESC),
    isLoading: types.optional(types.boolean, true),
    items: types.optional(types.array(DevicesHistoryModel), [])
  })
  .actions(self => ({
    setList(dto: IDevicesHistoryModelSnapShot[]) {
      self.items = dto as IObservableArray;
    },
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {
    const setDefaultParams = () => {
      self.page = 0;
      self.searchValue = "";
      self.sortBy = DevicesHistorySort.serialNumber;
      self.sortDirection = SortDirection.DESC;
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new BaseFilter();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        const result: ApiResponseType<IDevicesHistoryModelSnapShot[]> = yield devicesApi.getHistoryList(filter);
        if (result.isOk) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new BaseFilter();
        filter.search = self.searchValue || "";

        const result: ApiResponseType<number> = yield devicesApi.getHistoryCounter(filter);
        if (result.isOk) {
          self.total = result.data;
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const downloadExport = flow(function* downloadExport() {
      const filter = new BaseFilter();
      filter.search = self.searchValue || "";
      filter.sortBy = self.sortBy;
      filter.sortDirection = self.sortDirection;

      try {
        const exportResult = yield devicesApi.getHistoryExport(filter);
        if (!exportResult.isOk) {
          return;
        }

        ExportUtils.downloadCSV(exportResult.data.data, "Devices History.csv");
      } catch (error) {
        console.error(error);
      }
    });

    return {
      nextPage,
      previousPage,
      getList,
      getTotalCount,
      setSearch,
      setSortBy,
      downloadExport,
      setFirstPage,
      setDefaultParams
    };
  });

import { Permissions } from "../../services";

export const DASHBOARD_ROUTES = [
  {
    link: "dashboard/accounts",
    title: "Accounts"
  },
  {
    link: "dashboard/orders",
    title: "Orders"
  },
  {
    link: "dashboard/rma",
    title: "RMA/Return"
  },
  {
    link: "dashboard/billing",
    title: "Billing"
  },
  {
    link: "dashboard/devices",
    title: "Devices",
    permission: Permissions.ReadDevicesHistory
  },
  {
    link: "dashboard/admin",
    title: "Admin",
    permission: Permissions.InviteUser
  }
];

import { ProcessAPIError } from "../decorators";
import { ApiResponseType } from "../types";
import { API } from "../../boot";
import { ApiResponseDto } from "../../models";
import { SalesItemsEntityDto } from "../../models/salesItems.dto";

class SalesItemsAPI {
  @ProcessAPIError()
  public async getItemsByAccountId(id: number): Promise<ApiResponseType<SalesItemsEntityDto>> {
    const { data } = await API.get("sales-items/customer/" + id);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getItemsByOrderId(id: number): Promise<ApiResponseType<SalesItemsEntityDto>> {
    const { data } = await API.get("sales-items/order/" + id);
    return new ApiResponseDto(data);
  }
}

export const salesItemAPI = new SalesItemsAPI();

import { IObservableArray } from "mobx";
import { flow, Instance, SnapshotIn, types } from "mobx-state-tree";
import { AdminListCategories, SortDirection, PAGINATION, AdminSort } from "../boot/constants";
import { BaseFilter } from "../core";
import { ApiResponseType, adminApi, TPaginated } from "../services";

export const AdminModelShort = types
  .model("AdminModelShort", {
    id: types.number,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string)
  });

export interface IAdminModelShort extends Instance<typeof AdminModelShort> {}

export const AdminModel = types
  .model("AdminModel", {
    id: types.number,
    firstname: types.maybeNull(types.string),
    lastname: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    groups: types.optional(types.array(types.model({
      id: types.number,
      name: types.string
    })), []),
    phone: types.maybeNull(types.string)
  });

export interface IAdminModel extends Instance<typeof AdminModel> {}
export interface IAdminModelSnapShot extends SnapshotIn<typeof AdminModel> {}

export const AdminListModel = types
  .model("AdminListModel", {
    activeCategory: types.optional(types.string, AdminListCategories[0].value),
    page: 0,
    total: 0,
    items: types.optional(types.array(AdminModel), []),
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, AdminSort.firstname),
    sortDirection: types.optional(types.number, SortDirection.DESC),
    allCategoryTotal: types.optional(types.number, 0),
    salesRepCategoryTotal: types.optional(types.number, 0),
    supportCategoryTotal: types.optional(types.number, 0),
    superusersCategoryTotal: types.optional(types.number, 0),
    accountingCategoryTotal: types.optional(types.number, 0),
    executiveCategoryTotal: types.optional(types.number, 0),
    isLoading: types.optional(types.boolean, true)
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setList(dto: IAdminModelSnapShot[]) {
      self.items = dto as IObservableArray;
    }
  }))
  .actions(self => {
    const setActiveCategory = (value: string, count: number) => {
      self.activeCategory = value;
      self.page = 0;
      self.total = count;
      getList();
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const setDefaultParams = () => {
      self.activeCategory = AdminListCategories[0].value;
      self.page = 0;
      self.searchValue = "";
      self.sortBy = AdminSort.firstname;
      self.sortDirection = SortDirection.DESC;
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new BaseFilter();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        const listItem = AdminListCategories.find(i => i.value === self.activeCategory);

        const result: ApiResponseType<TPaginated<IAdminModelSnapShot>>
          = yield adminApi.getAdmins(filter, listItem.groupName);
        if (result.isOk) {
          self.setList(result.data.entries);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new BaseFilter();
        filter.search = self.searchValue || "";

        const result: ApiResponseType<{
          all: number,
          salesRep: number,
          support: number,
          superusers: number,
          accounting: number,
          executive: number,
          production: number}> = yield adminApi.getCounters(filter);
        if (result.isOk) {
          self.allCategoryTotal = result.data.all || 0;
          self.salesRepCategoryTotal = result.data.salesRep || 0;
          self.supportCategoryTotal = result.data.support || 0;
          self.superusersCategoryTotal = result.data.superusers || 0;
          self.accountingCategoryTotal = result.data.accounting || 0;
          self.executiveCategoryTotal = result.data.executive || 0;

          switch (self.activeCategory) {
            case AdminListCategories[0].value :
              self.total = self.allCategoryTotal;
              break;
            case AdminListCategories[1].value :
              self.total = self.salesRepCategoryTotal;
              break;
            case AdminListCategories[2].value :
              self.total = self.supportCategoryTotal;
              break;
            case AdminListCategories[3].value :
              self.total = self.superusersCategoryTotal;
              break;
            case AdminListCategories[4].value :
              self.total = self.accountingCategoryTotal;
              break;
            case AdminListCategories[5].value :
              self.total = self.executiveCategoryTotal;
              break;
            default:
              break;
          }
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const deleteAdmin = flow(function* deleteAdmin(id: number) {
      try {
        self.isLoading = true;
        const result = yield adminApi.deleteAdmin(id);

        if (result.isOk) {
          getTotalCount();
          return result.isOk;
        }
      } catch (error) {
        console.error(error);
        return false;
      } finally {
        self.isLoading = false;
      }
    });

    return {
      nextPage,
      previousPage,
      getList,
      setActiveCategory,
      getTotalCount,
      setSearch,
      setSortBy,
      setFirstPage,
      deleteAdmin,
      setDefaultParams
    };
  });

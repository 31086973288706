import { IUserProfile } from "../models/v2/auth/IUserProfile";

export enum Permissions {
  Login = 1 << 0,
  ReadOwnedOrders = 1 << 1,
  ReadOwnedCustomers = 1 << 2,
  ReadOwnedReturns = 1 << 3,
  ReadAllOrders = 1 << 4,
  ReadAllCustomers = 1 << 5,
  ReadAllReturns = 1 << 6,
  EditOwnedOrders = 1 << 7,
  EditOwnedCustomers = 1 << 8,
  EditOwnedReturns = 1 << 9,
  EditAllOrders = 1 << 10,
  EditAllCustomers = 1 << 11,
  EditAllReturns = 1 << 12,
  CreateCustomer = 1 << 13,
  EditCustomerOnboardDate = 1 << 14,
  EditCustomerName = 1 << 15,
  InviteUser = 1 << 16,
  ExportOrders = 1 << 17,
  ExportCustomers = 1 << 18,
  ExportReturns = 1 << 19,
  ExportBilling = 1 << 20,
  ReadAllBilling = 1 << 21,
  SetCustomPriceHigher = 1 << 22,
  ReadOwnedBilling = 1 << 23,
  SetCustomPriceLower = 1 << 24,
  UserDelete = 1 << 25,
  EditSerializedItemsInActiveOrders = 1 << 26,
  SaveAllBilling = 1 << 27,
  ReadOwnedDevicesHistory = 1 << 28,
  ReadAllDevicesHistory = 1 << 29,
  ExportDevicesHistory = 1 << 30,
  AllPermissions = ~0,
  ReadCustomers = ReadOwnedCustomers | ReadAllCustomers,
  ReadReturns = ReadAllReturns | ReadOwnedReturns,
  EditReturns = EditOwnedReturns | EditAllReturns,
  ReadOrders = ReadAllOrders | ReadOwnedOrders,
  EditOrders = EditAllOrders | EditOwnedOrders,
  ReadDevicesHistory = ReadAllDevicesHistory | ReadOwnedDevicesHistory,
}

export abstract class PermissionService {
  public static hasPermission(user: IUserProfile, perm: Permissions): boolean {
    const n = user.permissions;
    return (n & perm) !== 0;
  }
}

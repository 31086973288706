import React, { FC, FormEvent, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessages, PasswordRules, SuccessMessages } from "../../../boot/constants";
import { useRootStore } from "../../../stores/storeContext";
import { VQAuthCreatePassword, VQAuthCreateUsername } from "vivaquant-components-library";
import { SlHeaderLogoOnly } from "../../../components/sl-header";

export const InvitationPage: FC = observer(() => {
  const { token } = useParams<{token: string}>();
  const { isTokenValid, completeInvite } = useRootStore().userStore;
  const {
    username,
    password,
    passwordConfirm,
    invalidUsername,
    invalidPassword,
    invalidPasswordConfirm,
    isSubmit,
    isShowPassword,
    isShowPasswordConfirm,
    isErrorInvitation,
    isLoading,
    setUsername,
    setPassword,
    setPasswordConfirm,
    setSubmit,
    cleanSubmit,
    setShowPassword,
    setShowPasswordConfirm,
    setErrorInvitation,
    setLoading,
    cleanData
  } = useRootStore().invitationFormStore;
  const navigate = useNavigate();

  const [isUsernameSet, setIsUsernameSet] = useState(false);

  useEffect(() => {
    if (!token) {
      return;
    }
    (isTokenValid(token) as unknown as Promise<boolean>).then(r => {
      if (!r) {
        navigate("../login", {
          state: {
            message: ErrorMessages.invitationLinkInvalid,
            isError: true
          }
        });
      }
    });
  }, []);

  const showPassword = () => {
    setShowPassword();
  };

  const showPasswordConfirm = () => {
    setShowPasswordConfirm();
  };

  const onSubmitPass = async(event: FormEvent) => {
    event.preventDefault();
    setSubmit();
    if (invalidPassword || invalidPasswordConfirm || !password || !passwordConfirm) { return; }
    if (password !== passwordConfirm) {
      return setErrorInvitation(ErrorMessages.passwordsDidNotMatch);
    }
    setLoading(true);
    const result = await completeInvite(token, username, password);
    if (result) {
      cleanData();
      navigate("../login", { state: { message: SuccessMessages.successInvitation } });
    } else {
      setErrorInvitation(ErrorMessages.failInvitation);
    }
    setLoading(false);
  };

  const onSubmitUseername = async(event: FormEvent) => {
    event.preventDefault();
    setSubmit();
    if (invalidUsername || !username) { return; }
    cleanSubmit();
    setIsUsernameSet(true);
  };

  if (!isUsernameSet) {
    return (
      <VQAuthCreateUsername
        header={(<SlHeaderLogoOnly></SlHeaderLogoOnly>)}
        isLoading={isLoading}
        isErrorSave={isErrorInvitation}
        onSubmit={onSubmitUseername}
        username={username}
        setUsername={setUsername}
        isSubmit={isSubmit}
        invalidUsername={invalidUsername}
      ></VQAuthCreateUsername>
    );
  } else {
    return (
      <VQAuthCreatePassword
        header={(<SlHeaderLogoOnly></SlHeaderLogoOnly>)}
        isLoading={isLoading}
        isErrorSave={isErrorInvitation}
        setPassword={setPassword}
        setPasswordConfirm={setPasswordConfirm}
        onSubmit={onSubmitPass}
        showPassword={showPassword}
        showPasswordConfirm={showPasswordConfirm}
        isSubmit={isSubmit}
        password={password}
        passwordConfirm={passwordConfirm}
        isShowPassword={isShowPassword}
        isShowPasswordConfirm={isShowPasswordConfirm}
        invalidPassword={invalidPassword}
        invalidPasswordConfirm={invalidPasswordConfirm}
        passwordRules={PasswordRules}
      ></VQAuthCreatePassword>
    );
  }
});

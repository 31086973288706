import { ProcessAPIError } from "../decorators";
import { ApiResponseType } from "../types";
import { ApiResponseDto } from "../../models";
import { API } from "../../boot";
import { BillingTotalApiDto } from "../../models/billing";
import { BaseFilterBilling } from "../../core";
import { IBillingModelSnapShot } from "../../stores/billing-list.store";
class BillingApi {
  @ProcessAPIError()
  public async getBillingsCounter(filter: BaseFilterBilling): Promise<ApiResponseType<BillingTotalApiDto>> {
    const result = await API.get("/billing/counters", {
      params: filter
    }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getBillings(filter: BaseFilterBilling)
    : Promise<ApiResponseDto<IBillingModelSnapShot[]>> {
    const { data } = await API.get("/billing", { params: filter });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getExport(filter: BaseFilterBilling): Promise<ApiResponseType<any>> {
    const result = await API.get("/billing/export", { params: filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async saveAllBilling(startAt: string, endAt: string) : Promise<ApiResponseType<any>> {
    const result = await API.put("/billing/save", { startAt, endAt });
    return new ApiResponseDto(result);
  }
}

export const billingsApi = new BillingApi();

import { Instance, types, cast, flow } from "mobx-state-tree";
import { OrderSplitComplete } from "../models/order";
import { OrderItemDto } from "../models/order/order-items.dto";
import { ApiResponseType, orderAPI } from "../services";

export const OrderSplitCompleteSuccess = types
  .model("OrderSplitCompleteSuccess", {
    originalId: types.string,
    firstPartId: types.string,
    secondPartId: types.string
  });

export const OrderSplitCompleteError = types
  .model("OrderSplitCompleteError", {
    detail: types.string,
    status: types.number,
    title: types.string
  });

export interface IOrderSplitCompleteSuccess extends Instance<typeof OrderSplitCompleteSuccess> {}
export interface IOrderSplitCompleteError extends Instance<typeof OrderSplitCompleteError> {}

export const OrderItem = types
  .model("OrderItem", {
    salesItemId: types.maybeNull(types.number),
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    quantityOrdered: types.maybeNull(types.number),
    originalOderQuantity: types.maybeNull(types.number),
    newOderQuantity: types.maybeNull(types.number)
  });

export interface IOrderItem extends Instance<typeof OrderItem> {}

export const SplitOrderModel = types
  .model("SplitOrderModel", {
    orderItems: types.optional(types.array(OrderItem), []),
    isLoading: types.optional(types.boolean, false),
    isComplete: types.optional(types.boolean, false),
    isSuccess: types.optional(types.boolean, false),
    id: types.maybeNull(types.number),
    orderNumber: types.maybeNull(types.string),
    orderCompleteSuccess: types.maybeNull(OrderSplitCompleteSuccess),
    orderCompleteError: types.maybeNull(OrderSplitCompleteError),
    isNotNull: types.optional(types.boolean, false)
  })
  .actions(self => {
    const setData = (id: number, orderNumber: string) => {
      self.id = id;
      self.orderNumber = orderNumber;
      self.isLoading = false;
      self.isComplete = false;
      self.isSuccess = false;
      self.orderItems = undefined;
      self.orderCompleteSuccess = null;
      self.orderCompleteError = null;
      self.isNotNull = false;
    };

    const setQuantity = (quantity: number, id: number) => {
      self.orderItems = cast(self.orderItems.map(item => {
        if (item.salesItemId === id) {
          if (self.orderItems?.length === 1 && quantity === 0) {
            self.isNotNull = true;
          } else {
            self.isNotNull = false;
          }
          return {
            ...item,
            newOderQuantity: quantity,
            originalOderQuantity: item.quantityOrdered - quantity
          };
        } else return { ...item };
      }));
    };

    const getData = flow(function* getData() {
      try {
        self.isLoading = true;
        const response: ApiResponseType<OrderItemDto[]> = yield orderAPI.getOderItems(self.id);
        if (response.isOk) {
          self.orderItems = cast(response.data.map(item => {
            return {
              ...item,
              originalOderQuantity: item.quantityOrdered - 1,
              newOderQuantity: 1
            };
          }));
        }
      } finally {
        self.isLoading = false;
      }
    });

    const complete = flow(function* complete() {
      try {
        self.isLoading = true;
        const completed: OrderSplitComplete = {
          id: self.id,
          items: self.orderItems.map(item => {
            return {
              salesItemId: item.salesItemId,
              orderItemId: item.id,
              quantity: item.newOderQuantity
            };
          })
        };
        const response: ApiResponseType<IOrderSplitCompleteSuccess> = yield orderAPI.splitOrder(completed);
        if (response.isOk) {
          self.orderCompleteSuccess = response.data;
          self.isSuccess = true;
        } else {
          throw response;
        }
      } catch (e) {
        if (e && typeof e === "object" && "error" in e) {
          self.orderCompleteError = e.error as IOrderSplitCompleteError;
        }
        self.isSuccess = false;
      } finally {
        self.isComplete = true;
        self.isLoading = false;
      }
    });

    return {
      setData,
      setQuantity,
      getData,
      complete
    };
  });

import { flow, SnapshotIn, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { IUpdateProfileRequest } from "../models/v2/User/IUpdateProfileRequest";
import { IUpdateProfileResponse } from "../models/v2/User/IUpdateProfileResponse";
import { ApiResponseType, userAPI, ValidationUtils } from "../services";
import { IProfileModel } from "./user.store";

export const ProfileStoreModel = types
  .model("ProfileStoreModel", {
    showModal: types.optional(types.boolean, false),
    firstName: types.maybe(InputFormModel),
    lastName: types.maybe(InputFormModel),
    email: types.maybe(InputFormModel),
    username: types.maybe(InputFormModel),
    phoneNumber: types.maybe(InputFormModel),
    is2FaEnabled: types.maybe(InputFormModel)
  })
  .actions(self => ({
    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    }
  }))
  .actions(self => {
    const initProfile = (profile: IProfileModel) => {
      self.firstName.setValue(profile.firstname || "");
      self.lastName.setValue(profile.lastname || "");
      self.email.setValue(profile.email || "");
      self.username.setValue(profile.login || "");
      self.phoneNumber.setValue(profile.phone || "");
      self.is2FaEnabled.setValue(profile.is2FaEnabled + "");
    };

    const getDataForSave = (): IUpdateProfileRequest => {
      return {
        signatureUrl: null,
        imageUrl: null,
        firstname: self.firstName.value.trim(),
        lastname: self.lastName.value.trim(),
        email: self.email.value,
        username: self.username.value,
        phone: "+" + self.phoneNumber.value.replace(/[^\w\s]/gi, "").replace(/\s/g, ""),
        is2FaEnabled: self.is2FaEnabled && self.is2FaEnabled.value + "" === "true"
      };
    };

    const validateCode = flow(function* validateCode(authCode: string) {
      const data = getDataForSave();
      return yield updateProfile2Fa(data, authCode);
    });

    const saveForm = flow(function* saveForm() {
      const data = getDataForSave();
      return yield updateProfile(data);
    });

    const updateProfile = flow(function* updateProfile(request: IUpdateProfileRequest) {
      try {
        const response: ApiResponseType<IUpdateProfileResponse> = yield userAPI.updateProfile(request);
        return response;
      } catch (e) {
        return false;
      }
    });

    const updateProfile2Fa = flow(function* updateProfile2Fa(request: IUpdateProfileRequest, code: string) {
      try {
        const response: ApiResponseType<IUpdateProfileResponse> = yield userAPI.updateProfile2Fa(request, code);
        return response.isOk;
      } catch (e) {
        return false;
      }
    });

    const validateField = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.firstName?.value) {
        errors.firstName = "Please fill the field";
        isValid = false;
      }

      if (!self.lastName?.value) {
        errors.lastName = "Please fill the field";
        isValid = false;
      }

      if (!self.username?.value) {
        errors.username = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isUsernameValid(self.username?.value)) {
          errors.username = "Minimum 6 characters required";
          isValid = false;
        }
      }

      if (!self.email?.value) {
        errors.email = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isEmailValid(self.email?.value)) {
          errors.email = "Wrong email";
          isValid = false;
        }
      }

      if (!self.phoneNumber?.value) {
        errors.phoneNumber = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isPhonedValid(self.phoneNumber?.value)) {
          errors.phone = "Please enter valid phone number";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    return {
      initProfile,
      validateCode,
      saveForm,
      validateField,
      getDataForSave
    };
  });

export interface IProfileStoreModelSnapShot extends SnapshotIn<typeof ProfileStoreModel> {}

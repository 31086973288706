import React, { FC } from "react";
import { NavItemProps } from "./sl-nav-item.props";
import { NavItemWrapper } from "./sl-nav-item.presets";
import { Link } from "react-router-dom";

export const SlNavItem: FC<NavItemProps> = ({ link, isActive, children }) => {
  return (
    <NavItemWrapper isActive={isActive}>
      <Link to={link}>{children}</Link>
    </NavItemWrapper>
  );
};

import React from "react";
import { RmaReturnListModel } from "./rma-return.store";
import { AccountsListModel } from "./accounts-list.store";
import { AdminListModel } from "./admin-list.store";
import { AdminInviteModel } from "./admin-invite.store";
import { CreateRmaReturnModel } from "./create-rma-return.store";
import { CreateAccountModel } from "./create-account.store";
import { GlobalStoreModel } from "./global.store";
import { OrderListModel } from "./order-list.store";
import { BillingListModel } from "./billing-list.store";
import { CreateOrderModel } from "./create-order.store";
import { EditOrderModel } from "./edit-order.store";
import { LoginFormModel } from "./authorization/login.store";
import { ForgotFormModel } from "./authorization/forgot.store";
import { RecoveryFormModel } from "./authorization/recovery.store";
import { InvitationFormModel } from "./authorization/invitation.store";
import { UserStoreModel } from "./user.store";
import { ProfileStoreModel } from "./profile.store";
import { SplitOrderModel } from "./split-order.store";
import { DevicesHistoryListModel } from "./devices-history-list.store";

import { CreateAccountInitialState } from "../states/create-account.state";
import { AdminInviteState } from "../states/admin-invite.state";
import { CreateOrderInitialState } from "../states/create-order.state";
import { EditOrderInitialState } from "../states/edit-order.state";
import { CreateRmaReturnInitialState } from "../states/create-rma-return.state";
import { ProfileInitialState } from "../states/profile.state";

type RootStoreContextValue = {
  rmaReturnListStore: any,
  accountsListStore: any,
  adminListStore: any,
  adminInviteStore: any,
  orderListStore: any,
  billingListStore: any,
  createRmaReturnStore: any,
  createAccountStore: any,
  createOrderStore: any
  editOrderStore: any,
  globalStore: any,
  loginFormStore: any,
  forgotFormStore: any,
  recoveryFormStore: any,
  invitationFormStore: any,
  userStore: any,
  profileStore: any,
  splitOrderStore: any,
  devicesHistoryListStore: any
};

const RootStoreContext = React.createContext<RootStoreContextValue>({} as RootStoreContextValue);

const context = {
  rmaReturnListStore: RmaReturnListModel.create(),
  createRmaReturnStore: CreateRmaReturnModel.create(CreateRmaReturnInitialState),
  globalStore: GlobalStoreModel.create(),
  accountsListStore: AccountsListModel.create(),
  createAccountStore: CreateAccountModel.create(CreateAccountInitialState),
  adminListStore: AdminListModel.create(),
  adminInviteStore: AdminInviteModel.create(AdminInviteState),
  orderListStore: OrderListModel.create(),
  billingListStore: BillingListModel.create(),
  createOrderStore: CreateOrderModel.create(CreateOrderInitialState),
  editOrderStore: EditOrderModel.create(EditOrderInitialState),
  loginFormStore: LoginFormModel.create(),
  forgotFormStore: ForgotFormModel.create(),
  recoveryFormStore: RecoveryFormModel.create(),
  invitationFormStore: InvitationFormModel.create(),
  userStore: UserStoreModel.create(),
  profileStore: ProfileStoreModel.create(ProfileInitialState),
  splitOrderStore: SplitOrderModel.create(),
  devicesHistoryListStore: DevicesHistoryListModel.create()
};

// eslint-disable-next-line react/prop-types
export const RootStoreProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <RootStoreContext.Provider value={context}>{children}</RootStoreContext.Provider>;
};
export const useRootStore = () => React.useContext(RootStoreContext);

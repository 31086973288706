import { OptionType } from "../models/OptionType";
import RMAIcon from "../assets/icons/list-patients-pending.svg";
import RMAIconActive from "../assets/icons/list-patients-pending-active.svg";
import ReturnIcon from "../assets/icons/return.svg";
import ReturnIconActive from "../assets/icons/return-active.svg";
import OrderAllIcon from "../assets/icons/events-all.svg";
import OrderAllIconActive from "../assets/icons/events-all-active.svg";
import OrderIconShipping from "../assets/icons/list-patients-archive.svg";
import OrderIconShippingActive from "../assets/icons/list-patients-archive-active.svg";
import AllIcon from "../assets/icons/list-patients-all.svg";
import AllIconActive from "../assets/icons/list-patients-all-active.svg";
import AccountingIcon from "../assets/icons/admin-accounting.svg";
import AccountingIconActive from "../assets/icons/admin-accounting-active.svg";
import SalesIcon from "../assets/icons/admin-sales.svg";
import SalesIconActive from "../assets/icons/admin-sales-active.svg";
import SupportIcon from "../assets/icons/admin-support.svg";
import SupportIconActive from "../assets/icons/admin-support-active.svg";
import SuperuserIcon from "../assets/icons/admin-super-user.svg";
import SuperuserIconActive from "../assets/icons/admin-super-user-active.svg";
import ExecutiveIcon from "../assets/icons/admin-executive.svg";
import ExecutiveIconActive from "../assets/icons/admin-executive-active.svg";
import ClinicsIcon from "../assets/icons/clinics.svg";
import ClinicsIconActive from "../assets/icons/clinics-active.svg";
import IDTFIcon from "../assets/icons/idtf.svg";
import IDTFIconActive from "../assets/icons/idtf-active.svg";
import DistributorsIcon from "../assets/icons/distributors.svg";
import DistributorsIconActive from "../assets/icons/distributors-active.svg";
import AccessoriesIcon from "../assets/icons/accessories.svg";
import AccessoriesIconActive from "../assets/icons/accessories-active.svg";
import DealerIcon from "../assets/icons/dealer.svg";
import DealerIconActive from "../assets/icons/dealer-active.svg";
import SplitBillIcon from "../assets/icons/split-bill.svg";
import SplitBillIconActive from "../assets/icons/split-bill-active.svg";

export const API_URL = "https://sa-service-dev.rhythmexpressecg.com/api/v2/";
export const RECAPTCHA_KEY = "6Lf3GssbAAAAAIqUo2myXWPyzkMpfWcu4dX97Id4";

export const ORDER_ITEM_MAX_VALUE = 9999;
export const SALES_REP_ID = 2;

export const ErrorMessages = {
  errorLogin: "Wrong email or password",
  errorPhone: "Wrong phone format",
  errorUsername: "Minimum 6 characters required",
  errorEmptyField: "Empty field",
  errorForgot: "Wrong email",
  errorSelectedAccount: "Account is not selected",
  errorAccountType: "Account type in not selected",
  wrongEmail: "Wrong Email",
  wrongPassword: "Wrong password",
  actionCanNotUse: "This action can not use now",
  recoveryError: "Error recovery password",
  recoveryLinkInvalid: "Recovery link is invalid",
  invitationLinkInvalid: "Invitation link is invalid",
  passwordsDidNotMatch: "Those passwords didn't match. Try again.",
  failInvitation: "Your account cannot be created",
  invalidDate: "The date is invalid",
  noItemsOrdered: "You have to order at least 1 item",
  invalidZipCode: "Zip code format is invalid"
};

export const SuccessMessages = {
  successForgot: "We have emailed you a link resetting your password."
    + "\nIf you did not receive the email please check your address and your spam folder."
    + "\nOnly users already registered with RhythmExpress can apply for a new password.",
  successRecovery: "Your password was successfully updated!",
  successInvitation: "Your password was successfully created! Use your email to sign in."
};

export const PasswordRules = (
  <div>
    <div>{`A password shall consist of a minimum of 8 characters including 
    at least one character matching each of the following requirements:`}</div>
    <ul>
      <li>capital letter</li>
      <li>small letter</li>
      <li>number</li>
      <li>special character (i.e. #, $, !, &, #, space)</li>
    </ul>
  </div>
);

export const enum AccountTypes {
  Clinic,
  IDTF,
  Distributor
}

export const enum AccountLinkedTypes {
  None,
  Main,
  LinkedToMain
}

export const enum AccountPrices {
  Clinic = 325,
  IDTF = 22,
  Distributor = 285
}

export const enum AccountFeeForServicePrices {
  MctRecordingPrice = 195,
  EventRecordingPrice = 140,
  ExtendedHolterPrice = 160,
  ShortTermHolterPrice = 49,
  DeviceLease = 40,
  HomeHookupPrice = 55
}

export const enum AccountModels {
  StandardSubscription = 0,
  FeeForService = 1,
  LeaseDevice = 2,
  Purchase = 3,
  Distributor = 4,
  MonitoringCenterPurchase = 5,
  SplitBill = 6
}

export const States = ["AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA",
  "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA",
  "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND",
  "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT",
  "VT", "VI", "VA", "WA", "WV", "WI", "WY"];

export const accountTypesButtons = [{
  text: "Clinic",
  type: "CLINIC",
  value: AccountTypes.Clinic,
  active: true
}, {
  text: "Distributor",
  type: "DISTRIBUTOR",
  value: AccountTypes.Distributor,
  active: false
}, {
  text: "IDTF",
  type: "IDTF",
  value: AccountTypes.IDTF,
  active: false
}];

export const accountLinkedTypesRadio = [{
  text: "Linked Main clinic",
  value: AccountLinkedTypes.Main,
  active: false
}, {
  text: "Linked To Main clinic",
  value: AccountLinkedTypes.LinkedToMain,
  active: false
}, {
  text: "None",
  value: AccountLinkedTypes.None,
  active: true
}];

export const SalesModel = [
  {
    id: 0,
    value: AccountModels.StandardSubscription,
    label: "Subscription",
    type: AccountTypes.Clinic
  }, {
    id: 1,
    value: AccountModels.FeeForService,
    label: "Fee For Service",
    type: AccountTypes.Clinic
  }, {
    id: 2,
    value: AccountModels.LeaseDevice,
    label: "Lease Device",
    type: AccountTypes.Clinic
  }, {
    id: 3,
    value: AccountModels.Purchase,
    label: "Purchase",
    type: AccountTypes.IDTF
  }, {
    id: 4,
    value: AccountModels.SplitBill,
    label: "Split Bill",
    type: AccountTypes.Clinic
  }
];

export const PAGINATION = {
  ROWS_PER_PAGE: 20,
  DEVICE_NUMBERS: 20
};

export enum OrderState {
  All = "All",
  CompleteNotShipped = "NotShipped",
  ShippedSuspended = "Suspended",
  ShippedActive = "Active",
  Cancelled = "Cancelled"
}

export enum OrderStateNum {
  CompleteNotShipped = 1,
  ShippedSuspended = 2,
  ShippedActive = 3,
  Cancelled = 4
}

export const OrderStateOptions = [
  {
    value: OrderStateNum.CompleteNotShipped,
    label: "Not Shipped"
  },
  {
    value: OrderStateNum.ShippedSuspended,
    label: "Suspended"
  },
  {
    value: OrderStateNum.ShippedActive,
    label: "Active"
  },
  {
    value: OrderStateNum.Cancelled,
    label: "Cancelled"
  }
];

export const enum OrderActions {
  EDIT = "Edit Order",
  REVIEW = "Review Order",
  SPLIT = "Split Order",
  DELETE = "Delete Order"
}

export const enum BillingState {
  CLINICS = "clinics",
  DISTRIBUTORS = "distributors",
  IDTF = "idtf",
  DEALERS= "dealerClinics",
  ACCESSORIES = "accessories",
  SPLITBILL = "splitbill"
}

export const enum SortDirection
{
  ASC = 0,
  DESC = 1
}

export const RmaReturnSort = {
  id: "number",
  requestdate: "requestdate",
  accountname: "accountname",
  reason: "reason",
  status: "status",
  salesrepid: "salesrepid"
};

export const DevicesHistorySort = {
  serialNumber: "serialNumber",
  orderDate: "orderDate",
  rmaReturnRequestDate: "rmaReturnRequestDate"
};

export const AccountsSort = {
  accountname: "accountname",
  onboardingdate: "onboardingdate",
  accounttype: "accounttype",
  salesmodel: "salesmodel",
  salesrep: "salesrep",
  accountnumber: "accountnumber"
};

export const AdminSort = {
  firstname: "firstname",
  lastname: "lastname"
};

export const OrderSort = {
  orderNumber: "orderNumber",
  accountName: "accountName",
  requestDate: "requestDate",
  shipDate: "shipDate",
  usageStartDate: "usageStartDate",
  salesRep: "salesRep"
};

export const OrderSerializedSort: OptionType[] = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Devices",
    value: "true"
  },
  {
    label: "Items",
    value: "false"
  }
];

export const BillingSort = {
  accountname: "accountname"
};

export const RmaReturnListCategories = [
  {
    title: "RMA",
    value: "rma",
    icon: RMAIcon,
    iconActive: RMAIconActive
  },
  {
    title: "Return",
    value: "return",
    icon: ReturnIcon,
    iconActive: ReturnIconActive
  }
];

export const OrderListCategories = [
  {
    title: "All",
    value: "",
    state: OrderState.All,
    icon: OrderAllIcon,
    iconActive: OrderAllIconActive
  },
  {
    title: "Not shipped",
    value: OrderStateNum.CompleteNotShipped + "",
    state: OrderState.CompleteNotShipped,
    icon: OrderIconShipping,
    iconActive: OrderIconShippingActive
  },
  {
    title: "Suspended",
    value: OrderStateNum.ShippedSuspended + "",
    state: OrderState.ShippedSuspended,
    icon: RMAIcon,
    iconActive: RMAIconActive
  },
  {
    title: "Active",
    value: OrderStateNum.ShippedActive + "",
    state: OrderState.ShippedActive,
    icon: RMAIcon,
    iconActive: RMAIconActive
  },
  {
    title: "Cancelled",
    value: OrderStateNum.Cancelled + "",
    state: OrderState.Cancelled,
    icon: ReturnIcon,
    iconActive: ReturnIconActive
  }
];

export enum BillingTypes {
  All,
  Subscription,
  Accessory
}

export const BillingListCategories = [
  {
    id: "0",
    title: "Clinics",
    value: AccountTypes.Clinic + "",
    state: BillingState.CLINICS,
    colName: "Clinic",
    hasDealer: false,
    billingType: BillingTypes.Subscription,
    hasMainClinic: false,
    includeLinked: true,
    icon: ClinicsIcon,
    iconActive: ClinicsIconActive
  },
  {
    id: "1",
    title: "Split Bill",
    value: AccountTypes.Clinic + "",
    state: BillingState.SPLITBILL,
    colName: "Clinic",
    hasDealer: false,
    billingType: BillingTypes.Subscription,
    hasMainClinic: false,
    includeLinked: true,
    icon: SplitBillIcon,
    iconActive: SplitBillIconActive
  },
  {
    id: "2",
    title: "Distributors",
    value: AccountTypes.Distributor + "",
    state: BillingState.DISTRIBUTORS,
    colName: "Distributor",
    hasDealer: null,
    billingType: BillingTypes.Subscription,
    includeLinked: true,
    hasMainClinic: false,
    icon: DistributorsIcon,
    iconActive: DistributorsIconActive
  },
  {
    id: "3",
    title: "IDTF",
    value: AccountTypes.IDTF + "",
    state: BillingState.IDTF,
    colName: "IDTF",
    hasDealer: null,
    billingType: BillingTypes.Subscription,
    includeLinked: true,
    hasMainClinic: false,
    icon: IDTFIcon,
    iconActive: IDTFIconActive
  },
  {
    id: "4",
    title: "Dealers",
    value: AccountTypes.Clinic + "",
    state: BillingState.DEALERS,
    colName: "Clinic",
    hasDealer: true,
    billingType: BillingTypes.Subscription,
    includeLinked: true,
    hasMainClinic: false,
    icon: DealerIcon,
    iconActive: DealerIconActive
  }, {
    id: "5",
    title: "Accessories",
    value: null,
    state: BillingState.ACCESSORIES,
    colName: "Clinic",
    hasDealer: null,
    billingType: BillingTypes.Accessory,
    includeLinked: false,
    hasMainClinic: null,
    icon: AccessoriesIcon,
    iconActive: AccessoriesIconActive
  }
];

export const AdminListCategories = [
  {
    title: "All",
    value: "all",
    groupName: "",
    icon: AllIcon,
    iconActive: AllIconActive
  },
  {
    title: "Sales Rep",
    value: "salesRep",
    groupName: "Sales Rep",
    icon: SalesIcon,
    iconActive: SalesIconActive
  },
  {
    title: "Support",
    value: "support",
    groupName: "Support",
    icon: SupportIcon,
    iconActive: SupportIconActive
  },
  {
    title: "Superuser",
    value: "superusers",
    groupName: "Superuser",
    icon: SuperuserIcon,
    iconActive: SuperuserIconActive
  },
  {
    title: "Accounting",
    value: "accounting",
    groupName: "Accounting",
    icon: AccountingIcon,
    iconActive: AccountingIconActive
  },
  {
    title: "Executive",
    value: "executive",
    groupName: "Executive",
    icon: ExecutiveIcon,
    iconActive: ExecutiveIconActive
  }
];

export const enum RMAStatus {
  Open,
  ShippedByCustomer,
  Received,
  Completed,
  NotCompleted
}

export const enum AccountsStatus {
  Active = 1,
  Stopped = 2,
  Inactive = 4,
  Incomplete = 5
}

export const RMAStatusInfo = [
  {
    id: RMAStatus.Open,
    label: "Open",
    color: "rgba(0, 156, 16, 0.2)"
  },
  {
    id: RMAStatus.ShippedByCustomer,
    label: "Shipped",
    color: "rgba(255, 184, 0, 0.2)"
  },
  {
    id: RMAStatus.Received,
    label: "Recieved",
    color: "rgba(115, 0, 185, 0.2)"
  },
  {
    id: RMAStatus.Completed,
    label: "Complete",
    color: "rgba(0, 101, 252, 0.3)"
  },
  {
    id: RMAStatus.NotCompleted,
    label: "Non Complete",
    color: "rgba(109, 109, 109, 0.1)"
  }
];

export const AccountsStatusInfo = [
  {
    id: AccountsStatus.Active,
    label: "Active",
    color: "rgba(0, 156, 16, 0.2)"
  },
  {
    id: AccountsStatus.Stopped,
    label: "Stopped",
    color: "rgba(109, 109, 109, 0.1)"
  },
  {
    id: AccountsStatus.Inactive,
    label: "Inactive",
    color: "rgba(112, 170, 53, 0.35)"
  },
  {
    id: AccountsStatus.Incomplete,
    label: "Incomplete",
    color: "rgba(244, 91, 91, 0.31)"
  }
];

export const AccountSetTermsDurationInDays = [15, 20, 30, 60];

export const ReasonList = [
  "Totally Unresponsive",
  "Red flash on cell LED when reset",
  "Stopped communicating",
  "Intermittent communication",
  "Damaged lead",
  "Damage to charging port",
  "Damage to button",
  "Damage to label",
  "Rattling component",
  "Damage to housing",
  "No recording data collected / received",
  "Intermittent / noisy data",
  "Vibration motor issue",
  "LED issue",
  "Device not transitioning to ready",
  "Device cannot be released",
  "Device going to Call for Service",
  "Recording stopped",
  "Cannot read fuel gauge",
  "Not able to charge",
  "Charger pin issue",
  "Charger not charging",
  "Red LED above cell icon",
  "Red LED above heart icon",
  "Lost item",
  "Customer Return",
  "Device swap",
  "Other"
];


export const enum ShippingServiceType
{
  SecondDayAir = 0,
  Ground = 1,
  NextDayAir = 2
}

export const ShippingServiceTypeOptions = [
  { 
    value: ShippingServiceType.SecondDayAir.toString(), 
    label: "2nd Day Air"
  },
  { 
    value: ShippingServiceType.Ground.toString(), 
    label: "Ground"
  },
  { 
    value: ShippingServiceType.NextDayAir.toString(), 
    label: "Next Day Air"
  }
];


export const enum ShipStationSyncStatus {
    Successfully = 0,
    TooManyRequests = 1,
    ValueMismatches = 2,
    SomethingWentWrong = 3
  }

export enum BillingDataSourceType {
  SalesApp = 0,
  ClinicPortal = 1,
}

export enum BillingDataSourceTypeLabel {
  SalesApp = "Populated from SalesApp source",
  ClinicPortal = "Populated from Clinic Portal",
}
import React, { FC } from "react";
import { GlobalStyles } from "../theme";
import { GlobalRoutes } from "./routes";
import { observer } from "mobx-react-lite";
import { BrowserRouter } from "react-router-dom";
import { RootStoreProvider } from "../stores/storeContext";

export const App: FC = observer(() => {
  return (
    <RootStoreProvider>
      <GlobalStyles>
        <BrowserRouter>
          <GlobalRoutes/>
        </BrowserRouter>
      </GlobalStyles>
    </RootStoreProvider>
  );
});

import { PAGINATION, SortDirection } from "../../boot/constants";

export class BaseFilter {
  public skip: number = 0;
  public take: number = PAGINATION.ROWS_PER_PAGE;
  public search: string;
  public sortBy: string = "id";
  public sortDirection: SortDirection = SortDirection.ASC;
}

export class BaseFilterRma extends BaseFilter {
  public salesRepId? : string | number;
  public status? : string | number;
  public startShipDate? : string;
  public endShipDate? : string;
}

export class BaseFilterAccount extends BaseFilter {
  public status? : string | number;
  public isMainClinic? : boolean;
  public isLinked? : boolean;
  public mainClinicId? : string | number;
  public salesRepId? : string | number;
}

export class BaseFilterOrder extends BaseFilter {
  public salesRepId? : string | number;
  public isSerialized? : boolean;
  public startShipDate? : string;
  public endShipDate? : string;
}

export class BaseFilterBilling extends BaseFilter {
  startAt: string;
  endAt: string;
  salesRepId?: string | number;
  dealerId?: string | number;
  salesModel?: string | number;
  type?: string | number;
  hasDealer?: boolean;
  billingType: string | number;
  includeLinked: boolean;
  hasMainClinic?: boolean;
}
